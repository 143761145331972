import { Component } from '@angular/core';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-documentoPC',
  templateUrl: './documentoPC.component.html',
  styleUrls: ['./documentoPC.component.scss']
})
export class DocumentoPCComponent {
razon:any
documento:any
  constructor(private api: GeneralService, private router: Router, private alertas: SweetAlertService) { }



  ngOnInit() {

this.razon=localStorage.getItem('constancia')




    this.api.filtropc(this.razon).subscribe(res => {
      this.documento = res.body;
      console.log(this.documento);



    })



  }



  crearPDF() {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [12, 8]

    });


    doc.setFontSize(30);



    var logo = new Image();
    logo.src = 'https://i.imgur.com/JhDtguN.png';
    doc.addImage(logo, 'PNG', .01, .01, 12, 8);
doc.text(this.documento[0].RAZON_SOCIAL , 6, 3.8, {align:"center"});
    doc.output('dataurlnewwindow', {filename: 'Constancia.pdf'});
doc.save('Constancia - Simulacro Nacional.pdf')
this.alertas.realizado('Realizado','Su constancia ha sido generada con exito')

  }

}
