<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">

    <section>
      <div class="container">



        <div class="row justify-content-center" style="border: solid; border-radius: 2vh; border-color: rgb(255, 255, 255); box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753);">
          <div class="col-12 col-md-8 col-lg-8 col-xl-6">
            <form [formGroup]="nuevoPC" (ngSubmit)="postForm(this.nuevoPC.value)"  action="">
            <div class="row">
              <div class="col text-center">



                <img src="https://i.imgur.com/oGuTsJG.png" height="100" width="100" alt="" style="margin-top: 1vh;">


                <h1>Registro de Servicio</h1>
                <p style="margin-top: 1vh;" class="text-h3">Rellene los datos para completar su registro. </p>




              </div>
            </div>


            <div class="row align-items-center mt-4" style="justify-content: center; text-align: center;">

              <h4 style="border: solid; border-color: black;">I DATOS DEL SERVICIO </h4>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Fecha:
                </label>
                <input type="date" class="form-control" placeholder="Fecha" formControlName="FECHA">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Dia:
                </label>
                <input class="form-control" placeholder="" formControlName="DIA">
              </div>


              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Hora de salida escena:
                </label>
                <input  class="form-control" placeholder="" formControlName="HORA_SALIDA">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                  Hora de llegada escena:
                </label>
                <input class="form-control" placeholder="" formControlName="HORA_ENTRADA">
              </div>


              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                 Ambulancia PC-O:
                </label>
                <input  class="form-control" placeholder="" formControlName="AMBULANCIA">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                 Operador y grado:
                </label>
                <input class="form-control" placeholder="" formControlName="OPERADOR">
              </div>



              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                Bomberos y Grados:
                </label>
                <input  class="form-control" placeholder="" formControlName="BOMBEROS">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
               Tipo de Servicio:
                </label>
                <input class="form-control" placeholder="" formControlName="SERVICIO">
              </div>


              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Ubicación:
                </label>
                <input  class="form-control" placeholder="" formControlName="UBICACION">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Hora de Traslado:
                </label>
                <input class="form-control" placeholder="" formControlName="HORA_TRASLADO">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Hora de arribo al hospital:
                </label>
                <input  class="form-control" placeholder="" formControlName="HORA_ARRIBO">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Hora de entrega al Médico:
                </label>
                <input class="form-control" placeholder="" formControlName="HORA_ENTREGA">
              </div>



              <h4 style="border: solid; border-color: black;margin-top: 2vh;">II DATOS DEL PACIENTE </h4>



              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Nombre:
                </label>
                <input class="form-control" placeholder="" formControlName="NOMBRE">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Municipio:
                </label>
                <input  class="form-control" placeholder="" formControlName="MUNICIPIO">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Domicilio:
                </label>
                <input class="form-control" placeholder="" formControlName="DOMICILIO">
              </div>


              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Teléfono:
                </label>
                <input class="form-control" placeholder="" formControlName="TELEFONO">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Sexo:
                </label>
                <input  class="form-control" placeholder="" formControlName="SEXO">
              </div>

              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
            Edad:
                </label>
                <input class="form-control" placeholder="" formControlName="EDAD">
              </div>


              <div style="margin-top: 2.5VH;" class="col-4">
<label for="">Afiliación a:</label>
              <select class="form-select" aria-label="Default select example" formControlName="AFILIACION">

                <option value="IMSS">IMSS</option>
                <option value="SEGURO POPULAR">SEGURO POPULAR</option>
                <option value="ISSSTE">ISSSTE</option>
              </select>


              </div>



              <div class="col-4">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Otro:
                </label>
                <input  class="form-control" placeholder="" formControlName="OTRO">
              </div>




              <h4 style="border: solid; border-color: black;margin-top: 2vh;">III DATOS DE LA ATENCION </h4>


              <div style="margin-top: 2.5VH;" class="col-6">
<label for="">Motivo:</label>
                <select class="form-select" aria-label="Default select example" formControlName="MOTIVO">

                  <option value="ENFERMEDAD">ENFERMEDAD</option>
                  <option value="COLISION VEHICULAR">COLISION VEHICULAR</option>
                  <option value="ACCIDENTE LABORAL">ACCIDENTE LABORAL</option>
                  <option value="PARTO">PARTO</option>

                </select>


                </div>



              <div style="margin-top: 2.5VH;" class="col-6">
<label for="">Lugar donde ocurrió:</label>
                <select class="form-select" aria-label="Default select example" formControlName="LUGAR">

                  <option value="TRABAJO">TRABAJO </option>
                  <option value="VIA PUBLICA">VIA PUBLICA</option>
                  <option value="HOGAR">HOGAR</option>
                  <option value="ESCUELA">ESCUELA</option>

                </select>


                </div>





                <h4 style="border: solid; border-color: black;margin-top: 2vh;">ESTADO DEL PACIENTE AL ARRIBO </h4>



                <div style="margin-top: 2.5VH;" class="col-6">
<label for="">Estado de conciencia:</label>
                  <select class="form-select" aria-label="Default select example"formControlName="CONCIENCIA">

                    <option value="CONCIENTE">CONCIENTE </option>
                    <option value="SEM-INCONCIENTE">SEM-INCONCIENTE</option>
                    <option value="INCONCIENTE">INCONCIENTE</option>
                    <option value="MUERTO">MUERTO</option>

                  </select>


                  </div>

                  <div style="margin-top: 2.5VH;" class="col-6">
                    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                      Escala de Glasgow:
                        </label>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="OCULAR">
                      <label class="form-check-label" for="inlineCheckbox1">Apertura Ocular</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" formControlName="VERBAL">
                      <label class="form-check-label" for="inlineCheckbox2">Respuesta Verbal</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="MOTORA">
                      <label class="form-check-label" for="inlineCheckbox1">Respuesta Motora</label>
                    </div>
                    <br>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"formControlName="TOTAL">
                      <label class="form-check-label" for="inlineCheckbox2">Total</label>
                    </div>


                    </div>

                    <div style="margin-top: 2.5VH;" class="col-6">
<label for="">Color de Piel:</label>
                      <select class="form-select" aria-label="Default select example" formControlName="PIEL">

                        <option value="PALIDA">PALIDA </option>
                        <option value="RUBICUNDEZ">RUBICUNDEZ</option>
                        <option value="CIANOSIS">CIANOSIS</option>
                        <option value="NORMAL">NORMAL</option>

                      </select>


                      </div>

                      <div style="margin-top: 2.5VH;" class="col-6">
<label for="">Respiración:</label>
                        <select class="form-select" aria-label="Default select example" formControlName="RESPIRACION">

                          <option value="PRESENTE">PRESENTE </option>
                          <option value="DISMINUIDA">DISMINUIDA</option>
                          <option value="AUSENTE">AUSENTE</option>
                          <option value="ESTETORES">ESTETORES</option>

                        </select>


                        </div>


   <h4 style="border: solid; border-color: black;margin-top: 2vh;">SIGNOS VITALES </h4>


   <div class="col-4">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  Hora:
    </label>
    <input class="form-control" placeholder="" formControlName="HORA">
  </div>

  <div class="col-4">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  F.R:
    </label>
    <input  class="form-control" placeholder="" formControlName="FR">
  </div>

  <div class="col-4">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  F.C:
    </label>
    <input class="form-control" placeholder="" formControlName="FC">
  </div>


  <div class="col-4">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  TEMP:
    </label>
    <input class="form-control" placeholder="" formControlName="TEMP">
  </div>

  <div class="col-4">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  T/A:
    </label>
    <input  class="form-control" placeholder="" formControlName="TA">
  </div>

  <div class="col-4">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  A.V.D.I:
    </label>
    <input class="form-control" placeholder="" formControlName="AVDI">
  </div>


  <div class="col-4">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  GLASGOW:
    </label>
    <input class="form-control" placeholder="" formControlName="GLASGOW">
  </div>

  <div class="col-4">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  GLUCOSA:
    </label>
    <input  class="form-control" placeholder="" formControlName="GLUCOSA">
  </div>

  <div class="col-4">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
 PUPILAS:
    </label>
    <input class="form-control" placeholder="" formControlName="PUPILAS">
  </div>

  <div class="col-6">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  LLENO. CAP:
    </label>
    <input class="form-control" placeholder="" formControlName="LLEN_CAP">
  </div>

  <div class="col-6">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  SO2:
    </label>
    <input  class="form-control" placeholder="" formControlName="SO2">
  </div>


  <div class="col-4" style="margin-top: 2VH;" >
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
ALERGIAS:
    </label>

  </div>


  <div class="col-8" style="margin-top: 2VH;">

    <input  class="form-control" placeholder="" formControlName="ALERGIAS">
  </div>




  <div class="col-4" style="margin-top: 2VH;">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
MEDICAMENTOS QUE TOMA:
    </label>

  </div>


  <div class="col-8" style="margin-top: 2VH;">

    <input  class="form-control" placeholder="" formControlName="MEDICAMENTOS">
  </div>




  <div class="col-4" style="margin-top: 2VH;">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
ENFERMEDADES Y CIRUGIAS PREVENTIVAS:
    </label>

  </div>


  <div class="col-8" style="margin-top: 2VH;">

    <input  class="form-control" placeholder="" formControlName="ENFE_Y_CIRUGIAS">
  </div>





  <div class="col-4" style="margin-top: 2VH;">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
HORA DE ULTIMA COMIDA Y QUE COMIÓ:
    </label>

  </div>


  <div class="col-8" style="margin-top: 2VH;">

    <input  class="form-control" placeholder="" formControlName="ULT_COMIDA">
  </div>


  <div class="col-4" style="margin-top: 2VH;">
    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
EVENTOS PREVIOS:
    </label>

  </div>


  <div class="col-8" style="margin-top: 2VH;">

    <input  class="form-control" placeholder="" formControlName="EVENTOS_PREV">
  </div>


  <div style="margin-top: 2.5VH;" class="col-6">
<label for="">PRIORIDAD TRIAGE:</label>
    <select class="form-select" aria-label="Default select example" formControlName="TRIAGE">

      <option value="ROJO">ROJO </option>
      <option value="AMARILLO">AMARILLO</option>
      <option value="VERDE">VERDE</option>
      <option value="NEGRO">NEGRO</option>

    </select>


    </div>


    <h4 style="border: solid; border-color: black;margin-top: 2vh;">IV MANEJO PREHOSPITALARIO </h4>


  <div class="col-6" style="margin-top: 2VH;">
      <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  Evaluación:
      </label>
      <input class="form-control" placeholder="" formControlName="EVALUACION_PRI">

    </div>

    <div class="col-6" style="margin-top: 2VH;">
      <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
  Tratamiento:
      </label>
      <input class="form-control" placeholder="" formControlName="TRATAMIENTO">
    </div>




      <div style="margin-top: 2.5VH;" class="col-6">
        <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">

            </label>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="INM_CERVICAL">
          <label class="form-check-label" for="inlineCheckbox1">INMOV. CERVICAL</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" formControlName="COLLARIN">
          <label class="form-check-label" for="inlineCheckbox2">COLLARIN</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="INM_ESPINAL">
          <label class="form-check-label" for="inlineCheckbox1">INMV. ESPINAL</label>
        </div>
        <br>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" formControlName="EMPAQUETAMIENTO">
          <label class="form-check-label" for="inlineCheckbox2">EMPAQUETAMIENTO</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="OROFARINGEA">
          <label class="form-check-label" for="inlineCheckbox1">CÁNULA OROFARINGEA</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" formControlName="ASPIRACION">
          <label class="form-check-label" for="inlineCheckbox2">ASPIRACION</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"formControlName="OVACE">
          <label class="form-check-label" for="inlineCheckbox1">O.V.A.C.E</label>
        </div>






        </div>



        <div style="margin-top: 2.5VH;" class="col-6">
          <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">

              </label>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"formControlName="OXIGENACION">
            <label class="form-check-label" for="inlineCheckbox1">OXIGENACIÓN</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" formControlName="BVM">
            <label class="form-check-label" for="inlineCheckbox2">B.V.M</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"formControlName="NASALES">
            <label class="form-check-label" for="inlineCheckbox1">PUNTILLAS NASALES</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"formControlName="FERULA">
            <label class="form-check-label" for="inlineCheckbox2">FERULA</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"formControlName="CANULA">
            <label class="form-check-label" for="inlineCheckbox2">CÁNULA NASAL</label>
          </div>

       <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"formControlName="TORACOCENTESIS">
            <label class="form-check-label" for="inlineCheckbox2">TORACONCENTESIS</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"formControlName="APLI_MED">
            <label class="form-check-label" for="inlineCheckbox2">APLI. DE. MED</label>
          </div>



          </div>

          <div class="col-6">
            <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
          Solución:
            </label>
            <input  class="form-control" placeholder="" formControlName="SOLUCION_MANEJO">
          </div>





  <div style="margin-top: 2.5VH;" class="col-12">
<label for="">Cateter:</label>
    <select class="form-select" aria-label="Default select example" formControlName="CATETER">

      <option value="14 Naranja">14 Naranja </option>
      <option value="17 Rojo">17 Rojo</option>
      <option value="16 Café">16 Café</option>
      <option value="18 Verde">18 Verde</option>
      <option value="20 Rosa">20 Rosa</option>
      <option value="22 Azul">22 Azul</option>
      <option value="24 Amarillo">24 Amarillo</option>

    </select>


    </div>

    <div class="col-6" style="margin-top: 2vh;">
      <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
    Farmacos y Dosis:
      </label>
      <input  class="form-control" placeholder="" formControlName="FARMACO">
    </div>

    <div class="col-6" style="margin-top: 2vh;">
      <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
    Médico que Autoriza:
      </label>
      <input  class="form-control" placeholder="" formControlName="MEDICO_AUT">
    </div>

    <div class="col-6">
      <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
    Material consumido y/o medicamentos:
      </label>
      <input  class="form-control" placeholder="" formControlName="MAT_CONSU">
    </div>
    <div class="col-6" style="margin-top: 2.5vh;">
      <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
    Cantidad:
      </label>
      <input  class="form-control" placeholder="" formControlName="CANTIDAD">
    </div>



            </div>

            <h4 style="border: solid; border-color: black;margin-top: 2vh;">V CAUSA CLINICA </h4>


            <div style="margin-top: 2.5VH;" class="col-12">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">

                  </label>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"  formControlName="NEUROLOGICO">
                <label class="form-check-label" for="inlineCheckbox1">NEUROLOGICO</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" formControlName="DIGESTIVO">
                <label class="form-check-label" for="inlineCheckbox2">DIGESTIVO</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="METABOLICO">
                <label class="form-check-label" for="inlineCheckbox1">METABOLICO</label>
              </div>


              </div>
              <div style="margin-top: 2.5VH;" class="col-12">
                <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">

                    </label>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="CARDIOVASCULAR">
                  <label class="form-check-label" for="inlineCheckbox1">CARDIOVASCULAR</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" formControlName="ENDOCRINO">
                  <label class="form-check-label" for="inlineCheckbox2">ENDOCRINO</label>
                </div>

                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="OSTEOMUSCULAR">
                  <label class="form-check-label" for="inlineCheckbox1">OSTEOMUSCULAR</label>
                </div>


                </div>

                <div style="margin-top: 2.5VH;" class="col-12">
                  <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">

                      </label>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"  formControlName="RESPIRATORIO">
                    <label class="form-check-label" for="inlineCheckbox1">RESPIRATORIO</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" formControlName="RENAL">
                    <label class="form-check-label" for="inlineCheckbox2">RENAL</label>
                  </div>

                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="ALTER_EMOC">
                    <label class="form-check-label" for="inlineCheckbox1">ALTERACION EMOCIONAL</label>
                  </div>


                  </div>




                  <div style="margin-top: 2.5VH;" class="col-12">
                    <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">

                        </label>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"  formControlName="UROGENITAL">
                      <label class="form-check-label" for="inlineCheckbox1">UROGENITAL</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" formControlName="GINECO_OBS">
                      <label class="form-check-label" for="inlineCheckbox2">GINECO-OBSTETRICO</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" formControlName="HEMATOLOGICO">
                      <label class="form-check-label" for="inlineCheckbox1">HEMATOLOGICO</label>
                    </div>


                    </div>

            <div class="col-6">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
            Otra:
              </label>
              <input  class="form-control" placeholder="" formControlName="OTRO_CAUSA_C">
            </div>

            <div class="col-6" style="margin-top: 2.5vh;">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
            Especifique:
              </label>
              <input  class="form-control" placeholder="" formControlName="ESPECIFIQUE">
            </div>

      <div class="col-12" style="margin-top: 2.5vh;">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
            ANTECENDETES PATOLOGICOS:
              </label>
              <input  class="form-control" placeholder="" formControlName="ANTECEDENTES_PAT">
            </div>




            <div class="row">
<div class="col-4"></div>
<div class="col-4"> <button type="submit" style="width: 25vh; background-color: brown; border-color: brown; color: aliceblue; border-radius: 1vh;margin-top: 2vh; ">   Siguiente   </button></div>
<div class="col-4"></div>

            </div>





          </form>
          </div>
        </div>
      </div>
    </section>



  </section><!-- End Services Section -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
