import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss']
})
export class FiltroComponent {

  documento: any;
  token: any;
  var: any

  constructor(private api: GeneralService, private router: Router, private alertas: SweetAlertService) { }

  nuevoPC = new FormGroup({
    NOMBRE: new FormControl('', Validators.required),

  });


  ngOnInit() {




  }


  postForm() {
    this.var = this.nuevoPC.value.NOMBRE
    console.log(this.var);

    this.api.filtropc(this.var).subscribe(res => {
      this.documento = res.body;

      console.log(this.documento);

      if (res.body.length === 0) {
        this.alertas.alertaError('Error en la busqueda', 'La razón social solicitada no esta registrada o se registró con un nombre diferente.')

      }else{
            localStorage.setItem('constancia',this.var)
      this.alertas.realizado('Realizado','La Razón social fué localizada con exito.').then((res: any) => {
        this.router.navigate(['admin/proteccion-civil/constancias'])
      })
      }



    })



  }





}
