<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Blog Single</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li><a href="blog.html">Blog</a></li>
            <li>Blog Single</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog" style="margin-top: 2vh;">
      <div class="container" data-aos="fade-up" style="margin-top: 2vh;">

        <div class="row">

          <div class="col-lg-8 entries">

            <article class="entry entry-single">

              <div class="entry-img">
                <img src="/assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
              </div>

              <h2 style="text-align: center; justify-content: center;" class="entry-title">
                <a style="text-align: center;">Museo y Centro Regional de las Artes</a>
              </h2>

              <div class="entry-meta" style="text-align: center; justify-content: center;">
                <ul style="text-align: center; justify-content: center;">
                  <li class="d-flex align-items-center"><img height="20" width="20"
                      src="https://img.icons8.com/ios/50/null/physical-gallery.png" /> <a>Lugar Cultural</a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20"
                      src="https://img.icons8.com/ios/50/null/address--v1.png" /> <a><time
                        datetime="2020-01-01"></time></a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20"
                      src="https://img.icons8.com/wired/64/null/tags.png" /> <a>Gratuito</a></li>
                </ul>
              </div>

              <div class="entry-content">



                <div style="justify-content: center; text-align: center;">
                  <img src="https://i.imgur.com/NBLE2Ji.png" width="600" height="400" style="border-radius: 2vh;"
                    alt="">
                </div>
                <p style="text-align: justify; margin-top: 2vh;">
                  De reciente fundación, el Museo y Centro Regional de las Artes de Autlán tiene por objetivo conservar,
                  restaurar, investigar y difundir el patrimonio cultural del municipio. Las diferentes salas del museo
                  exhiben muestras de arte sacro, algunos vestigios
                  arqueológicos, vestimenta tradicional, utensilios y aparatos de uso cotidiano del siglo pasado, así
                  como una sala dedicada al músico Carlos Santana, otra al pintor José Atanasio Monroy y una tercera a
                  los músicos de Autlán y de la región.

                </p>

                <p style="text-align: justify;">
                  El museo cuenta con 10 salas permanentes y un salón de usos múltiples:
                  Sala de Arte Sacro<br>
                  Dedicada a las manifestaciones artísticas religiosas, en esta sala se puede encontrar una pintura de
                  Nuestra Señora de la Gracia, la cual fue traída de Italia y que data de 1800 aproximadamente. Así
                  mismo encontramos casullas, dalmáticas, estolas y una capa
                  pluvial, que datan de 1850, así como crucifijos, ofrendas, una custodia y un libro escrito en latín.

                </p>





                <p style="text-align: justify;">
                  Sala de Costumbres y Tradiciones<br>
                  El público puede observar en esta sala máquinas de escribir de principios del siglo XX; vestimenta de
                  una reina del carnaval taurino y una manola, así como el traje típico de la chirimía de la familia
                  López Peña que es una tradición que data de 1831; una cómoda tallada a
                  mediados del siglo pasado; un instrumento de campo conocido como burro, que tiene colocado un fuste de
                  madera con incrustaciones de concha y mármol y una silla de montar piteada y fuste de plata con
                  figuras de dragón; el traje típico de los hombres y las mujeres de Autlán;
                  una cámara fotográfica Kodak con una maleta o maletín que fungía como caja de revelado, ambos de los
                  años treinta del siglo pasado, y una cocina tradicional.
                </p>


                <p style="text-align: justify;">

                  Sala Músicos de la Región<br>
                  Sala dedicada a los músicos de la región, donde mediante una pantalla auditiva, se pude escuchar
                  música de Clemente Amaya Radillo, Aurea Corona Corona, fray Octavio Guadalupe Michel Corona y Hermilio
                  Hernández López, entre otros. Se pueden observar algunas partituras originales
                  de los mismos músicos, fotografías y publicaciones de la época; el instrumento de viento llamado
                  barítono que perteneció a Jaime Gómez, quien fuera uno de los creadores de la banda Autlán y una
                  guitarra quinta que perteneció a Manuel Morán.


                </p>

                <p style="text-align: justify;">

                  Sala Carlos Santana <br>
                  En la primera de dos salas dedicadas a CarlosSantana encontramos guitarras eléctricas, retratos,
                  fotografías, discos de acetato y algunos suvenires como cojines y playeras. En la segunda sala
                  encontramos las portadas de sus álbumes,
                  posters de sus presentaciones, un traje utilizado en alguno de sus conciertos, un timbal original y
                  dos tamborcitos.


                </p>

                <p style="text-align: justify;">

                  Sala Carlos Santana <br>
                  Es el pintor más reconocido de Autlán. En esta sala se exhiben 30 cuadros de su autoría y un boceto.
                  Podemos observar sus herramientas de trabajo, su banquito, pinturas y pinceles. Existen algunos
                  cuadros que ilustran postales típicas de Autlán, como la Iglesia de las Montañas en los tiempos
                  remotos, la antigua fachada de la presidencia y la parroquia del Divino Salvador, además de escenas
                  costumbristas, paisajes, bodegones y retratos.



                </p>

                <p style="text-align: justify;">

                  En dos salas se exhiben piezas arqueológicas que señalan un Autlán en los periodos temprano y tardío.
                  En el primero se pueden apreciar figuras humanas y un metate y en el segundo, vasijas, una olla
                  funeraria, un huilanche, herramientas de trabajo (hachas) y una réplica de un mapa pintado al óleo que
                  data del año de 1535, donde se puede apreciar la llegada de los españoles a la región de Cuzalapa, así
                  como un petroglifo que data entre los años 600 y 900.

                </p>


                <p style="text-align: justify;">

                  Sala de Servicios Educativos <br>
                  Sala dedicada a los niños. Mediante un juego interactivo ellos pueden aprender lo que es el patrimonio cultural, natural, tangible e intangible.
                </p>



                <div style="justify-content: center; text-align: center;">
                  <img src="https://i.imgur.com/FNCUMsr.png" width="600" height="400" style="border-radius: 2vh;"
                    alt="">
                </div>



                <br><br>

                <p style="text-align: justify; color: rgb(9, 48, 147);font-size: 2vh;">

                  Referencias:

                </p>
                <p style="text-align: justify;">

                  Sistema de Información Cultural SIC México


                </p>




              </div>

              <!-- <div class="entry-footer">
                <i class="bi bi-folder"></i>
                <ul class="cats">
                  <li><a href="#">Business</a></li>
                </ul>

                <i class="bi bi-tags"></i>
                <ul class="tags">
                  <li><a href="#">Creative</a></li>
                  <li><a href="#">Tips</a></li>
                  <li><a href="#">Marketing</a></li>
                </ul>
              </div> -->

            </article><!-- End blog entry -->


          </div><!-- End blog entries list -->



          <div class="col-lg-4">

            <div class="sidebar">





              <h3 class="sidebar-title">Lugares de Intéres</h3>
              <div class="sidebar-item recent-posts">


                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/2sVV4Hq.png" alt="">
                  <h4><a href="admin/lugares-de-interes/catedral">Catedral de la Santísima Trinidad</a></h4>
                  <time datetime="2020-01-01">Calle M. Hidalgo 74, Centro, 48900 Autlán de Navarro, Jal.
                  </time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/UpMJ6td.png" alt="">
                  <h4><a href="admin/lugares-de-interes/jardin">Jardin "Miguel Hidalgo"</a></h4>
                  <time datetime="2020-01-01"> Margarito González Rubio 9, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/Dxa9ct9.png" alt="">
                  <h4><a href="admin/lugares-de-interes/casa-de-la-familia">Casa de la familia Santana Barragán</a></h4>

                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NBLE2Ji.png" alt="">
                  <h4><a href="admin/lugares-de-interes/museo">Museo y Centro Regional de las Artes </a></h4>
                  <time datetime="2020-01-01"> C. José María Morelos 47, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/HAVjRry.png" alt="">
                  <h4><a href="admin/lugares-de-interes/parroquia">Parroquia de El Divino Salvador "El Sagrario"</a></h4>
                  <time datetime="2020-01-01">Margarito González Rubio 10, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NbxePys.png" alt="">
                  <h4><a href="admin/lugares-de-interes/monumentos-ninos-heroes">Monumento a los Niños Heroes</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/OE2jTNN.png" alt="">
                  <h4><a href="admin/lugares-de-interes/plaza-civica">Plaza Civica</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/DEKzly6.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Capilla">Cerro de la Capilla</a></h4>
                  <time datetime="2020-01-01">Noroeste de la ciudad de Autlán</time>
                </div>
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/ojgjmCF.jpg" alt="">
                  <h4><a href="admin/lugares-de-interes/alameda">Parque Paulino Navarro o Alameda</a></h4>
                  <time datetime="2020-01-01"> La Alameda, 48900 Autlán de Navarro, Jal.</time>
                </div>
              </div><!-- End sidebar recent posts-->


            </div><!-- End sidebar -->

          </div><!-- End blog sidebar -->



          </div><!-- End blog sidebar -->

        </div>


    </section><!-- End Blog Single Section -->

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
