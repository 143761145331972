import { DirectorioComponent } from './../directorio/directorio.component';
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';

import { LoginComponent } from '../LOGIN/login/login.component';


import { GobiernoComponent } from '../gobierno/gobierno.component';
import { DIFComponent } from '../dif/dif.component';
import { IMujerComponent } from '../i-mujer/i-mujer.component';
import { InfoFundamentalComponent } from '../info-fundamental/info-fundamental.component';
import { SesionesComponent } from '../sesiones/sesiones.component';
import { TramitesYServiciosComponent } from '../tramites-y-servicios/tramites-y-servicios.component';
import { VerNoticiaComponent } from '../ver-noticia/ver-noticia.component';
import { ConvocatoriaComponent } from '../convocatoria/convocatoria.component';
import { IndexComponent } from '../index/index.component';
import { TeamComponent } from '../team/team.component';
import { SubirNoticiaComponent } from '../subir-noticia/subir-noticia.component';
import { SubirImgInicioComponent } from '../subir-img-inicio/subir-img-inicio.component';
import { DesarrolloUrbanoComponent } from '../tramites-y-servicios/datos/desarrollo-urbano/desarrollo-urbano.component';
import { PasaporteComponent } from '../tramites-y-servicios/datos/pasaporte/pasaporte.component';
import { SecretariaGeneralComponent } from '../tramites-y-servicios/datos/secretaria-general/secretaria-general.component';
import { SeguridadPublicaComponent } from '../tramites-y-servicios/datos/seguridad-publica/seguridad-publica.component';
import { DesarrolloEconomicoComponent } from '../tramites-y-servicios/datos/desarrollo-economico/desarrollo-economico.component';
import { GirosComponent } from '../tramites-y-servicios/datos/secretaria-general/giros/giros.component';
import { ProvisionalesComponent } from '../tramites-y-servicios/datos/secretaria-general/provisionales/provisionales.component';
import { ConstanciasComponent } from '../tramites-y-servicios/datos/secretaria-general/constancias/constancias.component';
import { SubirTextoComponent } from '../gobierno/subir-texto/subir-texto.component';
import { LogoComponent } from 'src/app/shared/components/sidenav-admin/logo/logo.component';
import { CarnavalQrComponent } from '../Rutas-Provisionales/carnaval-qr/carnaval-qr.component';
import { ActualizarComponent } from 'src/app/shared/components/footer-admin/actualizar/actualizar.component';
import { CiudadComponent } from '../ciudad/ciudad.component';
import { TurismoComponent } from '../ciudad/turismo/turismo.component';
import { LugaresDeInteresComponent } from '../ciudad/lugares-de-interes/lugares-de-interes.component';
import { PlazaDeTorosComponent } from '../ciudad/lugares-de-interes/plaza-de-toros/plaza-de-toros.component';
import { ContanctComponent } from '../contanct/contanct.component';
import { BuscarComponent } from '../buscar/buscar.component';
import { ContratosComponent } from '../contratos/contratos.component';
import { ProteccionCivilComponent } from '../tramites-y-servicios/datos/proteccion-civil/proteccion-civil.component';
import { RegistroComponent } from '../tramites-y-servicios/datos/proteccion-civil/registro/registro.component';
import { SimulacroComponent } from '../tramites-y-servicios/datos/proteccion-civil/simulacro/simulacro.component';
import { AvisosComponent } from '../avisos/avisos.component';
import { SolicitudesComponent } from '../solicitudes/solicitudes.component';
import { ListaComponent } from '../solicitudes/lista/lista.component';
import { DatosComponent } from '../tramites-y-servicios/datos/datos.component';
import { EstatusComponent } from '../solicitudes/lista/estatus/estatus.component';
import { AuthEGuard } from 'src/app/core/guard/authE.guard';
import { CerritoComponent } from '../ciudad/lugares-de-interes/cerrito/cerrito.component';
import { CatedralComponent } from '../ciudad/lugares-de-interes/catedral/catedral.component';
import { JardinComponent } from '../ciudad/lugares-de-interes/jardin/jardin.component';
import { MonumentoNhComponent } from '../ciudad/lugares-de-interes/monumento-nh/monumento-nh.component';
import { MuseoComponent } from '../ciudad/lugares-de-interes/museo/museo.component';
import { ParroquiaComponent } from '../ciudad/lugares-de-interes/parroquia/parroquia.component';
import { PlazaCivicaComponent } from '../ciudad/lugares-de-interes/plaza-civica/plaza-civica.component';
import { HaciendaComponent } from '../tramites-y-servicios/datos/hacienda/hacienda.component';
import { PrivacyComponent } from '../Noticia1/privacy.component';
import { TestimonialsComponent } from '../Noticia2/testimonials.component';
import { ConstanciaComponent } from '../tramites-y-servicios/datos/proteccion-civil/constancia/constancia.component';
import { FiltroComponent } from '../tramites-y-servicios/datos/proteccion-civil/filtro/filtro.component';
import { DocumentoComponent } from '../archivos/documento/documento.component';
import { DocumentoPCComponent } from '../tramites-y-servicios/datos/proteccion-civil/documento/documentoPC.component';
import { GenerarReporteComponent } from '../tramites-y-servicios/datos/proteccion-civil/generar-reporte/generar-reporte.component';
import { ConferenciaComponent } from '../conferencia/conferencia.component';
import { ConstComponent } from '../conferencia/constancias/constancias.component';
import { ListadoComponent } from '../conferencia/listado/listado.component';
import { HubComponent } from '../hub/hub.component';
import { PatronatoComponent } from '../ciudad/patronato/patronato.component';
import { AgentesComponent } from '../team/agentes/agentes.component';
import { DirectoresComponent } from '../team/directores/directores.component';
import { JefesComponent } from '../team/jefes/jefes.component';
import { CasaFamiliaComponent } from '../ciudad/lugares-de-interes/casa-familia/casa-familia.component';
import { AlamedaComponent } from '../ciudad/lugares-de-interes/alameda/alameda.component';
import { CronistaComponent } from '../cronista/cronista.component';
import { VerCronicaComponent } from '../cronista/ver-cronica/ver-cronica.component';
import { BannerCronistaComponent } from '../cronista/banner-cronista/banner-cronista.component';
import { SubirCronicaComponent } from '../cronista/subir-cronica/subir-cronica.component';
import { FraseCronistaComponent } from '../cronista/banner-cronista/frase-cronista/frase-cronista.component';
import { ArchivosComponent } from '../archivos/archivos.component';
import { ProgramaAnualComponent } from '../archivos/programa-anual/programa-anual.component';
import { ServiciosPCComponent } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/servicios-pc.component';
import { NuevoServicioComponent } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/nuevo-servicio/nuevo-servicio.component';
import { HospitalarioComponent } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/hospitalario/hospitalario.component';
import { GestionServiciosComponent } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/gestion-servicios/gestion-servicios.component';
import { ServiciopcidComponent } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/serviciopcid/serviciopcid.component';
import { HospitalComponent } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/hospital/hospital.component';
import { PInternoComponent } from '../p-interno/p-interno.component';
import { FichaPComponent } from '../p-interno/ficha-p/ficha-p.component';
import { TramiteComponent } from '../p-interno/tramite/tramite.component';
import { OrganizacionPiComponent } from '../p-interno/tramite/organizacion-pi/organizacion-pi.component';
import { CalendarioPiComponent } from '../p-interno/tramite/calendario-pi/calendario-pi.component';
import { DirectorioPiComponent } from '../p-interno/tramite/directorio-pi/directorio-pi.component';
import { RiesgosPiComponent } from '../p-interno/tramite/riesgos-pi/riesgos-pi.component';
import { PreventivoPiComponent } from '../p-interno/tramite/preventivo-pi/preventivo-pi.component';
import { CapacitacionPiComponent } from '../p-interno/tramite/capacitacion-pi/capacitacion-pi.component';
import { AuxilioPiComponent } from '../p-interno/tramite/auxilio-pi/auxilio-pi.component';
import { RiesgoComponent } from '../p-interno/tramite/riesgo/riesgo.component';
import { PropositoComponent } from '../p-interno/tramite/proposito/proposito.component';
import { RecursosPiComponent } from '../p-interno/tramite/recursos-pi/recursos-pi.component';
import { ComunicacionesPiComponent } from '../p-interno/tramite/comunicaciones-pi/comunicaciones-pi.component';
import { PrevencionComponent } from '../prevencion/prevencion.component';
import { BrigadistasComponent } from '../brigadistas/brigadistas.component';
import { ListaPiComponent } from '../lista-pi/lista-pi.component';
import { EmpresaComponent } from '../p-interno/empresa/empresa.component';
import { DictamenesComponent } from '../dictamenes/dictamenes.component';
import { RegistroDictamenComponent } from '../dictamenes/registro-dictamen/registro-dictamen.component';
import { RegistroListaComponent } from '../dictamenes/registro-lista/registro-lista.component';
import { DictamenIdComponent } from '../dictamenes/dictamen-id/dictamen-id.component';
import { RegistrosEmpresaComponent } from '../lista-pi/registros-empresa/registros-empresa.component';
import { EscuelaComponent } from '../p-interno/escuela/escuela.component';
import { TipoTramiteComponent } from '../p-interno/tipo-tramite/tipo-tramite.component';
import { BusquedaEmpresaComponent } from '../p-interno/busqueda-empresa/busqueda-empresa.component';
import { SlectServicioComponent } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/slect-servicio/slect-servicio.component';
import { GesHospitalComponent } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/ges-hospital/ges-hospital.component';
import { HospitalarioIdComponent } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/hospitalario-id/hospitalario-id.component';
import { HospitalarioIdPart2Component } from '../tramites-y-servicios/datos/proteccion-civil/servicios-pc/hospitalario-id-part2/hospitalario-id-part2.component';







const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/
  {

    path: 'login',
    component: LoginComponent
  },
  {
    path: 'gobierno',
    component: GobiernoComponent
  },
  {
    path: 'dif',
    component: DIFComponent
  },
  {
    path: 'instituto-de-la-mujer',
    component: IMujerComponent
  },
  {
    path: 'info-fundamental',
    component: InfoFundamentalComponent
  },
  {
    path: 'sesiones',
    component: SesionesComponent
  },
  {
    path: 'tramites-y-servicios',
    component: TramitesYServiciosComponent
  },
  {
    path: 'nominas',
    component: TramitesYServiciosComponent
  },
  {
    path: 'directorio',
    component: DirectorioComponent
  },

  {
    path: 'ver-noticia/:id',
    component: VerNoticiaComponent
  },
  {
    path: 'convocatoria-un-patrimonio-para-tu-familia',
    component: ConvocatoriaComponent
  },
  {
    path: 'indice',
    component: IndexComponent
  },
  {
    path: 'equipo',
    component: TeamComponent
  },
  {
    path: 'subir-noticia',
    component: SubirNoticiaComponent
  },
  {
    path: 'editar-banner',
    component: SubirImgInicioComponent
  },
  {
    path: 'tramites-y-servicios/1',
    component: DesarrolloUrbanoComponent
  },
  {
    path: 'tramites-y-servicios/2',
    component: PasaporteComponent
  },
  {
    path: 'tramites-y-servicios/3',
    component: SecretariaGeneralComponent
  },
  {
    path: 'tramites-y-servicios/4',
    component: SeguridadPublicaComponent
  },
  {
    path: 'tramites-y-servicios/5',
    component: DesarrolloEconomicoComponent
  },
  {
    path: 'tramites-y-servicios/6',
    component: ProteccionCivilComponent
  },

  {
    path: 'tramites-y-servicios/7',
    component: HaciendaComponent
  },
  {
    path: 'tramites-y-servicios/secretaria-general/giros',
    component: GirosComponent
  },
  {
    path: 'tramites-y-servicios/secretaria-general/provisionales',
    component: ProvisionalesComponent
  },
  {
    path: 'tramites-y-servicios/secretaria-general/constancias',
    component: ConstanciasComponent
  },
  {
    path: 'gobierno/subir',
    component: SubirTextoComponent
  },
  {
    path: 'logo',
    component: LogoComponent
  },
  {
    path: 'carnaval/Autlan/2023',
    component: CarnavalQrComponent
  },
  {
    path:'pie-de-pagina/actualizar',
    component: ActualizarComponent
  }
  ,
  {
    path:'ciudad',
    component: CiudadComponent
  }
  ,
  {
    path:'turismo',
    component: TurismoComponent
  }
  ,
  {
    path:'svg',
    component: LugaresDeInteresComponent
  } ,
  {
    path:'lugares-de-interes/Plaza-de-Toros',
    component: PlazaDeTorosComponent
  },
  {
    path:'lugares-de-interes',
    component: CiudadComponent
  }
  ,
  {
    path:'contac',
    component: ContanctComponent
  },
  {
    path:'buscar',
    component: BuscarComponent,
    canActivate:[AuthEGuard],
  },
  {
    path: 'contratos/:anio/:tri',
    component: ContratosComponent
  },
  {
    path: 'proteccion-civil/registro',
    component: RegistroComponent
  },
  {
    path: 'proteccion-civil/simulacro',
    component: SimulacroComponent
  },
  {
    path: 'avisos-de-privacidad',
    component: AvisosComponent
  }
  ,
  {
    path: 'solicitudes',
    component: SolicitudesComponent,
    canActivate:[AuthEGuard],
  }
  ,
  {
    path: 'lista-solicitudes',
    component: ListaComponent,
    canActivate:[AuthEGuard],
  }

  ,
  {
    path: 'listado/:id',
    component: EstatusComponent,
    canActivate:[AuthEGuard],
  },
  {
    path: 'lugares-de-interes/Capilla',
    component: CerritoComponent
  },
  {
    path: 'lugares-de-interes/jardin',
    component: JardinComponent
  }
  ,
  {
    path: 'lugares-de-interes/monumentos-ninos-heroes',
    component: MonumentoNhComponent
  }
  ,
  {
    path: 'lugares-de-interes/museo',
    component: MuseoComponent
  }
  ,
  {
    path: 'lugares-de-interes/parroquia',
    component: ParroquiaComponent
  }
  ,
  {
    path: 'lugares-de-interes/catedral',
    component: CatedralComponent
  }
  ,
  {
    path: 'lugares-de-interes/plaza-civica',
    component: PlazaCivicaComponent
  }
  ,
  {
    path: 'noticias/1',
    component: PrivacyComponent
  },

  {
    path: 'noticias/2',
    component: TestimonialsComponent
  },

  {
    path: 'proteccion-civil/constancias',
    component: ConstanciaComponent
  },

  {
    path: 'proteccion-civil/filtro',
    component:FiltroComponent
  },

  {
    path: 'proteccion-civil/imprimir-constancia',
    component:DocumentoPCComponent
  },
  {
    path: 'proteccion-civil/reporte',
    component:GenerarReporteComponent
  }
  ,
  {
    path: 'proteccion-civil/formatos',
    component:RegistroComponent
  }
  ,
  {
    path: 'conferencia',
    component:ConferenciaComponent
  },
  {
    path: 'conferencia/constancia',
    component: ConstComponent
  },
  {
    path: 'conferencia/listado',
    component: ListadoComponent
  },
  {
    path: 'redes-sociales',
    component: HubComponent
  },
  {
    path: 'carnaval-de-autlan',
    component: PatronatoComponent
  },
  {
    path: 'equipo/agentes',
    component: AgentesComponent
  },
  {
    path: 'equipo/directores',
    component: DirectoresComponent
  },
  {
    path: 'equipo/directores',
    component: DirectoresComponent
  },
  {
    path: 'equipo/jefes',
    component: JefesComponent
  },
  {
    path: 'lugares-de-interes/casa-de-la-familia',
    component: CasaFamiliaComponent


   }, {
    path: 'lugares-de-interes/alameda',
    component: AlamedaComponent

  }, {
    path: 'cronista',
    component: CronistaComponent

  }, {
    path: 'cronica/:id',
    component: VerCronicaComponent

  }, {
    path: 'editar/cronista',
    component: BannerCronistaComponent

  }
  , {
    path: 'nueva/cronica',
    component: SubirCronicaComponent

  } , {
    path: 'editar/frase',
    component: FraseCronistaComponent

  } , {
    path: 'presupuestos',
    component: ArchivosComponent

  } , {
    path: 'programa/anual',
    component: ProgramaAnualComponent

  } , {
    path: 'proteccion-civil/servicios',
    component: ServiciosPCComponent,
    canActivate:[AuthEGuard],
  }
  , {
    path: 'proteccion-civil/nuevo/servicio',
    component: NuevoServicioComponent,
    canActivate:[AuthEGuard],
  }
  , {
    path: 'proteccion-civil/servicios/hospitalario',
    component: HospitalarioComponent,
    canActivate:[AuthEGuard],
  }
  , {
    path: 'proteccion-civil/servicios/gestion',
    component: GestionServiciosComponent,
    canActivate:[AuthEGuard],
  } , {
    path: 'proteccion-civil/servicios/:id',
    component: ServiciopcidComponent,
    canActivate:[AuthEGuard],
  }
  , {
    path: 'proteccion-civil/servicios/hospitalario/2',
    component: HospitalComponent,
    canActivate:[AuthEGuard],
  }, {
    path: 'proteccion-civil/pc/interno',
    component: PInternoComponent,

  }, {
    path: 'proteccion-civil/pc/interno/ficha-pago',
    component: FichaPComponent,

  }, {
    path: 'proteccion-civil/pc/interno/tramite',
    component: TramiteComponent,

  }
  , {
    path: 'proteccion-civil/pc/interno/organizacion',
    component: OrganizacionPiComponent,

  }
  , {
    path: 'proteccion-civil/pc/interno/calendario',
    component: CalendarioPiComponent,

  } , {
    path: 'proteccion-civil/pc/interno/directorio',
    component: DirectorioPiComponent,

  } , {
    path: 'proteccion-civil/pc/interno/riesgos',
    component: RiesgosPiComponent,

  } , {
    path: 'proteccion-civil/pc/interno/preventivo',
    component: PreventivoPiComponent,

  }, {
    path: 'proteccion-civil/pc/interno/capacitacion',
    component: CapacitacionPiComponent,

  }, {
    path: 'proteccion-civil/pc/interno/auxilio',
    component: AuxilioPiComponent,

  }, {
    path: 'proteccion-civil/pc/interno/riesgo',
    component: RiesgoComponent,

  }, {
    path: 'proteccion-civil/pc/interno/proposito',
    component: PropositoComponent,

  }, {
    path: 'proteccion-civil/pc/interno/recursos',
    component: RecursosPiComponent,

  }, {
    path: 'proteccion-civil/pc/interno/comunicaciones',
    component: ComunicacionesPiComponent,

  }, {
    path: 'proteccion-civil/pc/interno/brigadistas',
    component: BrigadistasComponent,

  }, {
    path: 'proteccion-civil/pc/interno/prevencion',
    component: PrevencionComponent,

  },
  {
    path: 'proteccion-civil/pc/interno/lista',
    component: ListaPiComponent,

  },{
    path: 'proteccion-civil/pc/interno/lista/registros',
    component: RegistrosEmpresaComponent,

  },
  {
    path: 'proteccion-civil/interno/empresa',
    component: EmpresaComponent,

  },
  {
    path: 'proteccion-civil/dictamen',
    component: DictamenesComponent,

  },
  {
    path: 'proteccion-civil/dictamen/registro',
    component: RegistroDictamenComponent,

  },
  {
    path: 'proteccion-civil/dictamen/lista',
    component: RegistroListaComponent,

  },
  {
    path: 'proteccion-civil/dictamen/ver/:id',
    component: DictamenIdComponent,

  },
  {
    path: 'proteccion-civil/riesgos/escuela',
    component: EscuelaComponent,

  },
  {
    path: 'proteccion-civil/brigadistas',
    component: BrigadistasComponent,

  },
  {
    path: 'proteccion-civil/tramite/seccion',
    component: TipoTramiteComponent,

  },
  {
    path: 'proteccion-civil/tramite/buscar',
    component: BusquedaEmpresaComponent,

  },
  {
    path: 'proteccion-civil/seleccionar/servicio',
    component: SlectServicioComponent,

  },
  {
    path: 'proteccion-civil/gestion/hospitalaria',
    component: GesHospitalComponent,

  },
  {
    path: 'proteccion-civil/gestion/hospitalaria/:id',
    component: HospitalarioIdComponent,

  },
  {
    path: 'proteccion-civil/gestion/hospitalaria/parte/secundaria',
    component: HospitalarioIdPart2Component,

  }
















];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class DashboardRoutingModule { }
