<section id="blog" class="blog">
  <div style="margin-top: 200px;border-radius: 1vh;" class="container" data-aos="fade-up" >



        <article class="entry entry-single" style="justify-content: center;text-align: center;">

          <h2 style="text-align: center;"> Extensiones Telefónicas por Área de Trabajo</h2>



            <div class="row">
              <div class="col-12">
                <img width="800" height="400" style="text-align: center; justify-content: center; "
                  src="https://i.imgur.com/GSTkLat.jpg">
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <img width="800" height="400" style="text-align: center; justify-content: center; "
                  src="https://i.imgur.com/9zLs2HP.jpg">
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <img width="800" height="500" style="text-align: center; justify-content: center; "
                  src="https://i.imgur.com/JqjKuk1.jpg">
              </div>
            </div>







        </article><!-- End blog entry -->




  </div>

<br>


</section><!-- End Blog Single Section -->





<section id="blog" class="blog">

  <div style="margin-top: 50px; border-radius: 1vh;" class="container" data-aos="fade-up">



    <article class="entry entry-single" style="justify-content: center;text-align: center;">


      <h1>Directorio Oficial Gobierno Mpal. 2021-2024</h1>

            <div class="row">
              <div class="col-12">
                <img width="600" height="800" style="text-align: center; justify-content: center;"
                  src="https://i.imgur.com/oTrS7jA.png">
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <img width="600" height="800" style="text-align: center; justify-content: center;"
                  src="https://i.imgur.com/bcrxzuN.png">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <img width="600" height="800" style="text-align: center; justify-content: center; "
                  src="https://i.imgur.com/3iKKWBu.png">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <img width="600" height="800" style="text-align: center; justify-content: center; "
                  src="https://i.imgur.com/8dERIoq.png">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <img width="600" height="800" style="text-align: center; justify-content: center;"
                  src="https://i.imgur.com/k10Hhea.png">
              </div>
            </div>



        </article><!-- End blog entry -->




  </div>
</section><!-- End Blog Single Section -->
