<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">

    <section>
      <div class="container">






            <div class="row justify-content-center" style="border: solid; border-radius: 2vh; border-color: rgb(255, 255, 255); box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753);">
              <div class="col-12 col-md-8 col-lg-8 col-xl-6">
                <form [formGroup]="nuevoPC" (ngSubmit)="postForm(this.nuevoPC.value)" action="">
                <div class="row">
                  <div class="col text-center">
                    <img src="https://i.imgur.com/JcnpEie.png" height="100" width="150" alt="" style="margin-top: 1vh;"> <h1 style="font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;">Charla de sensibilización y capacitación  <br> "Acerca del uso correcto del equipo de protección y educación vial a usuarios de motocicletas"</h1>
                    <p style="margin-top: 1vh; font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;"  class="text-h3">Rellene los datos para completar su registro. </p>
                  </div>
                </div>


                <div class="row align-items-center mt-4 form-floating mb-3">
                  <input  class="form-control" id="floatingInput" placeholder="name@example.com" formControlName="NOMBRE">
                  <label for="floatingInput">Nombre y Apellidos</label>
                </div>


                <div class="row align-items-center mt-4 form-floating mb-3">
                  <input  class="form-control" id="floatingInput" placeholder="name@example.com" formControlName="ESCUELA">
                  <label for="floatingInput">Escuela o Universidad</label>
                </div>



                <div class="row align-items-center mt-4 form-floating mb-3">
                  <input  class="form-control" id="floatingInput" placeholder="name@example.com"  formControlName="GRADO">
                  <label for="floatingInput">Grado</label>
                </div>


                <div class="row align-items-center mt-4 form-floating mb-3">
                  <input  class="form-control" id="floatingInput" placeholder="name@example.com" formControlName="CARRERA">
                  <label for="floatingInput">Carrera</label>
                </div>



                <div class="row align-items-center mt-4 form-floating mb-3">
                  <input  class="form-control" id="floatingInput" placeholder="name@example.com" formControlName="DIRECCION">
                  <label for="floatingInput">Dirección</label>
                </div>

                <div class="row align-items-center mt-4 form-floating mb-3">
                  <input  class="form-control" id="floatingInput" placeholder="name@example.com"  maxlength="11" formControlName="TELEFONO">
                  <label for="floatingInput">Télefono</label>
                </div>


                <div class="row align-items-center mt-4 form-floating mb-3">

                  <textarea id="floatingInput" class="form-control" placeholder="name@example.com" formControlName="CIUDAD" maxlength="100"></textarea>
                  <label for="floatingInput">Ciudad</label>
                </div>

                <div class="row align-items-center mt-4 form-floating mb-3">

                  <textarea id="floatingInput" class="form-control" placeholder="name@example.com" formControlName="EMAIL" ></textarea>
                  <label for="floatingInput">Correo</label>
                </div>




                <div class="row justify-content-start mt-4">
                  <div class="col">

                    <button type="submit"  class="btn  mt-4" style="width: 100%;margin-bottom: 5vh;background-color: #C20406;color: rgb(255, 255, 255);">Enviar</button>
                  </div>
                </div>

              </form>
              </div>
            </div>








      </div>
    </section>



  </section><!-- End Services Section -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
