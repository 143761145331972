

<section style="background-color:  #F3F3F4; height: 6vh; width: 100%;"></section>

<div class="wrapper">
  <section style="background-color: white; padding-left: 2vh; padding-right: 2vh; overflow: scroll;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel">

        <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
          <thead style="color: #ba0000;">
            <th scope="col">FOLIO</th>
            <th scope="col">EDAD</th>
            <th scope="col">GENERO</th>
            <th scope="col">FECHA</th>
            <th scope="col">OPERADOR</th>
            <th scope="col">AMBULANCIA</th>
            <th scope="col">BOMBEROS</th>
            <th scope="col">Impresiones</th>

          </thead>
          <tbody>
            <tr
              *ngFor="let dato of activos | paginate: { itemsPerPage: 15, currentPage: pages, id:'activos' }; let i = index">
              <td (click)="id(dato.ID)">{{dato.ID}}</td>
              <td (click)="id(dato.ID)">{{dato.EDAD}}</td>
              <td (click)="id(dato.ID)">{{dato.SEXO}}</td>
              <td (click)="id(dato.ID)">{{dato.FECHA}}</td>
              <td (click)="id(dato.ID)">{{dato.OPERADOR}}</td>
              <td (click)="id(dato.ID)">{{dato.AMBULANCIA}}</td>
              <td (click)="id(dato.ID)">{{dato.BOMBEROS}}</td>

              <td><button (click)="id(dato.ID)" style="background-color: #ba0000; color: #ffffff; border-radius: 1vh;border-color: #ba0000;"> Parte 1</button> </td>
              <td><button (click)="idPart2(dato.ID)" style="background-color: #ba0000; color: #ffffff; border-radius: 1vh;border-color: #ba0000;"> Parte 2</button> </td>
            </tr>
            <tr *ngIf="this.noEncontrado === true">
              <td style="justify-content: center; text-align: center;" colspan="6"><strong>No se encontro ningun
                  usuario con ese dato</strong></td>
            </tr>
          </tbody>
          <tr *ngIf="this.cargando === true">
            <td colspan="6">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" style="color: #ba0000;"></div>
              </div>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls id="activos" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
            nextLabel="Siguiente"></pagination-controls>
        </div>
      </div>

    </div>

  </section>
</div>



