import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import jsPDF from 'jspdf';
import { error } from 'console';
@Component({
  selector: 'app-registro-dictamen',
  templateUrl: './registro-dictamen.component.html',
  styleUrls: ['./registro-dictamen.component.scss']
})
export class RegistroDictamenComponent {
  razon:any
  concepto:any
  cantidad:any
  solicitante:any
  activos:any
   archivos: string[] = [];
 estatus:any
 id:any
 costos:any
 minimo:any
 media:any
 maxima:any
 titulo:any
 spinner:any
img:any
quest:any


nombre:any
propietario:any
celular:any
giro:any
domicilio:any
colonia:any
ent_calles:any
fija:any
flotante:any
horario:any
numero:any
folio:any
recibo:any
monto:any
seguridad:any
constancia:any
fecha:any
solicita:any
entr_calles:any
flota:any
pi_interno:any
aforo:any
total:any
estado_pi:any
estado_aforo:any
extra:any
ver:any
m_manual:any

   nuevoPC = new FormGroup({
     NOMBRE: new FormControl('', Validators.required),
     PROPIETARIO: new FormControl('', Validators.required),
     CELULAR: new FormControl('', Validators.required),
     GIRO: new FormControl('', Validators.required),
     DOMICILIO: new FormControl('', Validators.required),
     COLONIA: new FormControl('', Validators.required),
     ENT_CALLES: new FormControl('', Validators.required),
     P_FIJA: new FormControl('', Validators.required),
     P_FLOTANTE: new FormControl('', Validators.required),
     HORARIO: new FormControl('', Validators.required),
     NUMERO: new FormControl('', Validators.required),
     FOLIO: new FormControl('', Validators.required),
     RECIBO: new FormControl('', Validators.required),
     MONTO: new FormControl('', Validators.required),
     M_MANUAL: new FormControl('', Validators.required),
     EXTRA: new FormControl('', Validators.required),
     SEGURIDAD: new FormControl('', Validators.required),
     CONSTANCIA_DE_SINIESTRO: new FormControl('', Validators.required),
     FECHA: new FormControl('', Validators.required),
     SOLICITANTE: new FormControl('', Validators.required),
     ESTATUS: new FormControl('', Validators.required),

   });
manual:any
   constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,) { }

   ngOnInit(): void {
    this.manual=0
    this.spinner=false
    this.img=false
    this.quest=true
    this.api.listaPcDictamenCostos().subscribe(res => {
      this.activos = res.body
      console.log(this.activos);

    })
    this.estado_aforo=0
    this.estado_pi=0
   }
   selectID(id:any){
    localStorage.setItem('id',id)
   }

   costo(){
    this.spinner=true
    this.img=false
    this.quest=false
  this.id=this.nuevoPC.value.CONSTANCIA_DE_SINIESTRO
    this.api.DictamenCostoID(this.id).subscribe(res => {
      this.costos = res.body
      console.log(this.costos);
      this.titulo=this.costos[0].CONSTANCIA_DE_SINIESTRO
      this.minimo=this.costos[0].MINIMA
      this.media=this.costos[0].MEDIA
      this.maxima=this.costos[0].MAXIMA
      this.pi_interno=this.costos[0].P_INTERNO
      this.aforo=this.costos[0].AFORO
      this.spinner=false
      this.img=true
      this.quest=false

      this.quest=false

if(this.pi_interno!=''){
this.estado_pi=1
}else{
  this.estado_pi=0

}

if(this.aforo!=''){
  this.estado_aforo=1
  }else{
    this.estado_aforo=0
  }


    },error=>{

      this.quest=true
      this.spinner=false
    })
   }


   M_MANUAL(){
this.manual=1
console.log(this.manual);

   }

   postForm(form: any) {




     this.alerts.alertaConfirmacionAgregar('Registro Datos Generales', '¿Desea enviar los datos?')
       .then((res: any) => {

         if (res.isConfirmed) {
           if (form.NOMBRE !== ''&&
            form.PROPIETARIO !== '' &&
            form.CELULAR !== '' &&
            form.GIRO !== '' &&
            form.DOMICILIO !== '' &&
            form.COLONIA !== '' &&

            form.P_FIJA !== '' &&

            form.HORARIO !== '' &&
            form.NUMERO !== '' &&
            form.SEGURIDAD !== '' &&

            form.FECHA !== '' &&
            form.SOLICITANTE !== ''




           ) {
             console.log(form);

if(this.estado_pi===1){

  form.EXTRA='Programa Interno: $2700'
}

if( this.estado_aforo===1){
  form.EXTRA='Programa Interno: $2700'

}

if(form.ESTATUS==""){
  form.ESTATUS=1

}
             this.api.PCDictamen(form).subscribe(data => {
               console.log(data);

this.nombre=this.nuevoPC.value.NOMBRE
this.propietario=this.nuevoPC.value.PROPIETARIO
this.celular=this.nuevoPC.value.CELULAR
this.giro=this.nuevoPC.value.GIRO
this.domicilio=this.nuevoPC.value.DOMICILIO
this.colonia=this.nuevoPC.value.COLONIA
this.entr_calles=this.nuevoPC.value.ENT_CALLES
this.fija=this.nuevoPC.value.P_FIJA
this.flota=this.nuevoPC.value.P_FLOTANTE
this.horario=this.nuevoPC.value.HORARIO
this.numero=this.nuevoPC.value.NUMERO
this.folio=this.nuevoPC.value.FOLIO
this.seguridad=this.nuevoPC.value.SEGURIDAD
this.fecha=this.nuevoPC.value.FECHA
this.solicita=this.nuevoPC.value.SOLICITANTE
this.recibo=this.nuevoPC.value.RECIBO
this.monto=this.nuevoPC.value.MONTO
this.m_manual=this.nuevoPC.value.M_MANUAL
this.nuevoPC.value.EXTRA=this.ver


               this.crearPDF()
               this.alerts.realizado('Completado', 'Se han enviado los datos con exito').then((res: any) => {

               })

             }, error => {

               this.alerts.alertaError('Ups', 'Error de registro')
               console.log(error);

             })

           } else {
             this.alerts.alertaError('Error de registro', 'Todos los campos son obligatorios');
           }

         }

       })

   }

verificar(){
  this.ver=this.nuevoPC.value.EXTRA
  console.log(this.ver);

}

   crearPDF() {
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "cm",
      format: [ 27.94,21.59]

    });





    doc.setFontSize(14);

    doc.addFont('Courier', 'Arial', 'normal');
    doc.setFont('Courier');

    var logo = new Image();
    logo.src = 'assets/img/img.png';
    doc.addImage(logo, 'JPEG', 0, 0,21.59, 27.94);
doc.text(this.nombre,6.8, 7.1  );
{
  doc.addFont('Courier', 'Arial', 'normal');
  doc.setFont('Courier');
  doc.setFontSize(14);

    doc.text(this.folio ,17,3.6);
    doc.text(this.recibo ,17,4.3);

  doc.text(this.propietario ,9.5, 7.9);
  doc.text(this.celular ,3.1, 8.7);
  doc.text(this.giro ,5, 9.4);
  doc.text(this.domicilio ,6.8,10.2);
  doc.text(this.numero ,17, 11);
  doc.text(this.colonia ,7.5, 11);
  doc.text(this.entr_calles ,5,11.7);
  doc.text(this.fija ,4.8,12.5);
  doc.text(this.flota ,13,12.5);
  doc.text(this.horario ,18.7,12.5);
  doc.text(this.solicita ,4,13.1);
  doc.text(this.fecha ,3.1,2.5);




}

{

  doc.setFontSize(14);
  doc.text( this.monto ,17,5);
  doc.text(  this.m_manual  ,17,5);
}
if(this.estado_pi===1){

  doc.text( '2700' ,5,3.7);
}

if(this.ver>9){

  doc.text(  '2700' ,5,3.7);
}

doc.save('Recibo.pdf')



  }

 }
