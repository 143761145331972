<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">


    <h3
      style="text-align: center; width: 100%;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; margin-top: 13vh; ">
      Protección Civil</h3>
    <div class="container" data-aos="fade-up">
      <div class="row">
        <div class="col-lg-3" data-aos-delay="200">
          <div style="border-radius: 3vh;" class="icon-box iconbox-orange ">
            <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10877G1Gu%C3%ADa%20practica%20para%20simulacros%20(1).pdf?alt=media&token=87df8867-390b-4d39-850c-3461452bb398">
              <div class="icon">
                <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                  <path stroke="none" stroke-width="0" fill="#f5f5f5"
                    d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426">
                  </path>
                </svg>
                <i><img width="60" height="60" src="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR9714G1manual.png?alt=media&token=95ca2590-0ff0-4d82-95cd-b80665966ada"/></i>
              </div>
              <h4>Guía Práctica de Simulacros de Evacuación en Inmuebles</h4>
            </a>
          </div>
        </div>
        <div class="col-lg-3" data-aos-delay="200">
          <div style="border-radius: 3vh;" class="icon-box iconbox-teal">
            <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10877G1Instrucciones%20para%20llenar%20formulario%20(1).pdf?alt=media&token=a696b57b-e379-411e-a350-ff4081c8aa4f">
              <div class="icon">
                <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                  <path stroke="none" stroke-width="0" fill="#f5f5f5"
                    d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426">
                  </path>
                </svg>
                <i><img width="60" height="60" src="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR9714G1instrucciones.png?alt=media&token=079f0db0-aabe-48e1-b683-f2968e8793f3" /></i>
              </div>
              <h4>Instrucciones para llenar formulario simulacro de Gabinete</h4>
            </a>
          </div>
        </div>
        <div class="col-lg-3" data-aos-delay="100">
          <div style="border-radius: 3vh;" class="icon-box iconbox-blue">
            <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10877G1Formulario_Simulacro%20de%20Gabinete%20(1).pdf?alt=media&token=00ad8f33-5414-457e-8bc7-08e13cbf4006">
              <div class="icon">
                <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                  <path stroke="none" stroke-width="0" fill="#f5f5f5"
                    d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174">
                  </path>
                </svg>
                <i><img width="60" height="60" src="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR9714G1registro-en-linea.png?alt=media&token=15add0f3-1930-4887-8ddd-f37fadb5bec9" /></i>
              </div>
              <h4>Formulario Simulacro de Gabinete</h4>
            </a>
          </div>
        </div>
        <div class="col-lg-3" data-aos-delay="300">
          <div style="border-radius: 3vh;" class="icon-box iconbox-pink">
            <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR10877G1C%C3%A9dula%20para%20Evaluaci%C3%B3n%20de%20Simulacros%20(1).pdf?alt=media&token=25e92658-1de2-41d8-9b2e-2209c7343cf5">
              <div class="icon">
                <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                  <path stroke="none" stroke-width="0" fill="#f5f5f5"
                    d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781">
                  </path>
                </svg>
                <i><img width="60" height="60" src="https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Transparencia%2FIPR9714G1documentos-oficiales.png?alt=media&token=30655384-e272-45eb-8810-a2df42d60167" /></i>
              </div>
              <h4>Cédula para la Evaluación de Simulacros</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section><!-- End Services Section -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
