import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Fraccion1Service } from 'src/services/Articulo8/Fraccion1.service';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-ver-noticia',
  templateUrl: './ver-noticia.component.html',
  styleUrls: ['./ver-noticia.component.scss']
})
export class VerNoticiaComponent {
  idNoticia: any;
  boton: any;
  noticias: any;
  noticia: any;
  id: any
  estatus: any
  constructor(private activerouter: ActivatedRoute, private router: Router, private api: Fraccion1Service, private general: GeneralService, private alerts: SweetAlertService) { }

  ngOnInit(): void {

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

    this.idNoticia = this.activerouter.snapshot.paramMap.get('id');
    console.log(this.idNoticia);

    this.general.verNoticias().subscribe(res => {
      this.noticias = res.body;
      console.log(this.noticias);

    })

    this.api.obtenerNoticiaPC(this.idNoticia).subscribe(res => {
      this.noticia = res.body;
      console.log(this.noticia);

    })

  }

  subirNoticia() {
    localStorage.setItem('idNoticia', this.idNoticia);
    this.router.navigate(['admin/subir-noticia']);
  }

  eliminar() {
    this.id = this.activerouter.snapshot.paramMap.get('id');
    this.general.eliminarNoticiaPC(this.id).subscribe((res: any) => {
      this.estatus = res.body;


      this.alerts.alertaConfirmacion('Eliminar', '¿Desea eliminar esta noticia?').then((res: any) => {

        if (res.isConfirmed) {
          this.alerts.alertaRealizado().then((res: any) => {
            this.router.navigate(['admin/indice']);
          })

        }




      })


    })
  }
}
