<!DOCTYPE html>
<html lang="en" data-aos="fade-up">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Autlan - Gobierno</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>










  <section id="hero"
    style="height: 600px; margin-top: 50px; width: 80%; margin-left: 10%; margin-right: 10%; border-radius: 6vh;">


    <div id="heroCarousel" data-bs-interval="4000" class="carousel slide carousel-fade" data-bs-ride="carousel">

      <div class="carousel-inner" role="listbox">

        <!-- Slide 1 -->
        <div class="carousel-item " style="background-image: url({{this.bann[0].URL}}); ">
          <div class="carousel-container" *ngIf="boton === 1">
            <div class="text-center"><button style="border-radius: 1vh; width: 180px; " (click)="editar()">Editar
                Banner</button></div>
          </div>
        </div>

        <!-- Slide 2 -->
        <div class="carousel-item " style="background-image: url({{this.bann[2].URL}}); ">
          <div class="carousel-container" *ngIf="boton === 1">
            <div class="text-center"><button style="border-radius: 1vh; width: 180px; " (click)="editar()">Editar
                Banner</button></div>
          </div>
        </div>

        <!-- Slide 3 -->
        <div class="carousel-item active" style="background-image: url({{this.bann[1].URL}}); ">
          <div class="carousel-container" *ngIf="boton === 1">
            <div class="text-center"><button style="border-radius: 1vh; width: 180px;" (click)="editar()">Editar
                Banner</button></div>
          </div>
        </div>

      </div>
    </div>

  </section>








  <main id="main">

    <section id="testimonials" class="testimonials section-bg">

      <div style="margin-top: -2vh;" class="astrodivider" data-aos="fade-up">
        <div class="astrodividermask" data-aos="fade-up"></div><span
          style=" background-image: url(  https://i.imgur.com/UpwdnWO.png); justify-items: start;"></span>
      </div>

      <div class="container">

        <div class="row">
          <div class="section-title" data-aos="fade-up">
            <a style="font-size: 4vh;" target="_blank"> Sección <strong>CLIMATICA</strong></a>
            <h4>Protección Civil</h4>

            <img src="https://www.meteored.mx/wimages/foto9a33e620a5fa22dc27a251205fed4731.png"><br><br>
            <p>
              <a class="btn btn-primary" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button"
                aria-expanded="false" aria-controls="multiCollapseExample1">


               Ver mapa de México</a>
              <button class="btn btn-primary" type="button" data-bs-toggle="collapse"
                data-bs-target="#multiCollapseExample2" aria-expanded="false"
                aria-controls="multiCollapseExample2">Ver mapa de la región</button>

              <button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse"
                aria-expanded="false" aria-controls="multiCollapseExample1 multiCollapseExample2">Abrir mapas</button>
            </p>


            <div class="row">
              <div class="col">
                <div class="collapse multi-collapse" id="multiCollapseExample1" style="margin-top: 2vh;">
                  <div class="card card-body">
                    <form [formGroup]="mexico" (ngSubmit)="Mexico()" action="">
                      <label for=""
                        style="color: rgb(0, 0, 0);font-weight: bolder;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">{{this.clima[0].TEXTO}}</label><br>
                      <img class="imagen1 animate__pulse" [src]="this.clima[0].URL" style="border-radius: 2vh;"
                        height="100%" width="100%" alt="">
                      <input *ngIf="this.token==true" type="file" style="margin-top: 1%;"
                        (change)="changeFileMenu($event)"><br>


                      <button *ngIf="this.token==true" type="submit"
                        style="margin-top: 2vh;width: 25vh; border-radius: 1vh;"> Enviar cambio </button>

                    </form>
                  </div>
                </div>
              </div>


              <div class="col" style="margin-left: 2vh;">
                <div class="collapse multi-collapse" id="multiCollapseExample2" style="margin-top: 2vh;">
                  <div class="card card-body">

                    <form [formGroup]="regional" (ngSubmit)="Regional()" action="">
                      <label for=""
                        style="color: rgb(0, 0, 0);font-weight: bolder;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">{{this.clima[1].TEXTO}}</label><br>
                      <img class="imagen1 animate__pulse" [src]="this.clima[1].URL" style="border-radius: 2vh;"
                        height="100%" width="100%" alt="">
                      <input *ngIf="this.token==true" type="file" style="margin-top: 1%;"
                        (change)="changeFileMenu1($event)"><br>


                      <button *ngIf="this.token==true" type="submit"
                        style="margin-top: 2vh;width: 25vh; border-radius: 1vh;"> Enviar cambio </button>
                    </form>

                  </div>
                </div>
              </div>





            </div>


          </div>
        </div>




      </div>
    </section>




    <section id="team" class="team section-bg">


      <div style="margin-top: -2vh;" class="astrodivider" data-aos="fade-up">
        <div class="astrodividermask" data-aos="fade-up"></div><span
          style=" background-image: url(  https://i.imgur.com/UpwdnWO.png); justify-items: start;"></span>
      </div>



      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Últimas <strong>Noticias</strong></h2>
          <div *ngIf="boton === 1" style="margin-bottom: 15px;">
            <button style="border-radius: 1vh; width: 180px;" (click)="subirNoticia()">Agregar Noticia <i
                class="bi bi-cloud-arrow-up"></i></button>
          </div>
        </div>
        <div class="row" style="justify-content: center; text-align: center;">




          <div id="cuerpo" class="card mb-4" style="max-width: 540px; margin-left: 6vh;" *ngFor="let noticia of data"
            data-aos="fade-up"> <a href="admin/ver-noticia/{{noticia.ID}}">
              <div class="row g-0">
                <div class="col-md-4">
                  <img [src]="noticia.ENLACE" class="img-fluid rounded-start" alt="...">
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">Noticia Autlán</h5>
                    <a href="" style="font-size: 1.8vh; text-align: justify;">{{noticia.ENCABEZADO}}</a>
                    <p class="card-text"><small class="text-muted">{{noticia.FECHA}}</small></p>
                  </div>
                </div>
              </div>
            </a>
          </div>



        </div>
      </div>
    </section>


















  </main><!-- End #main -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>







</body>

</html>
