import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-serviciopcid',
  templateUrl: './serviciopcid.component.html',
  styleUrls: ['./serviciopcid.component.scss']
})
export class ServiciopcidComponent {



  boton: any;
  id: any;
  data: any;
  areas: any;
  area: any;
  arrayFotos:any
  subarea: any;
  areas1: any;
  menu: any;
  titulo: any;
  icono: any;
  archivos: string[] = [];
  subscribeTimer: any;
  animation = true;
  urlDocumento:any
  imagen:any
  imagen2:any
  imagen3:any
estatus:any
  nuevoPC = new FormGroup({
    EDAD: new FormControl('', Validators.required),
    SEXO: new FormControl('', Validators.required),
    FOLIO: new FormControl('', Validators.required),
    EDAD1: new FormControl('', Validators.required),
    SEXO1: new FormControl('', Validators.required),

    EDAD2: new FormControl('', Validators.required),
    SEXO2: new FormControl('', Validators.required),

    EDAD3: new FormControl('', Validators.required),
    SEXO3: new FormControl('', Validators.required),

    EDAD4: new FormControl('', Validators.required),
    SEXO4: new FormControl('', Validators.required),

    EDAD5: new FormControl('', Validators.required),
    SEXO5: new FormControl('', Validators.required),

    FECHA: new FormControl('', Validators.required),
    SERVICIO: new FormControl('', Validators.required),
    TIPO_SERVICIO: new FormControl('', Validators.required),
    VEHICULO: new FormControl('', Validators.required),
    VEHICULO1: new FormControl('', Validators.required),
    VEHICULO2: new FormControl('', Validators.required),
    VEHICULO3: new FormControl('', Validators.required),
    VEHICULO4: new FormControl('', Validators.required),
    BASE: new FormControl('', Validators.required),
    LUGAR: new FormControl('', Validators.required),
    ESTATUS: new FormControl('', Validators.required),
    REPORTANTE: new FormControl('', Validators.required),
    CABINERO: new FormControl('', Validators.required),
    HORA_RECEPCION: new FormControl('', Validators.required),
    HORA_SALIDA: new FormControl('', Validators.required),
    HORA_LLEGADA: new FormControl('', Validators.required),
    HORA_ARRIBO: new FormControl('', Validators.required),
    DOMICILIO: new FormControl('', Validators.required),
    COLONIA: new FormControl('', Validators.required),
    IMAGEN: new FormControl('', Validators.required),
    IMAGEN2: new FormControl('', Validators.required),
    IMAGEN3: new FormControl('', Validators.required),
    CONDUCTOR: new FormControl('', Validators.required),
    PERSONAL: new FormControl('', Validators.required),
    DESCRIPCION: new FormControl('', Validators.required),
    NUM_SERVICIO: new FormControl('', Validators.required),



  });
  subiendoDocumento: boolean;
  subiendoDocumento2: boolean;
  subiendoDocumento1: boolean;
edad1:any
edad2:any
edad3:any
edad4:any
edad5:any
edad:any
dato1:any
dato2:any
dato3:any
dato4:any
dato5:any
dato:any

dato1v:any
dato2v:any
dato3v:any
dato4v:any
dato5v:any


vehiculo1:any
vehiculo2:any
vehiculo3:any
vehiculo4:any
vehiculo5:any

  constructor(private api: GeneralService, private alerts: SweetAlertService,private act : ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.id = this.act.snapshot.paramMap.get('id');

    this.api.ListaPCID(this.id).subscribe(res => {
      this.data = res.body

      console.log(this.data);
    })

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }


  }



  progreso(){
    this.id = this.act.snapshot.paramMap.get('id');
    this.api.estatusServiciosPC(2,this.id).subscribe((res: any) => {
      this.estatus = res.body;

      this.alerts.realizado('Completado', 'Se ha cambiado el esatus con exito').then((res: any) => {
        this.router.navigate(['admin/proteccion-civil/servicios/gestion'])
      })
   })
  }

  terminada(){
    this.id = this.act.snapshot.paramMap.get('id');
    this.api.estatusServiciosPC(3,this.id).subscribe((res: any) => {
      this.estatus = res.body;


      this.alerts.realizado('Completado', 'Se ha cambiado el esatus con exito').then((res: any) => {
        this.router.navigate(['admin/proteccion-civil/servicios/gestion'])
      })

   })
  }

}
