import { Component } from '@angular/core';

@Component({
  selector: 'app-alameda',
  templateUrl: './alameda.component.html',
  styleUrls: ['./alameda.component.scss']
})
export class AlamedaComponent {

}
