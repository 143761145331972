<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body style="margin-top: 7vh;">
  <main id="main">
    <section></section>
    <section id="blog" class="blog">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-lg-8 entries">
            <article class="entry entry-single">
              <div class="entry-img">
                <img src="{{this.noticia[0].ENLACE}}" class="img-fluid" height="100%" width="100%">
              </div>
              <h2 class="entry-title">{{this.noticia[0].ENCABEZADO}}</h2>
              <div class="entry-meta">
                <ul>
                  <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <time
                      datetime="2020-01-01">{{this.noticia[0].FECHA}}</time> <img *ngIf="this.boton==1" (click)="eliminar()" height="30" width="30" src="https://img.icons8.com/glyph-neue/64/null/trash.png"/></li>
                </ul>
              </div>
              <div class="entry-content">
                <p style="text-align: justify;" [innerHTML]="this.noticia[0].CUERPO">
                </p>
              </div>
            </article>
          </div>
          <div class="col-lg-4">
            <div class="sidebar">
              <h3 class="sidebar-title">ÚLTIMAS NOTICIAS</h3>
              <div class="sidebar-item recent-posts">
                <div *ngFor="let noticia of noticias" style="margin-top: 3%;">
                  <div class="post-item clearfix">
                    <img src="{{noticia?.ENLACE}}" class="img-fluid" alt="">
                    <h4><a href="admin/ver-noticia/{{noticia?.ID}}">{{noticia?.ENCABEZADO}}</a></h4>
                    <time datetime="2020-01-01">{{noticia?.FECHA}}</time> <br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
