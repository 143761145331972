import { Component } from '@angular/core';

@Component({
  selector: 'app-turismo',
  templateUrl: './turismo.component.html',
  styleUrls: ['./turismo.component.scss']
})
export class TurismoComponent {

}
