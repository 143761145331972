<svg style="display:none;">
  <symbol id="one" viewBox="0 0 1440 320" preserveAspectRatio="none">
    <path fill="white" d="M0,96L1440,320L1440,320L0,320Z"></path>
  </symbol>
  <symbol id="two" viewBox="0 0 1440 320" preserveAspectRatio="none">
    <path fill="white"
      d="M0,32L48,37.3C96,43,192,53,288,90.7C384,128,480,192,576,197.3C672,203,768,149,864,138.7C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
    </path>
  </symbol>
  <symbol id="three" viewBox="0 0 1440 320" preserveAspectRatio="none">
    <path fill="white"
      d="M0,128L30,144C60,160,120,192,180,197.3C240,203,300,181,360,192C420,203,480,245,540,245.3C600,245,660,203,720,192C780,181,840,203,900,181.3C960,160,1020,96,1080,80C1140,64,1200,96,1260,122.7C1320,149,1380,171,1410,181.3L1440,192L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z">
    </path>
  </symbol>
  <symbol id="four" viewBox="0 0 1440 320" preserveAspectRatio="none">
    <path fill="white"
      d="M0,192L120,192C240,192,480,192,720,165.3C960,139,1200,85,1320,58.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z">
    </path>
  </symbol>
  <symbol id="five" viewBox="0 0 1440 320" preserveAspectRatio="none">
    <path fill="white"
      d="M0,32L120,69.3C240,107,480,181,720,192C960,203,1200,149,1320,122.7L1440,96L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z">
    </path>
  </symbol>
  <symbol id="six" viewBox="0 0 1440 320" preserveAspectRatio="none">
    <path fill="rgba(255, 255, 255, .8)"
      d="M0,32L120,64C240,96,480,160,720,160C960,160,1200,96,1320,64L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z">
    </path>
  </symbol>
</svg>



<section class="hero-section position-relative animate__animated animate__fadeInLeft" style="text-align: center;justify-content: center;">
  <img src="https://i.imgur.com/ytNMg5q.png" height="800" width="1800" style="border-radius: 2vh;">
  <div
    class="overlay position-absolute d-flex align-items-center justify-content-center font-weight-bold text-white h2 mb-0">
    <blockquote class="p-4 mb-0">
      <p>Conoce nuestra historia, </p>
      <footer style="margin-top: .5vh;" class="blockquote-footer text-white text-right">Conoce Autlán</footer>
      <button *ngIf="this.boton===1" (click)="editar()" style="width: 100%; border-radius: 1vh; border: solid; background-color: rgb(0, 0, 0); border-color: rgb(0, 0, 0);color: rgb(255, 255, 255);font-size: 2vh;">

Editar contenido
      </button>
    </blockquote>
  </div>

</section>

<div style="margin-top: -2vh;" class="astrodivider" data-aos="fade-up">
  <div class="astrodividermask" data-aos="fade-up"></div><span
    style=" background-image: url(https://i.imgur.com/WVvdtYK.png); justify-items: start;"></span>
</div>


<div class="animate__animated animate__jackInTheBox" style="text-align: center; justify-content: center;margin-top: -1.5vh;">

  <div class="row">
<div class="col-12">
 <strong  style="font-size: 4vh;font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif">Cronicas del Municipio</strong>
</div>
<div class="col-12">
   <button *ngIf="this.boton===1" (click)="subirCronica()" style="width: 20vh; border-radius: 1vh; border: solid; background-color: rgb(0, 0, 0); border-color: rgb(0, 0, 0);color: rgb(255, 255, 255);">
  Agregar Cronica
  </button>
</div>
  </div>



</div>


<section class="photos-section py-5 d-flex justify-content-center">
  <div class="container">
    <div class="row" >


      <div class="col-12 col-sm-6 col-xl-3 d-flex card-outer animate__pulse"data-aos="fade-up" *ngFor="let cronica of data">
        <a href="admin/cronica/{{cronica.ID}}" class="card-title">
          <div class="card ">
            <div class="position-relative">
              <img style="margin-top: 2vh; border-radius: 1vh;" height="250" width="100%" [src]="cronica.IMAGEN"
                class="card-img-top " alt="">
                <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                  <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
                </svg>
            </div>
            <div class="card-body">

              <strong>{{cronica.ENCABEZADO}}</strong>

            </div>
          </div>
        </a>
      </div>





<section class="cover-section position-relative">
  <br>
  <br>
  <div style="margin-top: -2vh;" class="astrodivider" data-aos="fade-up">
    <div class="astrodividermask" data-aos="fade-up"></div><span
      style=" background-image: url(https://i.imgur.com/WVvdtYK.png); justify-items: start;"></span>
  </div>





  <div class="cover" style="background-image: url(https://i.imgur.com/d1CZmcv.jpg); width: 100%;">
    <img width="1920" src="https://i.imgur.com/d1CZmcv.jpg" class="img-fluid invisible"
      alt="" />

      <div style="justify-content: center; text-align: center; width: 100%;" data-aos="fade-up"
      class="overlay position-absolute d-flex align-items-center justify-content-center font-weight-bold text-white h2 mb-0">
      <blockquote class="p-4 mb-6" style="justify-content: center; text-align: center; margin-top: -3vh;">
        <p class="animate__animated animate__fadeInLeft">Conoce el municipio: </p>
        <footer  style="margin-top: .5vh; width: 50%; margin-left: 25%;margin-right: 25%;" class=" animate__animated animate__fadeInLeft blockquote-footer text-white text-right;justify-content: center; text-align: center;">Autlán de Navarro es un municipio de la Región Costa Sur del estado de Jalisco, México.
           La cabecera municipal es Autlán de la Grana. Es un municipio líder en la región Costa Sur, ya que su cabecera municipal es un importante centro de intercambio comercial.</footer>

      </blockquote>
    </div>

  </div>

  <svg class="position-absolute w-100" >
    <use xlink:href="#six"></use>
  </svg>
</section>


<div class="row" style="margin-top: 3vh;">
  <div class="col-3"></div>

  <div class="col-6" style="justify-content: center;text-align: center;">
    <button *ngIf="this.boton===1" (click)="editarfrase()" style="width: 50%; border-radius: 1vh; border: solid; background-color: rgb(0, 0, 0); border-color: rgb(0, 0, 0);color: rgb(255, 255, 255); font-size: 2vh;">
      Editar contenido  </button>
  </div>

  <div class="col-3"></div>
</div>



