<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Blog Single</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li><a href="blog.html">Blog</a></li>
            <li>Blog Single</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog" style="margin-top: 2vh;">
      <div class="container" data-aos="fade-up" style="margin-top: 2vh;">

        <div class="row">

          <div class="col-lg-8 entries">

            <article class="entry entry-single">

              <div class="entry-img">
                <img src="/assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
              </div>

              <h2 style="text-align: center; justify-content: center;" class="entry-title">
                <a style="text-align: center;"> Plaza Cívica "Marcelino García Barragán"</a>
              </h2>

              <div class="entry-meta" style="text-align: center; justify-content: center;">
                <ul style="text-align: center; justify-content: center;">
                  <li class="d-flex align-items-center"><img height="20" width="20"
                      src="https://img.icons8.com/ios/50/null/physical-gallery.png" /> <a>Eventos Cívicos - Lugar al
                      Aire Libre</a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20"
                      src="https://img.icons8.com/ios/50/null/address--v1.png" /> <a><time
                        datetime="2020-01-01"></time></a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20"
                      src="https://img.icons8.com/wired/64/null/tags.png" /> <a>Gratuito</a></li>
                </ul>
              </div>

              <div class="entry-content">



                <div style="justify-content: center; text-align: center;">
                  <img src="https://i.imgur.com/YleNzLo.png" width="600" height="350" style="border-radius: 2vh;"
                    alt="">
                </div>
                <p style="text-align: justify; margin-top: 2vh;">
                  El 3 de septiembre de 1984 y dentro de la conmemoración del quinto aniversario
                  luctuoso del general Marcelino García Barragán, se inauguró la Plaza Cívica que lleva el nombre de
                  este militar jalisciense. La ceremonia, según don Ernesto Medina Lima, fue presidida por el gobernador
                  del Estado, Enrique Álvarez del Castillo.
                </p>

                <p style="text-align: justify;">
                  La Plaza Cívica fue construida con recursos del gobierno del Estado y del Ayuntamiento, durante la
                  administración 1983-85, presidida por Rafael Saray. Antiguamente, en el lugar que ocupa la plaza
                  existieron sembradíos, reemplazados después de la construcción del Centro Escolar Chapultepec por un
                  campo de tierra donde se jugaba futbol.
                </p>



                <!--
                <blockquote>
                  <p>
                    Et vero doloremque tempore voluptatem ratione vel aut. Deleniti sunt animi aut. Aut eos aliquam doloribus minus autem quos.
                  </p>
                </blockquote> -->

                <p style="text-align: justify;">
                  Esta plaza, la más grande de Autlán junto con la Carlos Santana, mide unos 60 metros de frente por 120
                  de fondo y tiene la característica de estar escasamente arbolada, comparándola con el resto de
                  espacios similares en el municipio. Cuenta con dos grandes ceibas a la entrada, los llamados árboles
                  de la Amistad y de la Historia y jardineras con
                  plantas bajas de ornato al frente de la plaza, donde se encuentra la estatua del general Barragán, y
                  en sus costados.
                </p>


                <p style="text-align: justify;">
                  Cuenta además con un par de monumentos de carácter cívico: la mencionada estatua de Marcelino García
                  Barragán, frente a la cual se encuentra la placa conmemorativa de la inauguración de la plaza, y un
                  conjunto escultórico que representa a los Niños Héroes, ubicado al fondo, frente a la fachada del
                  Centro Escolar. Este conjunto está montado en lo alto de una plataforma elevada que hace las veces de
                  templete para algunos eventos. En julio de 1997 se colocó un asta para izar la Bandera
                  monumental que se utiliza en las Fiestas Patrias.
                </p>


                <p style="text-align: justify;">
                  A principios del año 2014 se realizaron algunos trabajos de mantenimiento en la plaza y se agregaron
                  algunos elementos: Un asta mucho más alta que la primera, colocada en el centro de la plaza, un nuevo
                  monumento a los Niños Héroes, compuesto por un obelisco y un semicírculo de columnas, cada una con una
                  placa con el nombre de uno de los niños.
                </p>







              </div>

              <!-- <div class="entry-footer">
                <i class="bi bi-folder"></i>
                <ul class="cats">
                  <li><a href="#">Business</a></li>
                </ul>

                <i class="bi bi-tags"></i>
                <ul class="tags">
                  <li><a href="#">Creative</a></li>
                  <li><a href="#">Tips</a></li>
                  <li><a href="#">Marketing</a></li>
                </ul>
              </div> -->

            </article><!-- End blog entry -->


          </div><!-- End blog entries list -->

          <div class="col-lg-4">

            <div class="sidebar">




              <h3 class="sidebar-title">Lugares de Intéres</h3>
              <div class="sidebar-item recent-posts">
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/5TBbGk5.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Plaza-de-Toros">Plaza de Toros "Alberto Balderas"</a></h4>
                  <time datetime="2020-01-01">C. Mariano Bárcenas 72, Centro</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/2sVV4Hq.png" alt="">
                  <h4><a href="admin/lugares-de-interes/catedral">Catedral de la Santísima Trinidad</a></h4>
                  <time datetime="2020-01-01">Calle M. Hidalgo 74, Centro, 48900 Autlán de Navarro, Jal.
                  </time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/UpMJ6td.png" alt="">
                  <h4><a href="admin/lugares-de-interes/jardin">Jardin "Miguel Hidalgo"</a></h4>
                  <time datetime="2020-01-01"> Margarito González Rubio 9, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NBLE2Ji.png" alt="">
                  <h4><a href="admin/lugares-de-interes/museo">Museo y Centro Regional de las Artes </a></h4>
                  <time datetime="2020-01-01"> C. José María Morelos 47, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/HAVjRry.png" alt="">
                  <h4><a href="admin/lugares-de-interes/parroquia">Parroquia de El Divino Salvador "El Sagrario"</a>
                  </h4>
                  <time datetime="2020-01-01">Margarito González Rubio 10, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NbxePys.png" alt="">
                  <h4><a href="admin/lugares-de-interes/monumentos-ninos-heroes">Monumento a los Niños Heroes</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>



                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/DEKzly6.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Capilla">Cerro de la Capilla</a></h4>
                  <time datetime="2020-01-01">Noroeste de la ciudad de Autlán</time>
                </div>
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/Dxa9ct9.png" alt="">
                  <h4><a href="admin/lugares-de-interes/casa-de-la-familia">Casa de la familia Santana Barragán</a></h4>

<<<<<<< HEAD
                </div>

=======
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/ojgjmCF.jpg" alt="">
                  <h4><a href="admin/lugares-de-interes/alameda">Parque Paulino Navarro o Alameda</a></h4>
                  <time datetime="2020-01-01"> La Alameda, 48900 Autlán de Navarro, Jal.</time>
                </div>
>>>>>>> 3870e572650ebffd6744b26637459b35f47e5108
              </div><!-- End sidebar recent posts-->


            </div><!-- End sidebar -->

          </div><!-- End blog sidebar -->

        </div>

      </div>
    </section><!-- End Blog Single Section -->

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
