<p>solicitudes works!</p>
<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
  />
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>





<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" id="services" class="services section-bg">



    <div class="row" style="position: absolute;">

      <div class="col-3">

        <div class="card" style="width: 15rem;border-radius: 2vh;margin-top: 20vh;box-shadow:  0 5px 20px 2px rgba(0, 0, 0, 0.15);">

          <div class="card-body" >
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/tramite" *ngIf="this.comunicaciones==true">- Datos Generales  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/tramite" *ngIf="this.comunicaciones==false">- Datos Generales   <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>
      <br>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/organizacion" *ngIf="this.organizacion==true">- Organización  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/organizacion" *ngIf="this.organizacion==false">- Organización  <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>
            <br>

            <a class="card-text" href="/admin/proteccion-civil/pc/interno/calendario" *ngIf="this.calendario==true">- Calendario  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/calendario" *ngIf="this.calendario==false">- Calendario  <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>

            <br>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/directorio" *ngIf="this.directorio==true">- Directorio  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/directorio" *ngIf="this.directorio==false">- Directorio  <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>

            <br>
            <a class="card-text" >- Riesgos <img width="24" height="24" src="https://img.icons8.com/fluency/48/create-new.png" alt="create-new"/></a>

            <br>

            <a class="card-text" href="/admin/proteccion-civil/pc/interno/preventivo" *ngIf="this.preventivo==true">- Mantenimiento  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/preventivo" *ngIf="this.preventivo==false">- Mantenimiento  <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>
            <br>

            <a class="card-text" href="/admin/proteccion-civil/pc/interno/capacitacion" *ngIf="this.capacitacion==true">- Capacitación  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/capacitacion" *ngIf="this.capacitacion==false">- Capacitación  <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>

            <br>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/auxilio" *ngIf="this.auxilio==true">-  Auxilio  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/auxilio" *ngIf="this.auxilio==false">- Auxilio   <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>

            <br>

            <a class="card-text" href="/admin/proteccion-civil/pc/interno/riesgo" *ngIf="this.riesgo==true">-  Valoraciones  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/riesgo" *ngIf="this.riesgo==false">- Valoraciones  <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>
            <br>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/proposito" *ngIf="this.proposito==true">- Continuidad  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/proposito" *ngIf="this.proposito==false">- Continuidad <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>
            <br>

            <a class="card-text" href="/admin/proteccion-civil/pc/interno/recursos" *ngIf="this.recursosh==true">- Recursos Humanos  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/recursos" *ngIf="this.recursosh==false">- Recursos Humanos  <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>
            <br>

            <a class="card-text" href="/admin/proteccion-civil/pc/interno/comunicaciones" *ngIf="this.comunicaciones==true">- Comunicaciones  <img width="24" height="24" src="https://img.icons8.com/emoji/48/check-mark-button-emoji.png" alt="check-mark-button-emoji"/></a>
            <a class="card-text" href="/admin/proteccion-civil/pc/interno/comunicaciones" *ngIf="this.comunicaciones==false">- Comunicaciones   <img width="24" height="24" src="https://img.icons8.com/emoji/48/cross-mark-button-emoji.png" alt="cross-mark-button-emoji"/></a>



          </div>
        </div>



      </div>
      <div class="col-6"></div>
      <div class="col-3"></div>
      </div>



    <section>
      <div class="container animate__animated animate__fadeInDown" >



        <div class="row justify-content-center" style="border: solid; border-radius: 2vh; border-color: rgb(255, 255, 255); box-shadow: -5px 6px 20px 0px rgba(0, 0, 0, 0.753);">
          <div class="col-12 col-md-8 col-lg-8 col-xl-6">
            <form [formGroup]="nuevoPC" (ngSubmit)="postForm(this.nuevoPC.value)" action="">
            <div class="row">
              <div class="col text-center">
                <img width="80" height="80" src="https://img.icons8.com/officel/80/high-risk.png" alt="high-risk"/> <h1>Identificación de riesgos y su evaluación</h1>
                <p style="margin-top: 1vh;" class="text-h3">Adjunte los documentos para continuar su registro. </p>
              </div>
            </div>


            <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Fundamento,exposicion y analisis de cada uno de los seis agentes perturbadores,
               asi como los antecedentes históricos relacionados con el municipio y el nivel de intensidad de cada uno de ellos:</label>
            <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>


<br>

            <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Análisis de riesgos internos deberá contemplar todo el inmueble, dicho análisis documentando
              todos aquellos riesgos detectados durante la inspección fisica: </label>
            <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu1($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento1 && urlDocumento1 !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento1"></div>

<br>


            <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Medidas de prevención y mitigación para cada uno de los riesgos detectados:</label>
            <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu2($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento2 && urlDocumento2 !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento2"></div>


<br>

            <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">plano interno que especifique todos los recuersos ante una emergencia:</label>
            <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu3($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento3 && urlDocumento3 !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento3"></div>


<br>

            <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Análisis de riesgos internos externos. Examinar la edificación y documentar los
              riesgos que estén en un entorno de 100 metros a la redonda:</label>
            <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu4($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento4 && urlDocumento4 !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento4"></div>

<br>

            <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Plano externo que indique la ubicación del unmieble en relación a los riesgos
               externos que deberá ubicar un radio de 500 metros:</label>
            <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu5($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento5 && urlDocumento5 !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento5"></div>


<br>

            <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Plano externo que indique la ubicación del inmueble en relacion
               a los recirsos circundantes que deberáubicar un radio de 500 metros:</label>
            <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu6($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento6 && urlDocumento6 !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento6"></div>
<br>

            <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Carta de corresponsabilidad de quien elaboró la identificación y evaluación de reisgos, asi como el
              lugar especifíco donde llevó a cabo dicho trabajo:</label>
            <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu7($event)">

            <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
              *ngIf="!subiendoDocumento7 && urlDocumento7 !== '' "></i>

            <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento7"></div>


            <div class="row justify-content-start mt-4">
              <div class="col">

                <button type="submit"  class="btn  mt-4" style="width: 100%;margin-bottom: 5vh;background-color: hwb(359 2% 24%);color: rgb(255, 255, 255);">Siguiente</button>
              </div>
            </div>

          </form>
          </div>
        </div>
      </div>
    </section>



  </section><!-- End Services Section -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
