import { Component } from '@angular/core';

@Component({
  selector: 'app-servicios-pc',
  templateUrl: './servicios-pc.component.html',
  styleUrls: ['./servicios-pc.component.scss']
})
export class ServiciosPCComponent {

}
