import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RespuestaAPI } from "src/interface/api-responses.model";
import { environment } from 'src/environments/environment';
import { Observable, Subject } from "rxjs";


const API = "https://api.guarderia.saion.mx";
var token = localStorage.getItem('token');

if (token === null) {
  token = '';
}

const headers = new HttpHeaders({
  'Authorization': 'App' + token!
});
@Injectable({
  providedIn: 'root'
})

export class GeneralService{

  constructor(private http:HttpClient){}

  //---Observable para indicar URL de foto obtenida---
  urlFoto: any;
  private subject = new Subject<any>();
  url: string = API + "/";


  verBannerCronista(){
    const direccion = API + "/autlan/cronista/banner/lista";
    return this.http.get<RespuestaAPI>(direccion);
  }
  actualizarBannerCronista(form: any){
    const direccion = API + "/autlan/actualizar/cronista/index";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }

  eliminarCronica(id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/eliminar/cronista/${id}`);
  }
  eliminarDictamen(id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/eliminar/dictamen/pc/data/${id}`,{headers});
  }

  eliminarServicioPC(id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/eliminar/servicio/${id}`,{headers});
  }

  nuevaCronica(form: any) {
    let direccion = this.url + "autlan/cronista/nuevo";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }
  listaCronista() {
    const direccion = API + "/autlan/cronista";
    return this.http.get<RespuestaAPI>(direccion);
  }

  listaUsers() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/usuarios`);
  }

  filtropc(razon: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/filtro/${razon}`);
  }

  filtroEmpresa(empresa: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/empresa/nombre/${empresa}`);
  }


  filtroConfe(razon: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/confe/${razon}`);
  }

  listaPC() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/pc`);
  }
  nuevoDoc(form: any) {
    let direccion = this.url + "autlan/nuevo/doc";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }
  eliminarNoticia(id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/eliminar/${id}`);
  }

  eliminarNoticiaPC(id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/eliminar/pc/noticia/${id}`);
  }
  solicitudesgeneral(id:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/general`);
  }

  listaSolicitudes() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/solicitudes`);
  }
  listaSolicitudes2() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/solicitudes/progreso`);
  }
  listaSolicitudes3() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/solicitudes/terminada`);
  }
  estatusSolicitud(estatus: any, id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/estado/${estatus}/${id}`);
  }
  estatusServiciosPC(estatus: any, id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/actualizar/servicios/${estatus}/${id}`);
  }

  estatusDictamen(estatus: any, id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/estado/dictamen/${estatus}/${id}`);
  }

  solicitudesID(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/solicitudes/${id}`);
  }
  registroPC(form: any) {
    let direccion = this.url + "autlan/civil/nuevo";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }
  registroServicioPC(form: any) {
    let direccion = this.url + "autlan/servicios/nuevo/pc";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }
  registroHospitalario(form: any) {
    let direccion = this.url + "autlan/servicio/hospital/nuevo/dato";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }
  registroHospitalario2(form: any) {
    let direccion = this.url + "autlan/hospitalario/nuevo/segunda";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  listaServiciosPC() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/servicios/pc`);
  }
  listaHospitalario() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/hospitalario/data`);
  }
  listaHospitalario2() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/hospitalario/secundaria`);
  }

  registroConfe(form: any) {
    let direccion = this.url + "autlan/confe/nuevo";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  fichapago(form: any) {
    let direccion = this.url + "autlan/ficha/pago/nuevo";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  PCDictamen(form: any) {
    let direccion = this.url + "autlan/dictamen/registro";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  PIRegistro(form: any) {
    let direccion = this.url + "autlan/programa/interno/registro";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }


  PIOrganizacion(form: any) {
    let direccion = this.url + "autlan/programa/interno/organizacion";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }


  PICalendario(form: any) {
    let direccion = this.url + "autlan/programa/interno/calendario";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  PIDirectorio(form: any) {
    let direccion = this.url + "autlan/programa/interno/directorio";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }


  PIRiesgos(form: any) {
    let direccion = this.url + "autlan/programa/interno/riesgos";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  PISenalizacion(form: any) {
    let direccion = this.url + "autlan/programa/interno/senalizacion";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  PICapacitacion(form: any) {
    let direccion = this.url + "autlan/programa/interno/capacitacion";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }
  PIAuxilio(form: any) {
    let direccion = this.url + "autlan/programa/interno/auxilio";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  PIRiesgo(form: any) {
    let direccion = this.url + "autlan/programa/interno/autoproteccion";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }
  PIProposito(form: any) {
    let direccion = this.url + "autlan/programa/interno/proposito";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }
  PIRecursos(form: any) {
    let direccion = this.url + "autlan/programa/interno/requerimentos";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  PIComunicaciones(form: any) {
    let direccion = this.url + "autlan/programa/interno/comunicaciones";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  PIListaAuxilio(form: any) {
    let direccion = this.url + "autlan/programa/interno/comunicaciones";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }


  listaPcAuxilio() {
    return this.http.get<RespuestaAPI>(API + `/autlan/lista/pi/auxilio`);
  }
  listaDatosG() {
    return this.http.get<RespuestaAPI>(API + `/autlan/lista/pi/datos/generales`);
  }

  listaDatosGId(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/id/datos/general/${id}`);
  }




  buscarAuxilio(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/auxilio/${columna}/${valor}`);
  }
  buscarDatos(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/datos/${columna}/${valor}`);
  }

  buscarDatosOrganizacion(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/organizacion/${columna}/${valor}`);
  }


  buscarDatosCalendario(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/calendario/${columna}/${valor}`);
  }



  buscarDatosDirectorios(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/directorios/${columna}/${valor}`);
  }


  buscarDatosRiesgos(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/riesgos/${columna}/${valor}`);
  }

  buscarDatosSenalizacion(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/senalizacion/${columna}/${valor}`);
  }

  buscarDatosCapacitacion(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/capacitacion/${columna}/${valor}`);
  }

  buscarDatosAutoproteccion(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/autoproteccion/${columna}/${valor}`);
  }


  buscarProposito(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/proposito/${columna}/${valor}`);
  }

  buscarRecursos(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/recursos/${columna}/${valor}`);
  }

  buscarComunicaciones(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/buscar/comunicaciones/${columna}/${valor}`);
  }

estatusOrga(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/organizacion/${estatus}/${id}`);
  }
  estatusautoprpteccion(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/autoprpteccion/${estatus}/${id}`);
  }
  estatusrecursos(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/recursos/${estatus}/${id}`);
  }
  estatusauxilio(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/auxilio/${estatus}/${id}`);
  }
  estatuscalendario(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/calendario/${estatus}/${id}`);
  }
  estatuscomunicaciones(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/comunicaciones/${estatus}/${id}`);
  }
  estatusdirectorios(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/directorios/${estatus}/${id}`);
  }
  estatuspropo(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/propositos/${estatus}/${id}`);
  }
  estatusriesgos(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/riesgos/${estatus}/${id}`);
  }

  estatusSena(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/senalizacion/${estatus}/${id}`);
  }
  estatuscapactacion(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/capactacion/${estatus}/${id}`);
  }
  estatusaux(id: any,estatus:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/estado/auxilio/${estatus}/${id}`);
  }

  PcDatosGeneral() {
    return this.http.get<RespuestaAPI>(API + `/autlan/lista/pi/datos/generales`);
  }

  buscarDictamenPC(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan//buscar/dictamen/${columna}/${valor}`);
  }
  listaPcDictamen() {
    return this.http.get<RespuestaAPI>(API + `/autlan/lista/pc/dictamen`);
  }

  listaPcDictamenCostos() {
    return this.http.get<RespuestaAPI>(API + `/autlan/lista/dictamen/costo/datos`);
  }

  DictamenCostoID(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan//dictamen/costo/id/${id}`);
  }

  DictamenID(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/dictamen/dato/id/${id}`);
  }

  giroID(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/dictamen/giro/${id}`);
  }

HospitalarioID(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/hospitalario/pc/${id}`);
  }

  HospitalarioID2(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/hospitalario/segunda/parte/${id}`);
  }

  editarDictamen(form:any){
    let direccion = this.url + "autlan/actualizar/dictamen/pc"
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
}













  solicitudes(form: any) {
    let direccion = this.url + "autlan/solicitudes";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  nuevoeEnlace(form: any) {
    let direccion = this.url + "autlan/nuevo/enlace";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  verEnlaces() {
    return this.http.get<RespuestaAPI>(API +`/autlan/enlaces`);
  }
  verEnlacesPC() {
    return this.http.get<RespuestaAPI>(API +`/autlan/enlaces/pc`);
  }
  buscar(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/consulta/${columna}/${valor}`);
  }


  buscarServiciosPC(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/servicios/${columna}/${valor}`);
  }


  programaBrigadista(programa: any, id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/brigadistas/programa/${programa}/${id}`);
  }
  inscripcionBrigadista(inscripcion: any, id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/brigadistas/inscripcion/${inscripcion}/${id}`);
  }
 descripcionrigadista(descripcion: any, id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/brigadistas/descripcion/${descripcion}/${id}`);
  }


  obtenerBrigadistas() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/pc/brigadistas`);
  }
  obtenerEscuelas() {
    return this.http.get<RespuestaAPI>(API +`/autlan/lista/pc/escuelas`);
  }


  editarBrigadista(form:any){
    let direccion = this.url + "autlan/actualizar/brigadistas"
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
}


editarEscuela(form:any){
  let direccion = this.url + "autlan/actualizar/escuela"
  return this.http.put<RespuestaAPI>(direccion, form,{headers});
}

editarClima(form:any){
  let direccion = this.url + "autlan/actualizar/clima/seccion"
  return this.http.put<RespuestaAPI>(direccion, form,{headers});
}



  buscarConfe(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/consulta/confe/${columna}/${valor}`);
  }
  buscarPC(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/pc/${columna}/${valor}`);
  }

  editarDoc(form:any){
    let direccion = this.url + "autlan/actualizar"
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
}

  obtener(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/${id}`);
  }
  obtenerA8(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/${id}/${inciso}`);
  }

  obtenerA152018(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2018/${id}`);
  }

  obtenerA152019(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2019/${id}`);
  }

  obtenerA152020(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2020/${id}`);
  }

  obtenerA152021(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2021/${id}`);
  }

  obtenerA152022(id: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A15/2022/${id}`);
  }


  obtenerA82018(id: any, inciso: any, ano:any, carpeta:any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A8/${id}/${inciso}/${ano}/${carpeta}`);
  }

  obtenerA82019(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A8/2019/${id}/${inciso}`);
  }

  obtenerA82020(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A8/2020/${id}/${inciso}`);
  }

  obtenerA82021(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A8/2021/${id}/${inciso}`);
  }

  obtenerA82022(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A8/2022/${id}/${inciso}`);
  }
  obtenerSUBSIDIO(id: any, inciso: any) {
    return this.http.get<RespuestaAPI>(API +`/autlan/docs/A8/${id}/${inciso}`);
  }
  obtenerIdentificadorDocumentos(idEmpresa: any){
    let direccion = API + `/preregistro/obtenerIdentificadorDocumentos/${idEmpresa}`
    return this.http.get<RespuestaAPI>(direccion);
  }
  obtenerIdentificadorDocumentosAutlan(idEmpresa: any){
    let direccion = API + `/preregistro/obtenerIdentificadorDocumentos/${idEmpresa}`
    return this.http.get<RespuestaAPI>(direccion);
  }
  eliminarEnlace(form: any){
    const direccion = API + "/autlan/actualizar";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }

  limpiarEnlaces(){
    const direccion = API + "/autlan/eliminar";
    return this.http.get<RespuestaAPI>(direccion);
  }

  actualizarNoticia(form: any){
    const direccion = API + "/autlan/noticia/actualizar";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }

  actualizarGobierno(form: any){
    const direccion = API + "/autlan/actualizar/gobierno";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }
  verGobierno(){
    const direccion = API + "/autlan/gobierno";
    return this.http.get<RespuestaAPI>(direccion);
  }

  nuevaNoticia(form: any) {
    let direccion = this.url + "autlan/noticia/nueva";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }

  nuevaNoticiaPC(form: any) {
    let direccion = this.url + "autlan/noticias/nuevo/pc";
    return this.http.post<RespuestaAPI>(direccion, form,{headers});
  }
  verNoticias(){
    const direccion = API + "/autlan/noticias/lista/pc";
    return this.http.get<RespuestaAPI>(direccion);
  }


  hospitalarioID(){
    const direccion = API + "/autlan/lista/id/hospitalario";
    return this.http.get<RespuestaAPI>(direccion);
  }




  verClima(){
    const direccion = API + "/autlan/lista/seccion/clima";
    return this.http.get<RespuestaAPI>(direccion);
  }

  listadoConfe(){
    const direccion = API + "/autlan/lista/confe";
    return this.http.get<RespuestaAPI>(direccion);
  }
  verPersonal(){
    const direccion = API + "/autlan/personal";
    return this.http.get<RespuestaAPI>(direccion);
  }
    verPersonal2(){
    const direccion = API + "/autlan/personal/directores";
    return this.http.get<RespuestaAPI>(direccion);
  }
  verPersonal1(){
    const direccion = API + "/autlan/personal/agentes";
    return this.http.get<RespuestaAPI>(direccion);
  }
  verBanner(){
    const direccion = API + "/autlan/banner/lista/completa";
    return this.http.get<RespuestaAPI>(direccion);
  }
  actualizarBanner(form: any){
    const direccion = API + "/autlan/banner/actualizar";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }

  actualizarPCBanner(form: any){
    const direccion = API + "/autlan/actualizar/pc/banner";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }
  actualizarAreas(form:any){
    const direccion = API + "/autlan/actualizar/tramites";
    return this.http.put<RespuestaAPI>(direccion, form,{headers});
  }

  verAreas(){
    const direccion = API + "/autlan/tramites";
    return this.http.get<RespuestaAPI>(direccion);
  }

  verAreas1(area:any, subarea:any){
    let direccion = API + `/autlan/datos/tramites/${area}/${subarea}`;
    return this.http.get<RespuestaAPI>(direccion);
  }

  ListaPCID(id: any) {
    return this.http.get<RespuestaAPI>(API + `/autlan/servicios/dato/id/${id}`);
  }

}
