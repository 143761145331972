import { Component } from '@angular/core';

@Component({
  selector: 'app-hospitalario-id-part2',
  templateUrl: './hospitalario-id-part2.component.html',
  styleUrls: ['./hospitalario-id-part2.component.scss']
})
export class HospitalarioIdPart2Component {

}
