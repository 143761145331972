<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>



<section style="background-color: rgb(255, 255, 255);margin-top: 15vh;" id="services" class="services section-bg">

  <div class="container">
    <form [formGroup]="nuevoPC2" >
      <div class="row">




        <h4 style="border: solid; border-color: black;margin-top: 2vh;">VI DATOS EN CASO DE TRAUMA Y AGENTE CAUSAL </h4>

        <div style="margin-top: 2.5VH;" class="col-6">

          <input class="form-control" placeholder="" formControlName="LUGAR_VI" readonly>

        </div>



        <div style="margin-top: 2.5VH;" class="col-6">

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="CAIDA">
            <label class="form-check-label" for="inlineCheckbox1">Caida</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="GOLPE">
            <label class="form-check-label" for="inlineCheckbox2">Golpe</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="VOLCADURA">
            <label class="form-check-label" for="inlineCheckbox1">Volcadura</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="ARMA_BLANCA">
            <label class="form-check-label" for="inlineCheckbox2">Arma Blanca</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="COLICION">
            <label class="form-check-label" for="inlineCheckbox1">Colicion Vehicular</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="ATROPELLAMIENTO">
            <label class="form-check-label" for="inlineCheckbox2">Atropellamiento</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="ANIMAL">
            <label class="form-check-label" for="inlineCheckbox1">Animal</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="PUNZANTE">
            <label class="form-check-label" for="inlineCheckbox2">Objeto Punzante</label>
          </div>


          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="HUMANO">
            <label class="form-check-label" for="inlineCheckbox1">Ser Humano</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="FUEGO">
            <label class="form-check-label" for="inlineCheckbox2">Fuego</label>
          </div>



          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="EXPLOSION">
            <label class="form-check-label" for="inlineCheckbox1">Explosión</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="TOXICA">
            <label class="form-check-label" for="inlineCheckbox2">Sustancia tóxica</label>
          </div>



          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="ELECTRICIDAD">
            <label class="form-check-label" for="inlineCheckbox1">Electricidad</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="PROYECTIL">
            <label class="form-check-label" for="inlineCheckbox2">Proyectil de arma de fuego</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="MAQUINARIA">
            <label class="form-check-label" for="inlineCheckbox1">Herramienta/maquinaria</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="LIQUIDOS">
            <label class="form-check-label" for="inlineCheckbox2">Liquidos Calientes</label>
          </div>


        </div>







      </div>





      <div class="row">
        <div class="col-4"></div>
        <div class="col-4">

          <div style="margin-top: 2.5VH;">
            <label for="">Exploración Fisica:</label>
            <input class="form-control" placeholder="" formControlName="EXPLORACION" readonly>


          </div>


        </div>
        <div class="col-4"></div>
      </div>

      <div class="row">
        <div class="col-4"></div>
        <div class="col-4">

          <div style="margin-top: 2.5VH;">

            <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
              Zona de lesión:
            </label>
            <input class="form-control" placeholder="" formControlName="LESION" readonly>


          </div>


        </div>
        <div class="col-4"></div>
      </div>



      <div class="row">

        <div class="col-2"></div>


        <div style="margin-top: 2.5VH;" class="col-8">
          <h4 style="border: solid; border-color: black;margin-top: 2vh;">VII ATENCION GINECO-OBSTETRICA</h4>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" readonly
              formControlName="GESTA">
            <label class="form-check-label" for="inlineCheckbox1">Gesta</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" readonly
              formControlName="CESAREAS">
            <label class="form-check-label" for="inlineCheckbox2">Cesareas</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="PARTOS">
            <label class="form-check-label" for="inlineCheckbox1">Partos</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="ABORTOS">
            <label class="form-check-label" for="inlineCheckbox2">Abortos</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="FECHA_PARTO">
            <label class="form-check-label" for="inlineCheckbox1">Fecha probable de Parto</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="SEMANAS">
            <label class="form-check-label" for="inlineCheckbox2">Semanas</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="MEMBRANA_ROTA">
            <label class="form-check-label" for="inlineCheckbox1">Membrana Rota</label>
          </div>



          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="INTEGRA">
            <label class="form-check-label" for="inlineCheckbox1">Membrana Integra</label>
          </div>
          <br>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"
              formControlName="SANGRADO">
            <label class="form-check-label" for="inlineCheckbox2">Sangrado</label>
          </div>

          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
              formControlName="CONTRACCIONES">
            <label class="form-check-label" for="inlineCheckbox1">Contracciones</label>
          </div>


          <div class="row">

            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                Frecuencia en Minutos:
              </label>
              <input class="form-control" placeholder="" formControlName="FRECUENCIA">
            </div>

            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                Duración en Minutos:
              </label>
              <input class="form-control" placeholder="" formControlName="DURACION">
            </div>

            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                Hora de nacimiento del Producto:
              </label>
              <input class="form-control" placeholder="" formControlName="HORA_NACIMIENTO">
            </div>
          </div>


          <div class="row">

            <div class="form-check form-check-inline" style="margin-top: 2vh;">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                formControlName="PRODUCTO"readonly>
              <label class="form-check-label" for="inlineCheckbox1">Producto Vivo</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                formControlName="RCP_VII"readonly>
              <label class="form-check-label" for="inlineCheckbox1">RCP</label>
            </div>


            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"
                formControlName="PLACENTA_EXPULSADA"readonly>
              <label class="form-check-label" for="inlineCheckbox1">Placenta Expulsada</label>
            </div>


            <br>

            <div class="col-4" style="margin-top: 2vh;">
              <label class="form-check-label" for="flexCheckChecked" style="margin-left: 1vh; font-weight: bold;">
                Sexo:
              </label>
              <input class="form-control" placeholder="" formControlName="SEXO"readonly>
            </div>

            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2vh;">
                Lugar:
              </label>
              <input class="form-control" placeholder="" formControlName="LUGAR_VII"readonly>
            </div>
            <div class="col-4">



              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                A.P.G.A.R:
              </label>
              <input class="form-control" placeholder="" formControlName="A_P_G_A_R"readonly>


            </div>

          </div>

          <div class="row">
            <div class="col-4">


              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2vh;">
                Silverman:
              </label>
              <input class="form-control" placeholder="" formControlName="SILVERMAN"readonly>

            </div>


            <div class="col-4">
              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Oxigenoterapia:
              </label>
              <input class="form-control" placeholder="" formControlName="OXIGENOTERAPIA"readonly>

            </div>

            <div class="col-12">
              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                OTROS:
              </label>
              <input class="form-control" placeholder="" formControlName="OTROS_VII"readonly>

            </div>




          </div>



          <div class="row">
            <h4 style="border: solid; border-color: black;margin-top: 2vh;">VIII DATOS EN CASO DE COLISION VEHICULAR Y DEPENDENCIAS DE APOYO
            </h4>
            <div class="col-12">


              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"readonly
                  formControlName="DSP">
                <label class="form-check-label" for="inlineCheckbox1">D.S.P</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"readonly
                  formControlName="CRUZ_ROJA">
                <label class="form-check-label" for="inlineCheckbox1">CRUZ ROJA</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"readonly
                  formControlName="PF">
                <label class="form-check-label" for="inlineCheckbox1">P.F</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"readonly
                  formControlName="SEC_MOVILIDAD">
                <label class="form-check-label" for="inlineCheckbox1">SEC MOVILIDAD</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"readonly
                  formControlName="PGR">
                <label class="form-check-label" for="inlineCheckbox1">P.G.R</label>
              </div>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"readonly
                  formControlName="MP">
                <label class="form-check-label" for="inlineCheckbox1">M.P.</label>
              </div>
              <br>
              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Otros:
              </label>
              <input class="form-control" placeholder="" formControlName="OTRO_VIII"readonly>



            </div>



          </div>




          <div class="row">
            <div class="col-12">

              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Unidades:
              </label>
              <input class="form-control" placeholder="" formControlName="UNIDADES1" readonly>

              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Mando y Personal a cargo:
              </label>
              <input class="form-control" placeholder="" formControlName="PERSONAL" readonly>


              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Unidades:
              </label>
              <input class="form-control" placeholder="" formControlName="UNIDADES2"readonly>

              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Mando y Personal a cargo:
              </label>
              <input class="form-control" placeholder="" formControlName="PERSONAL2"readonly>


              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Unidades:
              </label>
              <input class="form-control" placeholder="" formControlName="UNIDADES3"readonly>


              <label class="form-check-label" for="flexCheckChecked"
                style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                Mando y Personal a cargo:
              </label>
              <input class="form-control" placeholder="" formControlName="PERSONAL3"readonly>



            </div>











          </div>


          <div class="row">

            <div class="col-12">
              <label for="">Vehículos Involucrados </label>

              <div class="row">


                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Tipo:
                  </label>
                  <input class="form-control" placeholder="" formControlName="TIPO" readonly>
                  <input class="form-control" placeholder="" formControlName="TIPO1"readonly>
                  <input class="form-control" placeholder="" formControlName="TIPO2"readonly>


                </div>
                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Color:
                  </label>
                  <input class="form-control" placeholder="" formControlName="COLOR1"readonly>
                  <input class="form-control" placeholder="" formControlName="COLOR2"readonly>
                  <input class="form-control" placeholder="" formControlName="COLOR3"readonly>

                </div>



                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Marca:
                  </label>
                  <input class="form-control" placeholder="" formControlName="MARCA1"readonly>
                  <input class="form-control" placeholder="" formControlName="MARCA2"readonly>
                  <input class="form-control" placeholder="" formControlName="MARCA3"readonly>
                </div>
                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Placas:
                  </label>
                  <input class="form-control" placeholder="" formControlName="PLACAS1"readonly>
                  <input class="form-control" placeholder="" formControlName="PLACAS2"readonly>
                  <input class="form-control" placeholder="" formControlName="PLACAS3"readonly>
                </div>
                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked" style=" font-weight: bold;margin-top: 2VH;">
                    Lesionados:
                  </label>
                  <input class="form-control" placeholder="" formControlName="LESIONADOS1"readonly>
                  <input class="form-control" placeholder="" formControlName="LESIONADOS2"readonly>
                  <input class="form-control" placeholder="" formControlName="LESIONADOS3"readonly>
                </div>

                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Agente del ministerio público:
                  </label>
                  <input class="form-control" placeholder="" formControlName="AGENTE"readonly>

                </div>


                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Firma:
                  </label>
                  <input class="form-control" placeholder="" formControlName="TIPO3"readonly>

                </div>


                <div class="col-4">

                  <label class="form-check-label" for="flexCheckChecked"
                    style="margin-left: 1vh; font-weight: bold;margin-top: 2VH;">
                    Ced. Profesional:
                  </label>
                  <input class="form-control" placeholder="" formControlName="CED_PROFESIONAL"readonly>

                </div>

              </div>






            </div>
          </div>





        </div>
      </div>

      <div style="justify-content: center; text-align: center;">
        <button type="button"
          style="background-color: rgb(210, 21, 21); border-color: rgb(210, 13, 13); color: rgb(255, 255, 255); width: 25vh;margin-top: 2vh;border-radius: .5vh;"   (click)="imprimir('reporte')">
          Imprimir </button>

      </div>






    </form>
  </div>





  <div id="reporte" hidden style="width: 100%;">
    <h3 style="text-align: center;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">DIRECCION DE LA COORDINACION MUNICIPAL DE PROTECCION CIVIL Y BOMBEROS AUTLAN</h3>
            <table class="table table-bordered" style="width: 100%;">
              <tbody class="thead-light">

                <tr>
                  <th colspan="3" style="text-align: center;">
                    VI DATOS EN CASO DE TRAUMA Y AGENTE CAUSAL
                  </th>
                </tr>
                <tr>
                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">Lugar donde ocurrió: {{this.lugar_VI}}<br>



                    Caida: {{this.caida}} <br>




                    Volcadura: {{this.volca}} <br>



                    Sustancia Tóxica: {{this.toxic}} <br>

                   Herramienta/maquinaria: {{this.maqui}} <br>


                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">Golpe
                    {{this.golpe}}

                    <br>
                    Arma Blanca:
                    {{this.arma}}


                    <br>
                    Fuego:
                    {{this.fuego}}
                    <br>
                    Electricidad:
                    {{this.elec}}
                    <br>

                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">Colición Vehicular
                    {{this.colicion}}
                    <br>
                    Atropellamiento:
                    {{this.atro}}


                    <br>
                    Explosión:
                    {{this.explo}}
                    <br>
                    Liquidos Calientes:
                    {{this.liqui}}
                    <br>

                  </td>



<br>
                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">Animal: {{this.animal}} <br>


                   Objeto punzante:{{this.punza}}
                   <br>

                    Ser Humano: {{this.human}} <br>

                  Proyectil de arma de fuego: {{this.proyec}} <br>
                   <br>

                  </td>

                </tr>



                <tr>
                  <th colspan="3" style="text-align: center;">

                  </th>
                </tr>
                <tr>
                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;"><br>





                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">Exploración Fisica
                    {{this.explo}}

                    <br>
                   Zona de lesión:
                    {{this.lesion}}


                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">
                  </td>



                </tr>


                <tr>
                  <th colspan="3" style="text-align: center;">

VII ATENCION GINECO-OBSTETRICA
                  </th>
                </tr>
                <tr>
                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">



                    Gesta: {{this.gesta}} <br>




                    Cesareas: {{this.cesa}} <br>



                    Partos: {{this.partos}} <br>

                    Fecha probable de Parto: {{this.fecha_parto}} <br>


                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">Semanas
                    {{this.semanas}}

                    <br>
                    Membrana Rota:
                    {{this.menbra}}


                    <br>
                    Membrana Integra:
                    {{this.integra}}
                    <br>


                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">Colición Vehicular
                    {{this.colicion}}
                    <br>
                    Contracciones:
                    {{this.contra}}
<br>
                    Sangrado:
                    {{this.sangrado}}
                    <br>

                  </td>





                </tr>

                <tr>
                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">



                    Frecuencia en Minutos: {{this.frecu}} <br>




                    Duración en Minutos: {{this.dura}} <br>






                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">


                    A.P.G.A.R: {{this.apgar}} <br>

                    Silverman: {{this.silver}} <br>


                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">


                    Oxigenoterapia
                    {{this.oxige}}
<br>
OTROS:
                    {{this.o_VII}}
                    <br>

                  </td>





                </tr>




                <tr>
                  <th colspan="3" style="text-align: center;">
                    VIII DATOS EN CASO DE COLISION VEHICULAR Y DEPENDENCIAS DE APOYO
                  </th>
                </tr>
                <tr>
                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">



                    DSP: {{this.dsp}} <br>




                    Cruz roja: {{this.cruz}} <br>






                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">


                   PF: {{this.pf}} <br>

                    Sec Movilidad: {{this.sec}} <br>


                  </td>

                  <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">


                    PGR
                    {{this.pgr}}
<br>
MP:
                    {{this.mp}}
                    <br>

                  </td>





                </tr>






              </tbody>
            </table>

            <table>
<tbody>

  <tr>
    <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">



      Otros: {{this.o_viii}} <br>

      Unidades: {{this.uni1}} <br>

      Mando y Personal a Cargo: {{this.perso}} <br>

      Unidades: {{this.uni2}} <br>
      Mando y Personal a Cargo: {{this.perso2}} <br>

      Unidades: {{this.uni3}} <br>
      Mando y Personal a Cargo: {{this.perso3}} <br>





    </td>





  </tr>




</tbody>




            </table>



<table class="table table-bordered" style="width: 100%;">
<tbody class="thead-light">

  <tr>
    <th colspan="3" style="text-align: center;">
    Vehículos involucrados
    </th>
  </tr>


  <tr>
    <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">



      Tipo:<br> {{this.tipo}} <br> {{this.tipo1}} <br> {{this.tipo2}} <br>


      Placas:<br> {{this.placas1}} <br> {{this.placas2}} <br> {{this.placas3}}



      Ced. Profesional: <br> {{this.ced}} <br>




    </td>

    <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">


      Color: <br> {{this.color1}} <br> {{this.color2}} <br> {{this.color3}} <br>

      Lesionados: <br> {{this.lesio1}} <br> {{this.lesio2}} <br> {{this.lesio3}} <br>
      Firma: <br> {{this.tipo3}} <br>

    </td>

    <td style="font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">



      Marca: <br> {{this.marca1}} <br> {{this.marca2}} <br> {{this.marca3}} <br>
      Agente del Ministerio Público: <br> {{this.agent}} <br>
    </td>





  </tr>


</tbody>



</table>








          </div>








</section>
