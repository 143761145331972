<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Blog Single</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li><a href="blog.html">Blog</a></li>
            <li>Blog Single</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->



    <br>
    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog" style="margin-top: 2vh;">
      <div class="container" data-aos="fade-up" style="margin-top: 2vh;">

        <div class="row">

          <div class="col-lg-8 entries">

            <article class="entry entry-single">

              <div class="entry-img">
                <img src="/assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
              </div>

              <h2 style="text-align: center; justify-content: center;" class="entry-title">
                <a style="text-align: center;">Casa de la familia Santana Barragán</a>
              </h2>



              <div class="entry-content">



                         <div style="justify-content: center; text-align: center;" >
                <img src="https://i.imgur.com/Dxa9ct9.png" width="600" height="400" style="border-radius: 2vh;" alt="">
                 </div>
                <p style="text-align: justify; margin-top: 2vh;">
                  Esta fue una de las casas que habitó la familia Santana Barragán en la década de 1940. Aquí nació, el 20 de julio de 1947, Carlos Humberto, el cuarto hijo de don José Santana y doña Josefina Barragán, conocido en todo el mundo como Carlos Santana, una de las leyendas vivas del rock.
                  En su autobiografía, titulada El Tono Universal, Carlos Santana recuerda su infancia en Autlán, mencionando elementos como los sonidos callejeros, los olores de la comida, frutos como las pitayas o las jícamas… pero también trae a la memoria las historias que su padre, don José, les contaba a él y a sus hermanos en esta casa: “Era mejor que la televisión. Mi papá era un excelente narrador de cuentos, tenía una voz que disparaba nuestra imaginación y lograba que nos compenetráramos con la historia”. Esta pasión y talento para narrar una historia pudo haber sido, siempre según Carlos, una de sus motivaciones para dedicarse a la música, puesto que “los mejores músicos saben cómo contar una historia y se aseguran de que su música no sea solo un puñado de notas”.



                </p>

                <p style="text-align: justify;">
                  Don José Santana, el padre de Carlos, fue un violinista reconocido en toda la región. Tocó en mariachis y en orquestas de varios pueblos, incluyendo la Orquesta Sinfónica que se conformó en Autlán en 1942, dirigida por el profesor Jesús Guzmán Martínez.
                  En este barrio de La Sirena, Carlos Santana tuvo sus primeros contactos con la música, de la que siempre estuvo rodeado: según testimonios de quienes vivieron aquí en la década de 1950, en estas calles se escuchaba música constantemente a lo largo del día, procedente de los ensayos de don José Santana, del también violinista Ramón Corona y de otros compañeros músicos. Además, cerca de esta casa, hacia el oriente, estaba el domicilio de don Jaime Gómez Vázquez, director de la Banda Autlán. Ahí se desarrollaban los ensayos de la banda y, en ese mismo lugar, don Jaime impartió clases de solfeo a las que el niño Carlos asistió, según lo que nos ha contado el maestro Jaime Gabino Gómez.


                </p>



<!--
                <blockquote>
                  <p>
                    Et vero doloremque tempore voluptatem ratione vel aut. Deleniti sunt animi aut. Aut eos aliquam doloribus minus autem quos.
                  </p>
                </blockquote> -->

                <p style="text-align: justify;">
                  Carlos Santana vivió sus primeros ocho años en Autlán y aquí cursó los primeros grados de la educación primaria. Existe el registro del segundo año con la maestra Andrea Velázquez en el Centro Escolar Chapultepec, desde esa tierna edad, sus preferencias en materia escolar eran enfocadas al arte, según se menciona en el libro Ciudadanos distinguidos de Autlán.
                  En 1955, la familia de Carlos Santana se mudó de Autlán a Tijuana, donde Carlos empezó a tocar en clubes y bares de la Avenida Revolución, la zona turística de Tijuana, junto con la banda de Javier Bátiz, los TJ´s. En esta banda, Santana comenzó a mezclar ritmos afroantillanos con el blues y el rock, siendo en esta época cuando surgieron los elementos que caracterizaron el sonido y el estilo interpretativo de Santana.

                </p>

<br>

<p style="text-align: justify;">
  La familia Santana Barragán se volvió a mudar, esta vez de Tijuana a San Francisco, California, en 1960, aunque él se quedó un año más. En 1966 fundó la primera versión de la Santana Blues Band, que luego se abreviaría a Santana. Los miembros fundadores de la Santana Blues Band fueron Carlos Santana en la guitarra, Greg Rolie en la voz y los teclados, David Brown en el bajo, Bob Livingstone en la batería y el percusionista Marcus Malone.
  En esta época, el área de la bahía de San Francisco estaba en plena efervescencia. La ciudad, que siempre había sido un bastión liberal dentro de la conservadora sociedad estadounidense bullía con el activismo de los Black Panthers, el descubrimiento del LSD y las protestas contra la guerra de Vietnam. En el centro de la actividad musical estaba el empresario Bill Graham, que organizaba los conciertos en el auditorio Fillmore West. Durante una tarde de domingo de 1967, en pleno Verano del Amor, la Santana Blues Band tuvo su bautizo de fuego en un concierto de blues que incluía a la Paul Butterfield Blues Band y músicos entre los que había integrantes de Grateful Dead y Jefferson Airplane. La intensidad y originalidad de la banda quedarían registradas en el disco “Live at the Fillmore 1968”, disco que sería publicado casi 20 años después. Graham invitaba frecuentemente a la Santana Blues Band a tocar en el Fillmore West y, al involucrarse en la organización de festival de Woodstock, hizo todo lo posible por que la banda participara en él.

</p>

<br>

<p style="text-align: justify;">
  En este festival comenzaría el despegue de la banda Santana, que en los siguientes años realizaría giras por todo el mundo, compartiendo escenario con personajes como Bob Dylan, Buddy Guy, Eric Clapton y Stevie Ray Vaughan, entre otros. El grupo ha grabado discos que hoy son considerados clásicos de la música popular, como Abraxas o Santana III. En 1999 publicó Supernatural, un disco que contó con colaboraciones con artistas de otros géneros musicales y que le valió obtener 9 premios Grammy en la ceremonia número 42 de estos premios.
  Carlos Santana tiene una estrella en el Paseo de la Fama de Hollywood, pero también ha participado en actividades en beneficio de diversas causas, destacando su financiamiento para el Centro Comunitario y de Salud Tiopa Tlanextli, en Autlán.
  Otros sitios interesantes para conocer sobre Carlos Santana:
  Sala de Carlos Santana. Museo y Centro Regional de las Artes. Morelos 47. Centro.
  Plaza Carlos Santana. Ahí se encuentra la estatua de Carlos Santana y la rotonda de músicos distinguidos. Colonia Echeverría.
  Centro Comunitario y de Salud Tiopa Tlanextli. Rosario Castellanos 217, Col. Echeverría.
  Av. Carlos Santana. Ahí se encuentra la placa en forma de Guitarra que da nombre a la Avenida.
</p>


<br>

<p>
  Por Guillermo Tovar Vázquez (2023)

</p>

                <!-- <div style="justify-content: center; text-align: center;" >
                  <img src="https://i.imgur.com/2sVV4Hq.png" width="600" height="400" style="border-radius: 2vh;" alt="">
                   </div> -->


                <br>






              </div>

              <!-- <div class="entry-footer">
                <i class="bi bi-folder"></i>
                <ul class="cats">
                  <li><a href="#">Business</a></li>
                </ul>

                <i class="bi bi-tags"></i>
                <ul class="tags">
                  <li><a href="#">Creative</a></li>
                  <li><a href="#">Tips</a></li>
                  <li><a href="#">Marketing</a></li>
                </ul>
              </div> -->

            </article><!-- End blog entry -->


          </div><!-- End blog entries list -->

          <div class="col-lg-4">

            <div class="sidebar">




              <h3 class="sidebar-title">Lugares de Intéres</h3>
              <div class="sidebar-item recent-posts">
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/5TBbGk5.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Plaza-de-Toros">Plaza de Toros "Alberto Balderas"</a></h4>
                  <time datetime="2020-01-01">C. Mariano Bárcenas 72, Centro</time>
                </div>


                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/UpMJ6td.png" alt="">
                  <h4><a href="admin/lugares-de-interes/jardin">Jardin "Miguel Hidalgo"</a></h4>
                  <time datetime="2020-01-01"> Margarito González Rubio 9, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/5TBbGk5.png" alt="">
                  <h4><a href="admin/lugares-de-interes/museo">Museo y Centro Regional de las Artes </a></h4>
                  <time datetime="2020-01-01"> C. José María Morelos 47, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/HAVjRry.png" alt="">
                  <h4><a href="admin/lugares-de-interes/parroquia">Parroquia de El Divino Salvador "El Sagrario"</a></h4>
                  <time datetime="2020-01-01">Margarito González Rubio 10, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NbxePys.png" alt="">
                  <h4><a href="admin/lugares-de-interes/monumentos-ninos-heroes">Monumento a los Niños Heroes</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/OE2jTNN.png" alt="">
                  <h4><a href="admin/lugares-de-interes/plaza-civica">Plaza Civica</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/DEKzly6.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Capilla">Cerro de la Capilla</a></h4>
                  <time datetime="2020-01-01">Noroeste de la ciudad de Autlán</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/Dxa9ct9.png" alt="">
                  <h4><a href="admin/lugares-de-interes/casa-de-la-familia">Casa de la familia Santana Barragán</a></h4>

                </div>


              </div><!-- End sidebar recent posts-->


            </div><!-- End sidebar -->

          </div><!-- End blog sidebar -->

        </div>

      </div>
    </section><!-- End Blog Single Section -->

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
