import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

declare var svg:any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  data: any;
  bann: any;
  boton: any;
  clima:any;
  urlDocumento:any;
  urlDocumento1:any;
  urlDocumento2:any;
docs:any
token:any
subiendoDocumento:any
subiendoDocumento1:any
subiendoDocumento2:any

  mexico = new FormGroup({
    ID: new FormControl(''),
    URL: new FormControl('', Validators.required),
   });
   regional = new FormGroup({
    ID: new FormControl(''),
    URL: new FormControl('', Validators.required),

   });

   autlan = new FormGroup({
    ID: new FormControl(''),
    URL: new FormControl('', Validators.required),

   });





  constructor(private router: Router, private service: GeneralService,private storage: Storage) { }

  ngOnInit(): void {

    this.service.verClima().subscribe(res => {
      this.clima = res.body;
      console.log(this.clima);

    })



    if (localStorage.getItem('token')) {
      this.boton = 1;
      this.token=true
    }else{
      this.token=false
    }
    this.service.verNoticias().subscribe(res => {
      this.data = res.body;
      console.log(this.data);

    })
    this.service.verBanner().subscribe(res => {
      this.bann = res.body;
      console.log(this.bann);

    })
    svg();


  }


  Mexico() {
    console.log('entra');
this.mexico.value.ID='1'
console.log(this. mexico.value);

    this.service.editarClima(this.mexico.value).subscribe(res => {
      console.log(res.body);
      console.log('Editado');

      this.service.verClima().subscribe(res => {
        this.clima = res.body
        console.log(this.clima);
        this.subiendoDocumento=false
      })

    })

  }

  Regional() {
    console.log('entra');
this.regional.value.ID='2'
console.log(this. regional.value);

    this.service.editarClima(this.regional.value).subscribe(res => {
      console.log(res.body);
      console.log('Editado');

      this.service.verClima().subscribe(res => {
        this.clima = res.body
        console.log(this.clima);
        this.subiendoDocumento1=false
      })

    })

  }

  Autlan() {
    console.log('entra');
this.autlan.value.ID='3'
console.log(this.autlan.value);

    this.service.editarClima(this.autlan.value).subscribe(res => {
      console.log(res.body);
      console.log('Editado');

      this.service.verClima().subscribe(res => {
        this.clima = res.body
        console.log(this.clima);
this.subiendoDocumento2=false
      })

    })

  }



  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.service.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })



   async changeFileMenu(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento = false;
          console.log(file);

          this.mexico.value.URL = file;
          console.log(this.mexico.value.URL);

        }).catch(error => { console.log(error) });

      })
    })

  }


  async changeFileMenu1(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento1 = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento1 = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento1 = false;
          console.log(file);

          this.regional.value.URL = file;
          console.log(this.regional.value.URL);

        }).catch(error => { console.log(error) });

      })
    })

  }




  async changeFileMenu2(event: any) {

    let file = event.target.files[0];

    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento2 = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase


      const name = '- Autlan';

      let path = 'Noticias';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + name); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento2 = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento2 = false;
          console.log(file);

          this.autlan.value.URL = file;
          console.log(this.autlan.value.URL);

        }).catch(error => { console.log(error) });

      })
    })

  }






  noticia1() {
    this.router.navigate(['admin/ver-noticia/1'])
  }

  noticia2() {
    this.router.navigate(['admin/ver-noticia/2'])
  }

  noticia3() {
    this.router.navigate(['admin/ver-noticia/3'])
  }
  noticia4() {
    this.router.navigate(['admin/ver-noticia/4'])
  }
  noticia5() {
    this.router.navigate(['admin/ver-noticia/5'])
  }

  noticia6() {
    this.router.navigate(['admin/ver-noticia/6'])
  }

  editar() {
    this.router.navigate(["admin/editar-banner"])
  }

  subirNoticia() {
    this.router.navigate(['admin/subir-noticia']);
  }


}
