import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';

@Component({
  selector: 'app-hospitalario',
  templateUrl: './hospitalario.component.html',
  styleUrls: ['./hospitalario.component.scss']
})
export class HospitalarioComponent {
  boton: any;
  id: any;
  data: any;
  areas: any;
  area: any;
  subarea: any;
  areas1: any;
  menu: any;
  titulo: any;
  icono: any;
  archivos: string[] = [];
  subscribeTimer: any;
  animation = true;
  cuerpo:any
  ids:any

  nuevoPC = new FormGroup({
    FECHA: new FormControl('', Validators.required),
    DIA: new FormControl('', Validators.required),
    HORA_SALIDA: new FormControl('', Validators.required),
    HORA_ENTRADA: new FormControl('', Validators.required),
    AMBULANCIA: new FormControl('', Validators.required),
    OPERADOR: new FormControl('', Validators.required),
    BOMBEROS: new FormControl('', Validators.required),
    HORA_TRASLADO: new FormControl('', Validators.required),
    HORA_ARRIBO: new FormControl('', Validators.required),
    HORA_ENTREGA: new FormControl('', Validators.required),
    NOMBRE: new FormControl('', Validators.required),
    MUNICIPIO: new FormControl('', Validators.required),
    SEXO: new FormControl('', Validators.required),
    AFILIACION: new FormControl('', Validators.required),
    DOMICILIO: new FormControl('', Validators.required),
    TELEFONO: new FormControl('', Validators.required),
    EDAD: new FormControl('', Validators.required),
    OTRO: new FormControl('', Validators.required),
    MOTIVO: new FormControl('', Validators.required),
    LUGAR: new FormControl('', Validators.required),
    CONCIENCIA: new FormControl('', Validators.required),
    OCULAR: new FormControl('', Validators.required),
    VERBAL: new FormControl('', Validators.required),
    MOTORA: new FormControl('', Validators.required),
    TOTAL: new FormControl('', Validators.required),
    PIEL: new FormControl('', Validators.required),
    RESPIRACION: new FormControl('', Validators.required),
    HORA: new FormControl('', Validators.required),
    FR: new FormControl('', Validators.required),
    FC: new FormControl('', Validators.required),
    TEMP: new FormControl('', Validators.required),
    TA: new FormControl('', Validators.required),
    AVDI: new FormControl('', Validators.required),
    GLASGOW: new FormControl('', Validators.required),
    GLUCOSA: new FormControl('', Validators.required),
    PUPILAS: new FormControl('', Validators.required),
    LLEN_CAP: new FormControl('', Validators.required),
    SO2: new FormControl('', Validators.required),
    ALERGIAS: new FormControl('', Validators.required),
    MEDICAMENTOS: new FormControl('', Validators.required),
    ENFE_Y_CIRUGIAS: new FormControl('', Validators.required),
    ULT_COMIDA: new FormControl('', Validators.required),
    EVENTOS_PREV: new FormControl('', Validators.required),
    TRIAGE: new FormControl('', Validators.required),
    EVALUACION_PRI: new FormControl('', Validators.required),
    TRATAMIENTO: new FormControl('', Validators.required),
    INM_CERVICAL: new FormControl('', Validators.required),
    COLLARIN: new FormControl('', Validators.required),
    INM_ESPINAL: new FormControl('', Validators.required),
    EMPAQUETAMIENTO: new FormControl('', Validators.required),
    OROFARINGEA: new FormControl('', Validators.required),
    ASPIRACION: new FormControl('', Validators.required),
    OVACE: new FormControl('', Validators.required),
    OXIGENACION: new FormControl('', Validators.required),
    BVM: new FormControl('', Validators.required),
    NASALES: new FormControl('', Validators.required),
    FERULA: new FormControl('', Validators.required),
    CANULA: new FormControl('', Validators.required),
    TORACOCENTESIS: new FormControl('', Validators.required),
    APLI_MED: new FormControl('', Validators.required),
    RCP: new FormControl('', Validators.required),
    DESFIBRILADOR: new FormControl('', Validators.required),
    ASEPSIA: new FormControl('', Validators.required),
    APOSITO_OCLUSIVO: new FormControl('', Validators.required),
    APOSITO_ABULSIVO: new FormControl('', Validators.required),
    APOSITO_HUMEDO: new FormControl('', Validators.required),
    VENDAJE: new FormControl('', Validators.required),
    CURACION: new FormControl('', Validators.required),
    CONTROL_HEMORRAGIA: new FormControl('', Validators.required),
    INM_OBJ: new FormControl('', Validators.required),
    ATENCION_PARTO: new FormControl('', Validators.required),
    PSICOLOGIA: new FormControl('', Validators.required),
    CANALIZACION: new FormControl('', Validators.required),
    DEXTROSIS: new FormControl('', Validators.required),
    CANTIDAD: new FormControl('', Validators.required),
    OTROS_MANEJO: new FormControl('', Validators.required),
    SOLUCION_MANEJO: new FormControl('', Validators.required),
    MILILITROS_MANEJ: new FormControl('', Validators.required),
    CATETER: new FormControl('', Validators.required),
    FARMACO: new FormControl('', Validators.required),
    MEDICO_AUT: new FormControl('', Validators.required),
    MAT_CONSU: new FormControl('', Validators.required),
    NEUROLOGICO: new FormControl('', Validators.required),
    DIGESTIVO: new FormControl('', Validators.required),
    METABOLICO: new FormControl('', Validators.required),
    CARDIOVASCULAR: new FormControl('', Validators.required),
    ENDOCRINO: new FormControl('', Validators.required),
    OSTEOMUSCULAR: new FormControl('', Validators.required),
    RESPIRATORIO: new FormControl('', Validators.required),
    RENAL: new FormControl('', Validators.required),
    ALTER_EMOC: new FormControl('', Validators.required),
    UROGENITAL: new FormControl('', Validators.required),
    GINECO_OBS: new FormControl('', Validators.required),
    HEMATOLOGICO: new FormControl('', Validators.required),
    OTRO_CAUSA_C: new FormControl('', Validators.required),
    ESPECIFIQUE: new FormControl('', Validators.required),
    ANTECEDENTES_PAT: new FormControl('', Validators.required),
    LUGAR_VI: new FormControl('', Validators.required),
    UBICACION: new FormControl('', Validators.required),
    SERVICIO: new FormControl('', Validators.required)



  });



  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,) { }

  ngOnInit(): void {

  }


  oberserableTimer() {
    const source = timer(1000, 2000);
    const abc = source.subscribe(val => {
      this.animation = true;
      this.cambiarFlag()
    });
  }

  flag = false;

  cambiarFlag() {
    this.flag = !this.flag;
  }






  postForm(form: any) {


console.log(this.nuevoPC.value);


    this.alerts.alertaConfirmacionAgregar('Registro de datos', '¿Desea enviar los datos de su registro?')
      .then((res: any) => {

        if (res.isConfirmed) {


            this.api.registroHospitalario(form).subscribe(data => {
              console.log(data);

              this.api.hospitalarioID().subscribe(res => {
                this.ids = res.body;
                console.log(this.ids);
                localStorage.setItem('id',this.ids[0].ID)
this.router.navigate(['admin/proteccion-civil/servicios/hospitalario/2'])
              })

              this.alerts.realizado('Completado', 'Se ha enviado el registro con exito').then((res: any) => {

              })

            }, error => {

              this.alerts.alertaError('Ups', 'Error de registro')
            })



        }

      })

  }


parteDelCuerpo1(){
  localStorage.setItem('cuerpo', '1');
  this.mostrarImagen();

}

parteDelCuerpo2(){
  localStorage.setItem('cuerpo', '2');
  this.mostrarImagen();

}


mostrarImagen(){
  this.cuerpo === localStorage.getItem('cuerpo');
  console.log(this.cuerpo);

}

}
