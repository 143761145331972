<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Team - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <main id="main" style="background-image: url(https://i.imgur.com/HOhv2vn.png); ">


      <!-- ======= Our Team Section ======= -->
      <section   style="margin-top: 20vh;justify-content: center;text-align: center;">



        <div class="row" style="justify-content: center; text-align: center;">
          <nav aria-label="breadcrumb" style="justify-content: center; text-align: center;">
            <ol class="breadcrumb"style="justify-content: center; text-align: center;">
              <li class="breadcrumb-item"  style="color: rgb(255, 255, 255);"><a  style="color: rgb(255, 255, 255);">Directores -
                Secretario Particular</a></li>
              <li class="breadcrumb-item"  style="color: rgb(255, 255, 255);"><a href="admin/equipo/jefes" style="color: rgb(255, 255, 255);">Jefes de
                Area</a></li>
              <li class="breadcrumb-item active" aria-current="page" style="color: rgb(255, 255, 255);"><a href="admin/equipo/agentes"  style="color: rgb(255, 255, 255);"> Agentes y
                Delegados</a>   </li>
            </ol>
          </nav>

          <div class="col-2">

          </div>

          <div class="col-8"  style="border: solid; border-radius: 2vh; border-color: rgb(255, 255, 255);margin-top: 20vh">





  <div class="section-title" data-aos="fade-up">
    <h2 style="margin-top: 60px; font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; color: rgb(255, 255, 255);"class="d-none d-sm-block d-md-block"  >EQUIPO LABORAL UNIDO Y EXPERIMENTADO</h2>
    <p style="margin-top: 10px; font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;color: rgb(255, 255, 255);" class="d-none d-sm-block d-md-block" >Son parte de toda nuestra cadena de valor, son el mejor capital humano del
      mercado,<br> especializado por sectores y áreas temáticas.</p>
  </div>

          <div class="row" >
            <div class="col-lg-12  align-items-stretch miembros">
              <div class="member" data-aos="fade-up">
                <div class="member-img">
                  <img style="border-radius: 2vh;" src="https://i.imgur.com/GYpFo5f.png" height="500" width="450" class="img-fluid" alt="">
                  <!-- <div class="social" style="height: 150px;">
                    <p style="justify-content: center;text-align: center; font-weight: bold;  margin: 2vh;"></p>
                  </div> -->
                </div>
                <div class="member-info">
                  <h4 style="color: rgb(255, 255, 255);">Gustavo Robles</h4>
                  <span style="color: rgb(255, 255, 255);">Presidente Municipal</span>
                </div>
              </div>
            </div>





          </div>


          </div>

          <div class="col-2">

          </div>

        </div>




        <div class="row">
<div class="col-1">

</div><div class="col-10">

   <div class="scroll" style="justify-content: center; width: 100%;">



          <div class="row" style="margin: 10px;">

            <div id="rw" *ngFor="let dato of noticias" class="col-lg-6 col-md-6 d-flex align-items-stretch miembros">

              <div class="member" data-aos="fade-up">
                <div class="member-img" >
                  <img [src]="dato.DATO" class="img-fluid" alt="" style="border-radius: 2vh; margin-top: 2vh; ">
                  <!-- <div class="social" style="height: 150px;">
                    <p
                      style="justify-content: center;text-align: center; font-weight: bold; margin: 2vh; font-size: 1.2vh;">
                      "{{dato.MENSAJE}}"</p>

                  </div> -->
                </div>
                <!-- <div class="member-info">
                  <h4>{{dato.NOMBRE}}</h4>
                  <span>{{dato.POCISION}}</span>


                </div> -->
              </div>
            </div>


          </div>

        </div>

</div>



<div class="col-1">

</div>

        </div>



      </section><!-- End Our Team Section -->











  </main><!-- End #main -->









  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
