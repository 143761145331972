import { Component } from '@angular/core';

@Component({
  selector: 'app-carnaval-qr',
  templateUrl: './carnaval-qr.component.html',
  styleUrls: ['./carnaval-qr.component.scss']
})
export class CarnavalQrComponent {

}
