<div class="animate__animated animate__jackInTheBox"
  style="text-align: center; justify-content: center;margin-top: 25vh;">
  <strong
    style="font-size: 4vh;font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;">PREVENCION DE RIESGO EN TU ESCUELA</strong>
</div>


<section class="photos-section py-5 d-flex justify-content-center">
  <div class="container">
    <div class="row">




      <div class="col-12 col-sm-6 col-xl-4">

      </div>


      <div class="col-12 col-sm-6 col-xl-4 d-flex card-outer animate__pulse"
        style="text-align: center; justify-content: center;" data-aos="fade-up">
        <a href="{{this.docs[0].PROGRAMA}}" class="card-title" target="_blank">
          <div class="card ">
            <div class="position-relative" style="text-align: center; justify-content: center;">
              <img style="margin-top: 2vh;" height="250" width="100%" src="https://i.imgur.com/34okQQ1.png"
                class="card-img-top " alt="">
              <svg class="position-absolute w-100" style="border: 0px;border-color: rgba(255, 255, 255, 0);">
                <use xlink:href="#two" style="border: 0px; border-color: rgba(255, 255, 255, 0);"></use>
              </svg>
            </div>
            <div class="card-body">

              <strong>Información del programa</strong>

            </div>
          </div>
        </a>
      </div>



      <div class="col-12 col-sm-6 col-xl-4">

      </div>



    </div>





    <div *ngIf="this.token===true">

      <form [formGroup]="programa" (ngSubmit)="programaBrigadista()" action="">
      <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Editar Programa:</label>
      <br><input type="file" style="margin-top: 1%;" (change)="changeFileMenu($event)">

      <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
        *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

      <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>
      <div style="justify-content: center;text-align: center;" *ngIf="this.token===true">
        <button  type="submit" style="margin-top: 2vh;width: 25vh; border-radius: 1vh;"> Enviar edición del programa </button>

      </div>

      </form>
    </div>

    <div
      style="text-align: center;justify-content: center;width: 100%; font-weight: bolder;color: rgb(5, 70, 134);font-size: 4VH;">
      <a href="{{this.docs[1].CONSTANCIA}}" target="_blank">Constancia </a>
    </div>


    <form [formGroup]="constancia" (ngSubmit)="constanciaBrigadista()" action="">
    <div class="row align-items-center mt-4 form-floating mb-3" *ngIf="this.token===true">
      <input class="form-control" id="floatingInput" placeholder="name@example.com" formControlName="CONSTANCIA">
      <label for="floatingInput">Modificar vinculo de constancias</label>
    </div>

    <div style="justify-content: center;text-align: center;" *ngIf="this.token===true">
      <button type="submit" style="margin-top: 2vh;width: 25vh; border-radius: 1vh;"> Editar vinculo de constancias </button>

    </div>
    </form>

    <div style="margin-top: 3vh;">
      <label for="">Prevención de riesgos en escuelas:</label>
      <textarea name="" id="" cols="30" rows="10"
        style="border-radius: 2vh;width: 100%; " readonly>{{this.docs[2].DESCRIPCION}}</textarea>
    </div>



    <form [formGroup]="descripcion" (ngSubmit)="descripcionBrigadista()" action="">

      <div *ngIf="this.token===true">

        <label for="">Modificación:</label>
        <textarea name="" id="" cols="30" rows="10" style="border-radius: 2vh;width: 100%; "
          formControlName="DESCRIPCION">


  </textarea>
      </div>

      <div style="justify-content: center;text-align: center;" *ngIf="this.token===true">
        <button type="submit" style="margin-top: 2vh;width: 25vh; border-radius: 1vh;"> Editar Descripción </button>

      </div>

    </form>











  </div>
</section>
