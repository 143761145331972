import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';

@Component({
  selector: 'app-cronista',
  templateUrl: './cronista.component.html',
  styleUrls: ['./cronista.component.scss']
})
export class CronistaComponent {

  data: any;
  bann: any;
  boton: any;

  constructor(private router: Router, private service: GeneralService) { }

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.boton = 1;
    }
    this.service.listaCronista().subscribe(res => {
      this.data = res.body;
      console.log(this.data);

    })

    this.service.verBannerCronista().subscribe(res => {
      this.bann = res.body;
      console.log(this.bann);

    })
  }


  editar() {
    this.router.navigate(["admin/editar/cronista"])
  }

  subirCronica(){

    this.router.navigate(['admin/nueva/cronica']);
  }
editarfrase(){
  this.router.navigate(["admin/editar/frase"])
}
}
