import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';


declare var menu: any;

@Component({
  selector: 'app-hacienda',
  templateUrl: './hacienda.component.html',
  styleUrls: ['./hacienda.component.scss']
})
export class HaciendaComponent {
  boton: any;
  id: any;
  displayStyleEditar = "none";

  //Variables para url de archivos
  urlDocumento = '';

  //Variables para indicar carga de archivos
  subiendoDocumento = false;



  menu: any;
  areas: any;
  areas1: any;
  area: any;
  subarea: any;
  archivos: string[] = [];

  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router) { }

  ngOnInit(): void {

    if (localStorage.getItem('token')) {
      this.boton = 1;
    }

    this.api.verAreas().subscribe(res => {
      this.menu = res.body;
      console.log(this.menu);

    })

    this.api.verAreas1(2, 0).subscribe(res => {
      this.areas = res.body;
      console.log(this.areas);

    })

    this.api.verAreas1(2, 1).subscribe(res => {
      this.areas1 = res.body;
      console.log(this.areas1);

    })

  }

}
