<section style="background-color:  #F3F3F4; height: 6vh; width: 100%;"></section>

<div class="wrapper">
  <section style="background-color: white; padding-left: 2vh; padding-right: 2vh; overflow: scroll;margin-top: 15vh;">

    <div class="row">
<div class="col-4"></div>


<div class="col-4">

  <div style="border-radius: 2vh;box-shadow:  0 5px 20px 2px rgba(0, 0, 0, 0.15); justify-content: center; text-align: center;">

    <p style="font-weight: bold;margin-left: 3vh;"> Elija la empresa:</p>
      <select class="form-select" aria-label="Default select example" >

    <option *ngFor="let data of activos " value="{{data.EMPRESA}}">{{data.EMPRESA}}</option>

  </select>

<button click="redireccionar(data.EMPRESA)" class="boton" style="width: 25vh; border-radius: 1vh;margin-top: 2vh;margin-bottom: 2vh; background-color: crimson; border: solid; border-color: crimson;color: #F3F3F4;box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.15);"> Buscar </button>



  </div>





</div>

<div class="col-4"></div>






    </div>

  </section>
</div>


<!-- Final Modal Tabla Comaparativa -->
