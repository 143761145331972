<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Blog Single</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li><a href="blog.html">Blog</a></li>
            <li>Blog Single</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog" style="margin-top: 2vh;">
      <div class="container" data-aos="fade-up" style="margin-top: 2vh;">

        <div class="row">

          <div class="col-lg-8 entries">

            <article class="entry entry-single">

              <div class="entry-img">
                <img src="/assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
              </div>

              <h2 style="text-align: center; justify-content: center;" class="entry-title">
                <a style="text-align: center;">Parque Paulino Navarro o Alameda
                </a>
              </h2>

              <div class="entry-meta" style="text-align: center; justify-content: center;">
                <ul style="text-align: center; justify-content: center;">
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/physical-gallery.png"/> <a >Lugar al aire libre</a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/address--v1.png"/> <a >   La Alameda, 48900 Autlán de Navarro, Jal.</a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/wired/64/null/tags.png"/> <a >Gratuito</a></li>
                </ul>
              </div>

              <div class="entry-content">



                         <div style="justify-content: center; text-align: center;" >
                <img src="https://i.imgur.com/yfqpdsr.jpg" width="600" height="400" style="border-radius: 2vh;" alt="">
                 </div>
                <p style="text-align: justify; margin-top: 2vh;">
                  La Alameda es uno de los parques más amplios y mejor arbolados de Autlán. Quizás por el ambiente fresco que le dan los árboles y el viento del poniente que sopla por las tardes, la Alameda se mantiene como uno de los lugares de esparcimiento favoritos de los autlenses.
                  Además, es uno de los espacios públicos dedicados al ocio que más tiempo ha mantenido su vocación. Sabemos que fue inaugurada en 1891 con el nombre de Parque Ramón Corona, en lo que se conocía como Plaza del Camposanto. Es decir, antes de esa fecha ya era una “plaza”, un espacio público, abierto. Según don Ernesto Medina Lima, en un principio la Alameda tenía un kiosco para la banda de música, una gran pila y estaba circulada de ladrillo con cuatro grandes pórticos en las esquinas, con el característico remate triangular que encontramos en otras construcciones, como la torrecilla del reloj. Y no es para menos: el constructor de ambas fue el maestro albañil Tomás Robles. Es la imagen de la Alameda que conocemos en fotos antiguas y en pinturas basadas en esas fotos.



                </p>

                <p style="text-align: justify;">
                  Desde siempre la Alameda ha estado, dedicada al ocio, al encuentro festivo y al descanso: aquí se celebraban, desde el siglo XIX, paseos, serenatas y
                  otras actividades en los fines de semana o en festividades como las Fiestas Patrias. Entre las más importantes se cuentan los paseos organizados en
                  septiembre de 1910 como parte de la conmemoración del Centenario de la Independencia y el festival popular para festejar la consagración del primer
                  obispo de Autlán el 27 de mayo de 1961, con la participación de, entre otras, las bandas de música de El Grullo y Sayula, bailables a cargo de las escuelas 20 de Noviembre, Colegio de la Concepción, Tepeyac, entre otras. También sabemos, gracias al maestro Orlando Ramírez, que en 1940, durante la campaña de Manuel Ávila Camacho a la Presidencia de la República se estrenó aquí la pieza dancística Canción del Ejido, de Francisco Sánchez Flores, que se presentaría nuevamente en el Palacio de Bellas Artes en la toma de protesta del último presidente militar mexicano.



                </p>


<!--
                <blockquote>
                  <p>
                    Et vero doloremque tempore voluptatem ratione vel aut. Deleniti sunt animi aut. Aut eos aliquam doloribus minus autem quos.
                  </p>
                </blockquote> -->

                <p style="text-align: justify;">
                  Algo poco recordado es que la Alameda también fue sede del Callejón del Vicio. Aquí habría estado otro lugar importante de nuestra historia: según Carlos Boyzo una finca frontera a la Alameda, por la actual calle de Matamoros, habría sido el cuartel general del temido guerrillero Antonio Rojas, que sería conocida por la vox populi todavía en la década de 1970 como “el cuartel de Rojas”.
                  La fisonomía de la Alameda ha cambiado sustancialmente en varias ocasiones: la forma original construida por el maestro Tomás Robles fue modificada, para modernizarla y convertirla en “un parque digno de cualquier ciudad”, en la administración del presidente municipal Leopoldo Godoy Cisneros, en 1959, para darle una imagen acorde a la época: se sustituyó el arbolado, se colocaron bancas de material y se colocaron baldosas de concreto. Además, se instaló una fuente con grullas de ornato, entre otras cosas. Algunas décadas después se instaló, al centro de la Alameda, una construcción con locales comerciales subterráneos, que muchos recordamos siempre abandonada. La última gran modificación se realizó entre 2010 y 2012, con la formalización de las canchas de usos múltiples al centro, el cambio de sitio de la fuente y la construcción de un foro para presentaciones artísticas, entre otros cambios.


                </p>
                <div style="justify-content: center; text-align: center;" >
                  <img src="https://i.imgur.com/ffr60iO.jpg" width="600" height="400" style="border-radius: 2vh;" alt="">
                   </div>


                <p style="text-align: justify;">
                  La Alameda cuenta con algunos monumentos interesantes. El más importante es la estatua de Paulino Navarro, cuyo nombre lleva el parque desde 1924, y que fue inaugurada el 26 de diciembre de 1939, con una ceremonia que formó parte de un programa de festejos que incluyó la participación de los alumnos de las escuelas de Autlán y personal del 15° regimiento del Ejército en actividades cívicas y deportivas. En la ceremonia de inauguración del monumento fungió como orador principal el profesor Salvador M. Lima García, originario de Autlán y que en esa época se desempeñaba como oficial mayor de la Secretaría de Educación federal. La estatua muestra al general, de pie, y sosteniendo en su mano derecha el sable.
                  Otro monumento de interés es el formado por los árboles del Centenario y del Bicentenario, plantados el 6 de junio de 2010 cerca de la esquina noreste del parque como parte de las celebraciones históricas y del Día del Medio Ambiente de ese año. Se trata de una primavera y una rosamorada, que fueron plantadas con tierra traída de las diferentes agencias y delegaciones del municipio de Autlán.


                </p>

                <p style="text-align: justify;">

                  De pocos años para acá la Alameda ha tenido un uso intenso como sede de actividades de todo tipo: serenatas organizadas por el Grupo Cultural Alameda, tianguis de libros y lecturas en voz alta en el Día Mundial del Libro, desde 2014 el Festival Musical por la Paz, el Teatro del Pueblo como evento alternativo en el Carnaval, el Festival de la Muerte, Fiestas Patrias y hasta de fiestas de alcance nacional, como el Encuentro Nacional de Mariachi Tradicional.
<br><br>

                  Por Guillermo Tovar Vázquez


                </p>


                <br>






              </div>

              <!-- <div class="entry-footer">
                <i class="bi bi-folder"></i>
                <ul class="cats">
                  <li><a href="#">Business</a></li>
                </ul>

                <i class="bi bi-tags"></i>
                <ul class="tags">
                  <li><a href="#">Creative</a></li>
                  <li><a href="#">Tips</a></li>
                  <li><a href="#">Marketing</a></li>
                </ul>
              </div> -->

            </article><!-- End blog entry -->


          </div><!-- End blog entries list -->

          <div class="col-lg-4">

            <div class="sidebar">




              <h3 class="sidebar-title">Lugares de Intéres</h3>
              <div class="sidebar-item recent-posts">
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/5TBbGk5.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Plaza-de-Toros">Plaza de Toros "Alberto Balderas"</a></h4>
                  <time datetime="2020-01-01">C. Mariano Bárcenas 72, Centro</time>
                </div>


                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/UpMJ6td.png" alt="">
                  <h4><a href="admin/lugares-de-interes/jardin">Jardin "Miguel Hidalgo"</a></h4>
                  <time datetime="2020-01-01"> Margarito González Rubio 9, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NBLE2Ji.png" alt="">
                  <h4><a href="admin/lugares-de-interes/museo">Museo y Centro Regional de las Artes </a></h4>
                  <time datetime="2020-01-01"> C. José María Morelos 47, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/HAVjRry.png" alt="">
                  <h4><a href="admin/lugares-de-interes/parroquia">Parroquia de El Divino Salvador "El Sagrario"</a></h4>
                  <time datetime="2020-01-01">Margarito González Rubio 10, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NbxePys.png" alt="">
                  <h4><a href="admin/lugares-de-interes/monumentos-ninos-heroes">Monumento a los Niños Heroes</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/OE2jTNN.png" alt="">
                  <h4><a href="admin/lugares-de-interes/plaza-civica">Plaza Civica</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/DEKzly6.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Capilla">Cerro de la Capilla</a></h4>
                  <time datetime="2020-01-01">Noroeste de la ciudad de Autlán</time>
                </div>


              </div><!-- End sidebar recent posts-->


            </div><!-- End sidebar -->

          </div><!-- End blog sidebar -->

        </div>

      </div>
    </section><!-- End Blog Single Section -->

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
