<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Blog Single</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li><a href="blog.html">Blog</a></li>
            <li>Blog Single</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog" style="margin-top: 2vh;">
      <div class="container" data-aos="fade-up" style="margin-top: 2vh;">

        <div class="row">

          <div class="col-lg-8 entries">

            <article class="entry entry-single">

              <div class="entry-img">
                <img src="/assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
              </div>

              <h2 style="text-align: center; justify-content: center;" class="entry-title">
                <a style="text-align: center;">Jardin "Miguel Hidalgo"</a>
              </h2>

              <div class="entry-meta" style="text-align: center; justify-content: center;">
                <ul style="text-align: center; justify-content: center;">
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/physical-gallery.png"/> <a >Lugar de descanso</a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/address--v1.png"/> <a ><time datetime="2020-01-01"></time></a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/wired/64/null/tags.png"/> <a >Público</a></li>
                </ul>
              </div>

              <div class="entry-content">



                         <div style="justify-content: center; text-align: center;" >
                <img src="https://i.imgur.com/UpMJ6td.png" width="600" height="400" style="border-radius: 2vh;" alt="">
                 </div>
                 <p style="text-align: justify; margin-top: 2vh;">
                  En el centro del jardín Hidalgo, que está justo frente a la presidencia municipal, se levanta un monumento dedicado al iniciador de la primera fase de la guerra de Independencia, don Miguel Hidalgo y Costilla, uno de los personajes más recordados
                  de la historia de México.
                  Este monumento, usado constantemente por los autlenses como punto de referencia, consiste en una estatua fabricada en metal colocada sobre un pedestal de cantera bastante alto. En este pedestal se colocó durante los festejos por el bicentenario del inicio
                   de la guerra de Independencia una placa conmemorativa del fusilamiento del mismo Miguel Hidalgo, Juan Aldama, Mariano Jiménez e Ignacio Allende.
                  La estatua, de tamaño natural, representa a Miguel Hidalgo de pie, en actitud de marchar, vestido con la sotana  con que siempre se le representa. En la mano izquierda sostiene lo que parece ser un pergamino, mientras que en la derecha enarbola un pendón,
                   que se presume es el de la imagen de la vírgen de Guadalupe tomado en Atotonilco.
                </p>

                <p style="text-align: justify;">
                  El pedestal sobre el que descansa la estatua mide aproximadamente dos metros de alto. Con forma de columna, está conformado por dos cuerpos sobre una base cuadrada. De estilo sobrio, sus terminados recuerdan a la fachada del edificio del Banco de México,
                  en la capital del país. Se observa en buen estado, aunque en 2010 se le hicieron trabajos de mantenimento para eliminar manchas y suciedad, precisamente para realizar ahí la conmemoración del 257 aniversario del natalicio de Hidalgo.

                </p>



<!--
                <blockquote>
                  <p>
                    Et vero doloremque tempore voluptatem ratione vel aut. Deleniti sunt animi aut. Aut eos aliquam doloribus minus autem quos.
                  </p>
                </blockquote> -->

                <p style="text-align: justify;">
                  El 30 de julio de 2010, con motivo del aniversario 199 del fusilamiento del prócer, en el pedestal de este monumento se develó una placa conmemorativa, que muestra en las esquinas superiores los logotipos de la administración municipal 2009-2012 y de la Comisión
                   Municipal de Festejos del 2010, además de la bandera del municipio de Autlán en la parte inferior. La placa tiene la siguiente leyenda: "El H. Ayuntamiento de Autlán, la Comisión Municipal de Festejos del 2010 Rinden homenaje al Padre de la Patria Don Miguel Hidalgo y
                   Costilla,
                  fusilado el 30 de julio de 1811 en Chihuahua y a los iniciadores de la independencia, Don Ignacio Allende, Mariano Jiménez y Juan Aldama, fusilados el 26 de junio de 1811 en Monclova en el CXCIX aniversario de su muerte.

                </p>


                <p style="text-align: justify; color: rgb(9, 48, 147);font-size: 2vh;">

                  Referencias:

                 </p>

                <p style="text-align: justify;">

                  CulturAutlán


                </p>




              </div>

              <!-- <div class="entry-footer">
                <i class="bi bi-folder"></i>
                <ul class="cats">
                  <li><a href="#">Business</a></li>
                </ul>

                <i class="bi bi-tags"></i>
                <ul class="tags">
                  <li><a href="#">Creative</a></li>
                  <li><a href="#">Tips</a></li>
                  <li><a href="#">Marketing</a></li>
                </ul>
              </div> -->

            </article><!-- End blog entry -->


          </div><!-- End blog entries list -->

          <div class="col-lg-4">

            <div class="sidebar">




              <h3 class="sidebar-title">Lugares de Intéres</h3>
              <div class="sidebar-item recent-posts">
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/5TBbGk5.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Plaza-de-Toros">Plaza de Toros "Alberto Balderas"</a></h4>
                  <time datetime="2020-01-01">C. Mariano Bárcenas 72, Centro</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/2sVV4Hq.png" alt="">
                  <h4><a href="admin/lugares-de-interes/catedral">Catedral de la Santísima Trinidad</a></h4>
                  <time datetime="2020-01-01">Calle M. Hidalgo 74, Centro, 48900 Autlán de Navarro, Jal.
                  </time>
                </div>


                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NBLE2Ji.png" alt="">
                  <h4><a href="admin/lugares-de-interes/museo">Museo y Centro Regional de las Artes </a></h4>
                  <time datetime="2020-01-01"> C. José María Morelos 47, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/HAVjRry.png" alt="">
                  <h4><a href="admin/lugares-de-interes/parroquia">Parroquia de El Divino Salvador "El Sagrario"</a></h4>
                  <time datetime="2020-01-01">Margarito González Rubio 10, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/Dxa9ct9.png" alt="">
                  <h4><a href="admin/lugares-de-interes/casa-de-la-familia">Casa de la familia Santana Barragán</a></h4>

                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NbxePys.png" alt="">
                  <h4><a href="admin/lugares-de-interes/monumentos-ninos-heroes">Monumento a los Niños Heroes</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/OE2jTNN.png" alt="">
                  <h4><a href="admin/lugares-de-interes/plaza-civica">Plaza Civica</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/DEKzly6.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Capilla">Cerro de la Capilla</a></h4>
                  <time datetime="2020-01-01">Noroeste de la ciudad de Autlán</time>
                </div>
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/ojgjmCF.jpg" alt="">
                  <h4><a href="admin/lugares-de-interes/alameda">Parque Paulino Navarro o Alameda</a></h4>
                  <time datetime="2020-01-01"> La Alameda, 48900 Autlán de Navarro, Jal.</time>
                </div>

              </div><!-- End sidebar recent posts-->


            </div><!-- End sidebar -->

          </div><!-- End blog sidebar -->

        </div>

      </div>
    </section><!-- End Blog Single Section -->

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
