import { Component } from '@angular/core';

@Component({
  selector: 'app-p-interno',
  templateUrl: './p-interno.component.html',
  styleUrls: ['./p-interno.component.scss']
})
export class PInternoComponent {

}
