<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

</head>

<body>
  <!--Main layout-->
  <main style="margin-top: 40px;">
    <div class="container pt-4"></div>
  </main>
  <!--Main layout-->

  <section id="blog" class="blog" style="margin-top: 150px;">
    <div class="section-title" data-aos="fade-up">
      <h4 style="font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; font-weight: bold;">Buscar Nombre Registrado:</h4>

<div class="row" style="margin-top: 3vh;">
  <div class="col-2"></div>
  <div class="col-8">


    <div class="fondo" data-aos="fade-up">
      <div class="fondo1" data-aos="fade-up">
        <div style="display:flex; flex-direction:column;justify-content: center; text-align: center "
         >
          <div>
            <select hidden name="filtro" id="filtro" #cbCampo>
              <option selected value="NOMBRE">Nombre</option>
            </select>
            <img src="https://img.icons8.com/ios-glyphs/30/000000/search--v1.png" alt="" height="30" width="30">
            <input style="margin-top: 2vh; width: 50%;border-radius: 43px; border-width: 1px;text-align: center; "
              placeholder=" ......." (keyup)="buscar()" #ctCadena>
          </div>
        </div>
        <div id="scroll" style="justify-content: left; text-align:left;">
          <table class="table table-striped" style="background-color: rgb(228, 228, 228); border-radius: 2vh; margin-top: 1vh;">
            <thead>
              <tr>
                <th scope="col" style="font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;">Listado de nombres en la Conferencia Magistral
                  "Rodada Segura: Una Estrategia de Prevención Para La Vida" </th>

              </tr>
            </thead>

            <tbody *ngFor="let dato of documento">
              <tr>
                <td style="color: rgb(10, 109, 148); font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;">
                  {{dato.NOMBRE}}
                </td>

              </tr>
            </tbody>
          </table>

        </div>
      </div>
    </div>




  </div>
  <div class="col-2"></div>
</div>




    </div>

  </section>


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>
