import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { timer } from 'rxjs';
@Component({
  selector: 'app-simulacro',
  templateUrl: './simulacro.component.html',
  styleUrls: ['./simulacro.component.scss']
})
export class SimulacroComponent {
  boton: any;
  id: any;
  data: any;
  areas: any;
  area: any;
  subarea: any;
  areas1: any;
  menu: any;
  titulo: any;
  icono: any;
  archivos: string[] = [];
  subscribeTimer: any;
  animation = true;

  nuevoPC = new FormGroup({
    NOMBRE: new FormControl('', Validators.required),
    RAZON_SOCIAL: new FormControl('', Validators.required),
    CORREO: new FormControl('', Validators.required),
    CELULAR: new FormControl('', Validators.required),
    DOMICILIO: new FormControl('', Validators.required),
    LOCALIDAD: new FormControl('', Validators.required),
    N_PARTICIPANTES: new FormControl('', Validators.required),
    TIPO: new FormControl('', Validators.required)
  });

  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,) { }

  ngOnInit(): void {

    this.oberserableTimer()
  }


  oberserableTimer() {
    const source = timer(1000, 2000);
    const abc = source.subscribe(val => {
      this.animation = true;
      this.cambiarFlag()
    });
  }

  flag = false;

  cambiarFlag() {
    this.flag = !this.flag;
  }




  postForm(form: any) {




    this.alerts.alertaConfirmacionAgregar('Registro de datos', '¿Desea enviar los datos de su registro?')
      .then((res: any) => {

        if (res.isConfirmed) {
          if (form.NOMBRE !== '' &&
            form.RAZON_SOCIAL !== '' &&
            form.CORREO !== '' &&
            form.DOMICILIO !== '' &&
            form.LOCALIDAD !== '' &&
            form.CORREO !== '' &&
            form.CELULAR !== '' &&
            form.TIPO !== '' &&
            form.N_PARTICIPANTES !== '') {

            this.api.registroPC(form).subscribe(data => {
              console.log(data);
              this.alerts.realizado('Completado', 'Se ha enviado el registro con exito').then((res: any) => {
                this.router.navigate(['admin/proteccion-civil/registro'])
              })

            }, error => {

              this.alerts.alertaError('Ups', 'Error de registro')
            })

          } else {
            this.alerts.alertaError('Error de registro', 'Todos los campos son obligatorios');
          }

        }

      })

  }





}
