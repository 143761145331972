import { Component } from '@angular/core';
declare var svg:any;
@Component({
  selector: 'app-ciudad',
  templateUrl: './ciudad.component.html',
  styleUrls: ['./ciudad.component.scss']
})
export class CiudadComponent {

  ngOnInit(): void {

    svg();
  }

}
