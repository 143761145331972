<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<body>

  <!-- ======= Header ======= -->

  <main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>Blog Single</h2>
          <ol>
            <li><a href="index.html">Home</a></li>
            <li><a href="blog.html">Blog</a></li>
            <li>Blog Single</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Blog Single Section ======= -->
    <section id="blog" class="blog" style="margin-top: 2vh;">
      <div class="container" data-aos="fade-up" style="margin-top: 2vh;">

        <div class="row">

          <div class="col-lg-8 entries">

            <article class="entry entry-single">

              <div class="entry-img">
                <img src="/assets/img/blog/blog-1.jpg" alt="" class="img-fluid">
              </div>

              <h2 style="text-align: center; justify-content: center;" class="entry-title">
                <a style="text-align: center;">Parroquia de El Divino Salvador "El Sagrario" </a>
              </h2>

              <div class="entry-meta" style="text-align: center; justify-content: center;">
                <ul style="text-align: center; justify-content: center;">
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/physical-gallery.png"/> <a >Eventos religiosos</a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/ios/50/null/address--v1.png"/> <a >  Margarito González Rubio 10, Centro, 48900 Autlán de Navarro, Jal.</a></li>
                  <li class="d-flex align-items-center"><img height="20" width="20" src="https://img.icons8.com/wired/64/null/tags.png"/> <a >Gratuito</a></li>
                </ul>
              </div>

              <div class="entry-content">



                         <div style="justify-content: center; text-align: center;" >
                <img src="https://i.imgur.com/n4ke8Sp.jpg" width="600" height="400" style="border-radius: 2vh;" alt="">
                 </div>
                <p style="text-align: justify; margin-top: 2vh;">
                  El templo parroquial del Divino Salvador está ubicado en el centro de Autlán y constituye el corazón de la actual ciudad. Fue fundado en el año 1543 como convento de los frailes franciscanos que llegaron con la misión de evangelizar a la población indígena de Autlán y la región, encabezados por fray Pedro de la Concepción.
                  En ese momento los asentamientos humanos de Autlán estaban a varios kilómetros de distancia al norte y al este de lo que fue el convento, que sirvió como aglutinante para la nueva población.
                  Su construcción es la típica de la arquitectura franciscana: austera, sobria, prescindiendo de cualquier tipo de adorno en su fachada; cuenta con una sola nave y un campanario, con su puerta mayor orientada hacia el poniente, en el porche y en el transepto muestra algunos detalles en cantera, así como en los altares menores de la nave central.



                </p>

                <p style="text-align: justify;">
                  Aunque el asentamiento franciscano data de 1543, la actual construcción fue levantada hacia 1743, puesto que el edificio original se encontraba en ruinas. De esta época data la portada del templo, cuya entrada principal contiene grabado el número 1743 en la clave. Fue objeto de una reconstrucción a finales del siglo XIX, que no afectó mayormente su fisonomía.
                  El amplio atrio de la parroquia del Divino Salvador, que abarcaba la actual manzana frontera y sus alrededores, albergó el cementerio de Autlán desde su fundación hasta el año de 1831, como era la costumbre en las ciudades españolas. En ese cementerio fueron sepultados los autlenses durante casi 300 años.


                </p>

                <div style="justify-content: center; text-align: center;" >
                  <img src="https://i.imgur.com/yUdXZRL.jpg" width="600" height="400" style="border-radius: 2vh;" alt="">
                   </div>

<!--
                <blockquote>
                  <p>
                    Et vero doloremque tempore voluptatem ratione vel aut. Deleniti sunt animi aut. Aut eos aliquam doloribus minus autem quos.
                  </p>
                </blockquote> -->

                <p style="text-align: justify;">
                  En la actualidad el templo conserva objetos históricos de gran valor, entre los que encontramos su archivo histórico, que contiene documentos fechados desde 1634 y que contienen las firmas de personajes relevantes para la historia de Jalisco; las campanas, aún en uso, una de las cuales fue fundida en 1831, así como dos cuadros de gran valor artístico: el primero es una Crucifixión firmada por Atanasio Monroy en 1943 y que recuerda, en el manejo de los claroscuros y en el dramatismo que expresa, a los pintores españoles clásicos que influyeron en don Atanasio.
                  El segundo es una Sagrada Familia firmada por Monroy en 1944. Con una novedosa composición que muestra a María, José y el niño recibiendo la visita de Juan el bautista, este cuadro es de los mejores dentro de la obra de caballete de don Atanasio

                </p>
                <div style="justify-content: center; text-align: center;" >
                  <img src="https://i.imgur.com/2gZlL1U.jpg" width="600" height="400" style="border-radius: 2vh;" alt="">
                   </div>


                <p style="text-align: justify;">
                  Aunque la pieza de mayor valor es la escultura de la Virgen del Rosario, patrona de la ciudad, que se encuentra en el altar mayor. Según el cronista fray Antonio Tello, la imagen está en Autlán desde alrededor del año 1614 y desde sus primeros años le fueron atribuidas “infinitas maravillas” y milagros que le hicieron ganar un lugar importante en la devoción de los autlenses, que celebran su fiesta mediante un novenario que culmina el primer domingo de octubre, tal y como quedó establecido por acuerdo del Ayuntamiento de Autlán del 19 de septiembre de 1831.
                  Ya a mediados de siglo, en las décadas de los 50 y 60, en el apogeo de la Minera Autlán, las fiestas de la virgen del Rosario seguían teniendo mucha importancia para la sociedad autlense. En la actualidad la fiesta de la Virgen del Rosario sigue siendo de las más importantes de Autlán en el ámbito religioso.
                  La parroquia del Divino Salvador es la más antigua de la región, tuvo durante el virreinato una extensión territorial casi tan grande como la actual diócesis de Autlán.
                  Este templo fue la primera catedral de la diócesis de Autlán cuando se erigió en 1961, debido a que el templo del Sagrado Corazón, actual Catedral, no estaba aún en condiciones de ser utilizado.
                  Se puede catalogar al templo parroquial del Divino Salvador como uno de los edificios fundamentales de la identidad autlense.

                </p>

                <p style="text-align: justify;">
                  Por Guillermo Tovar Vázquez


                </p>


                <div style="justify-content: center; text-align: center;" >
                  <img src="https://i.imgur.com/53rOeB4.jpg" width="600" height="400" style="border-radius: 2vh;" alt="">
                   </div>


                <br>






              </div>

              <!-- <div class="entry-footer">
                <i class="bi bi-folder"></i>
                <ul class="cats">
                  <li><a href="#">Business</a></li>
                </ul>

                <i class="bi bi-tags"></i>
                <ul class="tags">
                  <li><a href="#">Creative</a></li>
                  <li><a href="#">Tips</a></li>
                  <li><a href="#">Marketing</a></li>
                </ul>
              </div> -->

            </article><!-- End blog entry -->


          </div><!-- End blog entries list -->

          <div class="col-lg-4">

            <div class="sidebar">




              <h3 class="sidebar-title">Lugares de Intéres</h3>
              <div class="sidebar-item recent-posts">
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/5TBbGk5.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Plaza-de-Toros">Plaza de Toros "Alberto Balderas"</a></h4>
                  <time datetime="2020-01-01">C. Mariano Bárcenas 72, Centro</time>
                </div>


                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/UpMJ6td.png" alt="">
                  <h4><a href="admin/lugares-de-interes/jardin">Jardin "Miguel Hidalgo"</a></h4>
                  <time datetime="2020-01-01"> Margarito González Rubio 9, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NBLE2Ji.png" alt="">
                  <h4><a href="admin/lugares-de-interes/museo">Museo y Centro Regional de las Artes </a></h4>
                  <time datetime="2020-01-01"> C. José María Morelos 47, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img height="60" src="https://i.imgur.com/HAVjRry.png" alt="">
                  <h4><a href="admin/lugares-de-interes/parroquia">Parroquia de El Divino Salvador "El Sagrario"</a></h4>
                  <time datetime="2020-01-01">Margarito González Rubio 10, Centro, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/NbxePys.png" alt="">
                  <h4><a href="admin/lugares-de-interes/monumentos-ninos-heroes">Monumento a los Niños Heroes</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/OE2jTNN.png" alt="">
                  <h4><a href="admin/lugares-de-interes/plaza-civica">Plaza Civica</a></h4>
                  <time datetime="2020-01-01">Av Independencia, 48900 Autlán de Navarro, Jal.</time>
                </div>

                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/DEKzly6.png" alt="">
                  <h4><a href="admin/lugares-de-interes/Capilla">Cerro de la Capilla</a></h4>
                  <time datetime="2020-01-01">Noroeste de la ciudad de Autlán</time>
                </div>
                <div class="post-item clearfix">
                  <img src="https://i.imgur.com/Dxa9ct9.png" alt="">
                  <h4><a href="admin/lugares-de-interes/casa-de-la-familia">Casa de la familia Santana Barragán</a></h4>

                </div>


              </div><!-- End sidebar recent posts-->


            </div><!-- End sidebar -->

          </div><!-- End blog sidebar -->

        </div>

      </div>
    </section><!-- End Blog Single Section -->

  </main><!-- End #main -->


  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
