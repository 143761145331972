import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import * as XLSX from 'xlsx';
import Chart from 'chart.js/auto';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent {
activos:any
constructor(private router: Router, private general: GeneralService){}

ngOnInit() {



  this.general.PcDatosGeneral().subscribe(res => {
    this.activos = res.body


  }) }

  redireccionar(empresa:any){
    localStorage.setItem('','')
  this.router.navigate(['/admin/proteccion-civil/pc/interno'+ '/' + empresa])
  }

}
