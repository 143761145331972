import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-tramite',
  templateUrl: './tramite.component.html',
  styleUrls: ['./tramite.component.scss']
})
export class TramiteComponent {
  razon:any
  concepto:any
  cantidad:any
  solicitante:any
   archivos: string[] = [];
 estatus:any

   nuevoPC = new FormGroup({
     EMPRESA: new FormControl('', Validators.required),
     TELEFONO: new FormControl('', Validators.required),
     CODIGO: new FormControl('', Validators.required),
     NUM_EMPLEADOS: new FormControl('', Validators.required),
     REPRESENTANTE: new FormControl('', Validators.required),
     ORGA_DIST_LAB: new FormControl('', Validators.required),
     AFOROS: new FormControl('', Validators.required),
     POLIZA_SEG: new FormControl('', Validators.required),
     INMUEBLE: new FormControl('', Validators.required),
     PIPC: new FormControl('', Validators.required),

   });

   constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router,) { }

   ngOnInit(): void {

   }


   postForm(form: any) {




     this.alerts.alertaConfirmacionAgregar('Registro Datos Generales', '¿Desea enviar los datos?')
       .then((res: any) => {

         if (res.isConfirmed) {
           if (form.EMPRESA !== '' &&
           form.TELEFONO !== '' &&
           form.CODIGO !== '' &&
           form.NUM_EMPLEADOS !== '' &&
           form.REPRESENTANTE !== '' &&
           form.ORGA_DIST_LAB !== '' &&
           form.AFOROS !== '' &&
           form.POLIZA_SEG !== '' &&
           form.INMUEBLE !== '' &&
           form.PIPC !== ''



           ) {
             console.log(form);

             this.api.PIRegistro(form).subscribe(data => {
               console.log(data);

              localStorage.setItem('empresa', form.EMPRESA)
               this.alerts.realizado('Completado', 'Se han enviado los datos con exito').then((res: any) => {
this.router.navigate(['admin/proteccion-civil/pc/interno/organizacion'])
               })

             }, error => {

               this.alerts.alertaError('Ups', 'Error de registro')
             })

           } else {
             this.alerts.alertaError('Error de registro', 'Todos los campos son obligatorios');
           }

         }

       })

   }




 }
