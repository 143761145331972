<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <title>Blog Single - Company Bootstrap Template</title>
  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">


  <link href="/assets/css/style.css" rel="stylesheet">
</head>

<body>

  <section style="background-color: rgb(238, 238, 238);margin-top: 100px;" id="services" class="services section-bg">
    <h3
      style="text-align: center; width: 100%;font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; margin-top: 10vh; margin-bottom: 2vh;">
      Areas de Información:</h3>
    <div class="container" data-aos="fade-up">

      <div class="row" style="margin-left: 2vh; justify-content: center;">

        <div class="col-lg-2 " data-aos="zoom-in" data-aos-delay="100" *ngFor="let dato of areas">
          <div style="border-radius: 3vh; margin-top: 2vh;" class="icon-box iconbox-teal">
            <a href="admin/tramites-y-servicios/{{dato.AREA}}">
              <div class="icon">
                <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                  <path stroke="none" stroke-width="0" fill="#f5f5f5"
                    d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174">
                  </path>
                </svg>
                <i><img height="100" width="100" [src]="dato.ICONO" /></i>
              </div>
              <h4>{{dato.TITULO}}</h4>
            </a>
            <button *ngIf="boton === 1" style="border-radius: 1vh; width: 130px; margin-top: 15px;"
              (click)="openModalEditar(dato.ID, dato.AREA, dato.SUBAREA)">Editar <i
                class="bi bi-pencil-square"></i></button>
          </div>

        </div>

      </div>

    </div>
  </section>

  <!-- Inicio Editar -->
  <div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyleEditar}" style="margin-top: 15vh;">
    <form [formGroup]="editarForm">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header" >
            <div style="display: flex;flex-direction: column;">
              <h2 class="modal-title">Editar</h2>
            </div>
          </div>
          <div class="modal-body" style="margin-top: 2vh;">
            <div style="display: flex;justify-content: space-between; margin-top: 2%;">
              <div class="row" style="margin-top: 2vh;">
                <label style="font-weight: bold;">
                  Titulo actual:
                </label>
                <input type="text" style="width: 98%; margin-left: 13px; height: 25px;" readonly
                  placeholder="{{this.titulo}}">
              </div>
            </div>
            <div style="display: flex;justify-content: space-between; margin-top: 2%;">
              <div class="row">
                <label style="font-weight: bold;">
                  Escriba el nuevo titulo:
                </label>
                <input type="text" style="width: 98%; margin-left: 13px; height: 25px;" formControlName="TITULO">
              </div>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <div class="row">
                <label style="font-weight: bold;">
                  Icono actual:
                </label>
                <i><img width="100" height="100" [src]="this.icono" /></i>
                <div>
                  <label style="margin-top: 2%; margin-right: 1%;font-weight: bold;">Elija el nuevo icono:</label>
                  <input #File type="file" style="margin-top: 1%;" accept='image/*' (change)="changeFileMenu($event)">
                  <i class="bi bi-check-circle-fill" style="font-size: 30px; color: #00B59C;"
                    *ngIf="!subiendoDocumento && urlDocumento !== '' "></i>

                  <div class="spinner-border text-primary" role="status" *ngIf="subiendoDocumento"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="closePopUpEditar()">
              Cancelar
            </button>
            <button type="button" class="btn btn-outline-dark" (click)="editar()">
              Guardar
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- Final Editar -->

  <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i
      class="bi bi-arrow-up-short"></i></a>

  <!-- Vendor JS Files -->
  <script src="/assets/vendor/aos/aos.js"></script>
  <script src="/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="/assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="/assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="/assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="/assets/vendor/waypoints/noframework.waypoints.js"></script>
  <script src="/assets/vendor/php-email-form/validate.js"></script>

  <!-- Template Main JS File -->
  <script src="/assets/js/main.js"></script>

</body>

</html>
