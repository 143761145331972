import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { timer } from 'rxjs';
import { Storage, ref, uploadBytes, getDownloadURL } from '@angular/fire/storage';
@Component({
  selector: 'app-nuevo-servicio',
  templateUrl: './nuevo-servicio.component.html',
  styleUrls: ['./nuevo-servicio.component.scss']
})
export class NuevoServicioComponent {
  boton: any;
  id: any;
  data: any;
  areas: any;
  area: any;
  arrayFotos: any
  subarea: any;
  areas1: any;
  menu: any;
  titulo: any;
  icono: any;
  archivos: string[] = [];
  subscribeTimer: any;
  animation = true;
  urlDocumento: any
  imagen: any
  imagen2: any
  imagen3: any

  persona1: any
  persona2: any
  persona3: any
  persona4: any
  persona5: any
  persona6: any

  vehiculo: any
  vehiculo1: any
  vehiculo2: any
  vehiculo3: any
  vehiculo4: any

  num_vehi: any
  num_perso: any
  nuevoPC = new FormGroup({
    EDAD: new FormControl('', Validators.required),
    SEXO: new FormControl('', Validators.required),
    FOLIO: new FormControl('', Validators.required),
    EDAD1: new FormControl('', Validators.required),
    SEXO1: new FormControl('', Validators.required),


    EDAD2: new FormControl('', Validators.required),
    SEXO2: new FormControl('', Validators.required),


    EDAD3: new FormControl('', Validators.required),
    SEXO3: new FormControl('', Validators.required),


    EDAD4: new FormControl('', Validators.required),
    SEXO4: new FormControl('', Validators.required),


    EDAD5: new FormControl('', Validators.required),
    SEXO5: new FormControl('', Validators.required),


    NUM_PERSONAS: new FormControl('', Validators.required),
    NUM_VEHICULO: new FormControl('', Validators.required),

    FECHA: new FormControl('', Validators.required),
    SERVICIO: new FormControl('', Validators.required),
    TIPO_SERVICIO: new FormControl('', Validators.required),
    VEHICULO: new FormControl('', Validators.required),

    VEHICULO1: new FormControl('', Validators.required),
    VEHICULO2: new FormControl('', Validators.required),
    VEHICULO3: new FormControl('', Validators.required),
    VEHICULO4: new FormControl('', Validators.required),

    BASE: new FormControl('', Validators.required),
    LUGAR: new FormControl('', Validators.required),
    ESTATUS: new FormControl('', Validators.required),
    REPORTANTE: new FormControl('', Validators.required),
    CABINERO: new FormControl('', Validators.required),
    HORA_RECEPCION: new FormControl('', Validators.required),
    HORA_SALIDA: new FormControl('', Validators.required),
    HORA_LLEGADA: new FormControl('', Validators.required),
    HORA_ARRIBO: new FormControl('', Validators.required),
    DOMICILIO: new FormControl('', Validators.required),
    COLONIA: new FormControl('', Validators.required),
    IMAGEN: new FormControl('', Validators.required),
    IMAGEN2: new FormControl('', Validators.required),
    IMAGEN3: new FormControl('', Validators.required),
    CONDUCTOR: new FormControl('', Validators.required),
    PERSONAL: new FormControl('', Validators.required),
    DESCRIPCION: new FormControl('', Validators.required),



  });
  subiendoDocumento: boolean;
  subiendoDocumento2: boolean;
  subiendoDocumento1: boolean;

  constructor(private api: GeneralService, private alerts: SweetAlertService, private router: Router, private storage: Storage) { }

  ngOnInit(): void {
    this.imagen = 'sin imagen'
    this.imagen2 = 'sin imagen'
    this.imagen3 = 'sin imagen'
    this.oberserableTimer()
    // this.nuevoPC.setValue({
    //   'EDAD': ' ',
    //   'SEXO': ' ',
    //   'EDAD1': ' ',
    //   'SEXO1': ' ',
    //   'EDAD2': ' ',
    //   'SEXO2': ' ',
    //   'EDAD3': ' ',
    //   'SEXO3': ' ',

    //   'EDAD4': ' ',
    //   'SEXO4': ' ',

    //   'EDAD5': ' ',
    //   'SEXO5': ' ',

    //   'NUM_PERSONAS': '',
    //   'NUM_VEHICULOS': '',
    //   'FECHA': '',
    //   'SERVICIO': 'Dato no seleccionado',
    //   'TIPO_SERVICIO': 'Dato no seleccionado',
    //   'VEHICULO': 'Dato no seleccionado',
    //   'VEHICULO1': 'Dato no seleccionado',
    //   'VEHICULO2': 'Dato no seleccionado',
    //   'VEHICULO3': 'Dato no seleccionado',
    //   'VEHICULO4': 'Dato no seleccionado',
    //   'BASE': 'Dato no seleccionado',
    //   'LUGAR': '',
    //   'REPORTANTE': '',
    //   'CABINERO': '',
    //   'HORA_RECEPCION': '',
    //   'HORA_SALIDA': '',
    //   'HORA_LLEGADA': '',
    //   'HORA_ARRIBO': '',
    //   'DOMICILIO': '',
    //   'COLONIA': '',
    //   'IMAGEN': '',
    //   'IMAGEN2': '',
    //   'IMAGEN3': '',
    //   'CONDUCTOR': '',
    //   'PERSONAL': '',
    //   'DESCRIPCION': '',
    //   'ESTATUS': '',


    // })
  }
  generar() {
    this.num_perso = this.nuevoPC.value.NUM_PERSONAS
    console.log(this.num_perso);


    if (this.num_perso == 1) {
      this.persona1 = 1
      this.persona2 = 0
      this.persona3 = 0
      this.persona4 = 0
      this.persona5 = 0
      this.persona6 = 0

    } else if (this.num_perso == 2) {
      this.persona1 = 1
      this.persona2 = 1
      this.persona3 = 0
      this.persona4 = 0
      this.persona5 = 0
      this.persona6 = 0
    } else if (this.num_perso == 3) {
      this.persona1 = 1
      this.persona2 = 1
      this.persona3 = 1
      this.persona4 = 0
      this.persona5 = 0
      this.persona6 = 0
    } else if (this.num_perso == 4) {
      this.persona1 = 1
      this.persona2 = 1
      this.persona3 = 1
      this.persona4 = 1
      this.persona5 = 0
      this.persona6 = 0
    } else if (this.num_perso == 5) {
      this.persona1 = 1
      this.persona2 = 1
      this.persona3 = 1
      this.persona4 = 1
      this.persona5 = 1
      this.persona6 = 0

    } else if (this.num_perso == 6) {
      this.persona1 = 1
      this.persona2 = 1
      this.persona3 = 1
      this.persona4 = 1
      this.persona5 = 1
      this.persona6 = 1

    }

  }

  genVehiculo() {
    this.num_vehi = this.nuevoPC.value.NUM_VEHICULO
console.log(this.num_vehi);

    if (this.num_vehi == 1) {
      this.vehiculo = 1
      this.vehiculo1 = 0
      this.vehiculo2 = 0
      this.vehiculo3 = 0
      this.vehiculo4 = 0


    } else if (this.num_vehi == 2) {
      this.vehiculo = 1
      this.vehiculo1 = 1
      this.vehiculo2 = 0
      this.vehiculo3 = 0
      this.vehiculo4 = 0

    } else if (this.num_vehi == 3) {
      this.vehiculo = 1
      this.vehiculo1 = 1
      this.vehiculo2 = 1
      this.vehiculo3 = 0
      this.vehiculo4 = 0

    } else if (this.num_vehi == 4) {
      this.vehiculo = 1
      this.vehiculo1 = 1
      this.vehiculo2 = 1
      this.vehiculo3 = 1
      this.vehiculo4 = 0
    } else if (this.num_vehi == 5) {
      this.vehiculo = 1
      this.vehiculo1 = 1
      this.vehiculo2 = 1
      this.vehiculo3 = 1
      this.vehiculo4 = 1
    }


  }

  oberserableTimer() {
    const source = timer(1000, 2000);
    const abc = source.subscribe(val => {
      this.animation = true;
      this.cambiarFlag()
    });
  }

  flag = false;

  cambiarFlag() {
    this.flag = !this.flag;
  }


  //** Promesa para obtener una clave de identificacion de documentos para subirlos a Firebase*/
  getIdentificadorDocumentos = new Promise((resolve, reject) => {
    this.api.obtenerIdentificadorDocumentos(1).subscribe(res => {
      resolve(res.body);  //Cuando se reciben los datos del servidor, resolvemos la promesa
    })
  })

  async contarArchivos(event: any) {
    let cont = event.target.files.length;



    for (let i = 0; i < cont; i++) {

      this.changeFileMenu(event, i);
      let file = event.target.files[i];

      this.archivos[i] = file.name;


    }
  }


  async contarArchivos1(event: any) {
    let cont = event.target.files.length;



    for (let i = 0; i < cont; i++) {

      this.changeFileMenu1(event, i);
      let file = event.target.files[i];
      this.archivos[i] = file.name;


    }
  }


  async contarArchivos2(event: any) {
    let cont = event.target.files.length;



    for (let i = 0; i < cont; i++) {

      this.changeFileMen2(event, i);
      let file = event.target.files[i];

      this.archivos[i] = file.name;


    }
  }

  async changeFileMenu(event: any, index: any) {

    let file = event.target.files[index];


    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase

      const name = '- Autlan';

      let path = 'Transparencia';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + this.archivos[index]); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento = false;
          console.log(file);




          this.imagen = file
          this.nuevoPC.value.IMAGEN = this.imagen
          console.log(this.imagen);





        }).catch(error => { console.log(error) });

      })
    })

  }



  async changeFileMenu1(event: any, index: any) {

    let file = event.target.files[index];


    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento1 = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase

      const name = '- Autlan';

      let path = 'Transparencia';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + this.archivos[index]); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento1 = false;



          this.imagen2 = file
          this.nuevoPC.value.IMAGEN2 = this.imagen2
          console.log(this.imagen2);


        }).catch(error => { console.log(error) });

      })
    })

  }



  async changeFileMen2(event: any, index: any) {

    let file = event.target.files[index];


    //TODO: Obtenemos nombre identificador para el documento
    this.getIdentificadorDocumentos.then(res => {
      let identificador = res;
      this.subiendoDocumento2 = true; //Indicamos que se comienza proceso subir foto
      //TODO: Subimos archivo a Firebase

      const name = '- Autlan';

      let path = 'Transparencia';  //Construimos ruta
      let fileRef = ref(this.storage, path + '/' + identificador + this.archivos[index]); //Creamos una referncia al archivo usando la ruta

      //Subimos el archivo a Firebase
      uploadBytes(fileRef, file).then(response => {
        //TODO: Descargamos la URL del archivo
        getDownloadURL(fileRef).then(file => {
          this.urlDocumento = file; //guardamos la url del archivo en una variable
          this.subiendoDocumento2 = false;
          console.log(file);


          this.imagen3 = file
          this.nuevoPC.value.IMAGEN3 = this.imagen3
          console.log(this.imagen3);


        }).catch(error => { console.log(error) });

      })
    })

  }


  postForm() {


    this.nuevoPC.value.ESTATUS = '1'
    this.nuevoPC.value.IMAGEN = this.imagen
    this.nuevoPC.value.IMAGEN2 = this.imagen2
    this.nuevoPC.value.IMAGEN3 = this.imagen3

    this.alerts.alertaConfirmacionAgregar('Registro de datos', '¿Desea enviar los datos de su registro?')
      .then((res: any) => {

        if (res.isConfirmed) {
          if (this.nuevoPC.value.FECHA !== ''


          ) {

            this.api.registroServicioPC(this.nuevoPC.value).subscribe(data => {

              this.alerts.realizado('Completado', 'Se ha enviado el registro con exito').then((res: any) => {

              })

            }, error => {
              console.log(this.nuevoPC.value);
              console.log(error);

              this.alerts.alertaError('Ups', 'Error de registro')
            })

          } else {
            this.alerts.alertaError('Error de registro', 'Algunos campos son obligatorios');
          }

        }

      })

  }





}
