<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<header id="header" class="fixed-top">




  <div class="container d-flex align-items-center">

    <img src="https://i.imgur.com/jqif1VH.png" alt="logo_Autlan" height="70px" width="70px"><p style="font-weight: bolder; margin-left: 1vh;margin-top: 2vh;"> Protección Civil y Bomberos Autlán</p>


    <h1 class="logo me-auto"><a href="inicio"><span></span></a></h1>
    <!-- Uncomment below if you prefer to use an image logo -->
    <!-- <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

    <nav id="navbar" class="navbar order-last order-lg-0">





      <ul>
        <li><a href="admin/indice" class="active">Inicio</a></li>




        <li class="dropdown"><a><span>PROGRAMAS</span> <i class="bi bi-chevron-down"></i></a>
          <ul>

            <li class="dropdown"><a href="admin/proteccion-civil/pc/interno"><span>PROGRAMA INTERNO PC Y BOMBEROS</span> </a>

            </li>

            <li class="dropdown"><a href="admin/proteccion-civil/brigadistas"><span>BRIGADISTAS JUVENILES</span> </a>

            </li>

            <li class="dropdown"><a href="admin/proteccion-civil/riesgos/escuela"><span>PREVENCION DE RIESGO EN TU ESCUELA</span> </a>

            </li>



            <li class="dropdown"  *ngIf="this.token==true"><a ><span>DICTAMENES</span><i class="bi bi-chevron-down"></i> </a>

              <ul>
                <li class="dropdown"><a href="admin/proteccion-civil/dictamen/registro"><span>REGISTRO</span> </a>

                </li>
                <li class="dropdown"><a href="admin/proteccion-civil/dictamen/lista"><span>LISTA DE REGISTROS</span> </a>

                </li>
              </ul>

            </li>





            <li class="dropdown" *ngIf="this.token==true"><a > <span>SERVICIOS HOSPITALARIOS</span><i class="bi bi-chevron-down"></i> </a>


              <ul>
                <li class="dropdown"><a href="admin/proteccion-civil/nuevo/servicio"><span>REGISTRAR NUEVO SERVICIO</span> </a></li>


                <li class="dropdown"><a href="admin/proteccion-civil/seleccionar/servicio"><span>HOSPITALARIO</span></a>



                </li>

                <li class="dropdown"><a href="admin/proteccion-civil/servicios/gestion"><span>ADMINISTRACION DE SERVICIOS</span> </a></li>

              </ul>

            </li>





          </ul>
        </li>

        <a *ngIf="this.token===false" style="color: rgb(0, 83, 156)" href="/admin/login" class="youtube">
          <img width="20" height="20" src="https://img.icons8.com/ios-filled/50/000000/login-rounded-right.png" alt="login-rounded-right"/></a>

      <button style="height: 1px; border:transparent; border-color: transparent;border-top: transparent;border-top-color: transparent;margin-top: -3vh;  margin-left: 15px;color: rgb(4, 79, 132); font-size: 2vh;
      font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; ;
      " *ngIf="this.token===true" (click)="cerrar()" class="facebook">Cerrar Sesión</button>



      </ul>


      <button class="bi bi-list mobile-nav-toggle" style="background: none; border: none;" data-bs-toggle="collapse"
        data-bs-parent="#accordion" href="#collapse1">
      </button>
    </nav>

  </div>

  <nav id="collapse1" class="panel-collapse collapse in mobile-nav-toggle" data-bs-parent="#accordion">
    <div class="offcanvas-body">

      <div id="sidebar" class="text-ceter" style="width: 90%;">
        <div class="nav flex-column py-3">

          <ul class="list-unstyled ps-0">
            <ul class="list-unstyled ps-0">
              <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/indice" class="active">Inicio</a>
              </li>


              <li class="mb-1" class="btn align-items-center rounded ms-auto w-100">
                <button id="usuarios" href="#ciudad" data-bs-toggle="collapse" data-bs-parent="#sidebar"
                  style="background: none; border: none;">
                  Programas <i class="bi bi-chevron-down"></i>
                </button>
                <div class="collapse animate__animated animate__zoomIn" id="ciudad" data-bs-parent="#sidebar">
                  <ul class="btn align-items-center rounded ms-auto w-100">
                    <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/proteccion-civil/pc/interno">Programa Interno y bomberos</a></li>
                    <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/proteccion-civil/brigadistas">Brigadistas Juveniles
                       </a>
                    </li>
                    <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/proteccion-civil/riesgos/escuela">Prevencion de Riesgo en tu escuela
                    </a>
                 </li>

                 <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/proteccion-civil/dictamen">Dictamenes
                </a>
             </li>

             <li class="btn align-items-center rounded ms-auto w-100"><a href="admin/proteccion-civil/servicios">Servicios hospitalarios
            </a>
         </li>


                  </ul>
                </div>
              </li>



            </ul>
          </ul>
        </div>

      </div>
    </div>





  </nav>

</header><!-- End Header -->
